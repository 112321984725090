<template>
  <div class="zhejiang">
    <div class="zhejtable1" v-if="page.page1">
      <table>
        <tr>
          <td
            style="
              width: 620px;
              background: #e2e8f4;
              text-align: center;
              color: #454d60;
              padding-left: 0px;
            "
          >
            公司名称
          </td>
        </tr>
        <tr>
          <td>杭州哲石投资管理有限公司</td>
        </tr>
        <tr>
          <td>宁波思昊振东投资管理合伙企业（有限合伙）</td>
        </tr>
        <tr>
          <td>浙江引石资产管理有限公司</td>
        </tr>
        <tr>
          <td>杭州韬晟资产管理有限公司</td>
        </tr>
        <tr>
          <td>浙江大可资产管理有限公司</td>
        </tr>
        <tr>
          <td>杭州苍石投资管理有限公司</td>
        </tr>
        <tr>
          <td>宁波永乐杰行资产管理合伙企业（有限合伙）</td>
        </tr>
        <tr>
          <td>杭州睿银投资管理有限公司</td>
        </tr>
        <tr>
          <td>浙江期成投资管理有限公司</td>
        </tr>
        <tr>
          <td>浙江乐赢资产管理有限公司</td>
        </tr>
        <tr>
          <td>天泉证道资产管理（杭州）有限公司</td>
        </tr>
      </table>
    </div>
 <div class="zhejtable2" v-if="page.page2">
      <table>
        <tr>
          <td
            style="
              width: 620px;
              background: #e2e8f4;
              text-align: center;
              color: #454d60;
              padding-left: 0px;
            "
          >
            公司名称
          </td>
        </tr>

        <tr>
          <td>杭州大元资本管理有限公司</td>
        </tr>
        <tr>
          <td>浙江舜正投资管理有限公司</td>
        </tr>
        <tr>
          <td>杭州天迹资产管理有限公司</td>
        </tr>
        <tr>
          <td>杭州哲萌投资管理有限公司</td>
        </tr>
        
      </table>
    </div>
    <div class="successcomfoot">
      <div v-bind:class="[page.page1 ? class1 : class2]" @click="showp1">1</div>
      <div v-bind:class="[page.page2 ? class1 : class2]" @click="showp2">2</div>
      共2页，15条</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      page: {
        page1: true,
        page2: false,
      },
      class2: "pagebutton",
      class1: "pageclickbutton",
    };
  },
  methods: {
    showp1() {
      Object.keys(this.page).forEach((item) => {
        this.page[item] = false;
      });
      this.page.page1 = true;
    },
    showp2() {
      Object.keys(this.page).forEach((item) => {
        this.page[item] = false;
      });
      this.page.page2 = true;
    },
  },
};
</script>

<style >
</style>