<template>
  <div class="tradetable">
    <table style="margin:0 auto;">
      <tr>
        <td
          style="
            width: 70px;
            background: #e1e8f2;
            font-weight: bold;
            color: #084772;
            height: 52px;
            line-height: 52px;
          "
        >
          字母
        </td>
        <td
          style="
            width: 195px;
            background: #e1e8f2;
            font-weight: bold;
            color: #084772;
          "
        >
          期货公司
        </td>
        <td
          style="
            width: 140px;
            background: #e1e8f2;
            font-weight: bold;
            color: #084772;
          "
        >
          上期平台
        </td>
        <td
          style="
            width: 140px;
            background: #e1e8f2;
            font-weight: bold;
            color: #2c638d;
          "
        >
          金仕达交易
        </td>
        <td
          style="
            width: 140px;
            background: #e1e8f2;
            font-weight: bold;
            color: #2c638d;
          "
        >
          恒生接口
        </td>
      </tr>
      <tbody v-for="item in tabledata" :key="item.character">
          <tr v-for="(items,indexs) in item.list" :key="indexs"  :class="[items.idx%2==0?'tb1':'tb2']">
              <td v-if="indexs==0"  :rowspan="item.list.length" style="background: white">{{item.character}}</td>
              <td>{{items.memo==1?'*':''}}{{items.qhgs}}</td>
              <td>{{items.isshq==1?'支持':'-'}}</td>
              <td>{{items.isjsd==1?'支持':'-'}}</td>
              <td>{{items.ishsh==1?'支持':'-'}}</td>
          </tr>
      </tbody>
    </table>
    <p  class="text_p" style="padding-top:10px;padding-bottom:10px">
      *其它交易通道：<br>
      支持飞创后台的期货公司－华创期货、九州期货、新纪元期货；<br>
      支持飞马后台的期货公司－北金期货、长安期货、国都期货、津投期货、中航期货。
    </p>
    <p class="text_p" style="padding-bottom:30px;">
      此列表更新时间：{{this.time}}
    </p>
  </div>
</template>

<script>
import axios  from 'axios'
export default {
    data(){
        return{
            tabledata:[],
            time:'',
        }
    },
    methods:{        
        getdata(){
            let formData = new FormData();
            formData.append("type", 4);
              axios.post("https://wh-api.wenhua.com.cn/api/Website/GetQHGSPartnerList",formData, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                        }
                        }).then((res)=>{
                        console.log(res)
                                    var a=0
                                    this.tabledata=res.data.data.list
                                    var t=res.data.data.lastmodtime.split('-')
                                    this.time=t[0]+'年'+t[1]+'月'+t[2]+'日'
                                    this.tabledata.forEach((el)=>{
                                        el.list.forEach((els)=>{
                                        els.idx=a
                                        a++
                                        })
                                    })
                                    }).catch((err)=>{console.log(err);})
        }
    },
    created(){
        this.getdata()
    }
};
</script>

<style scoped>
.tradetable {
  width: 720px;
  margin: 0 auto;
  color: #5a5a5a;
}
.tb2 {
  background: #f4f7fc;
}
.tb1 {
  background: white;
}
td {
  vertical-align: middle;
  text-align: center;
  padding-left: 0px;
  border: 1px solid #a9a9a9;
  letter-spacing: 1px;
  color: #5a5a5a;
}
td {
  height: 35px;
  border: 1px solid #cdcdcd;
  line-height: 35px;
  vertical-align: middle;
  font-size: 14px;
  /* padding-left: 40px; */
  letter-spacing: 1px;
  color: #5a5a5a;
}
.text_p{
  line-height: 24px;
  font-size: 14px;
  padding-left:14px;
}
</style>