<template>
  <div class="serivehtml" ref="box">
      <div class="content">
	<p class="prompt">服务</p>
	<div class="mode" style="margin-top:20px;">
		<div class="mt"><span class="modeNm">渠道一：</span><ResTxt id="05667578f38eb" /></div>
		<div class="mCon mcon1">
            <ResTxt style="text-indent: 2em;" id="156b71dca1e94" /><br>
            <ResTxt style="text-indent: 2em;" id="e6a0cfd914d0a" /><br>
            <div style="text-indent: 2em;">每天我们处理上千个用户的提问，上一个年度我们解答了160643个用户的问题。</div>
            <!-- <ResTxt style="text-indent: 2em;" id="6aa79a0b53576" /><br> -->
            <ResTxt style="text-indent: 2em;" id="81c6efe81024a" /><br>
            <ResTxt style="font-weight: bold;margin-top: 10px;" id="1df6cc75c16c5" /><br>
			<!-- <p style="text-indent: 2em;">在线客服是文华服务用户的主流渠道。</p>
			<p style="text-indent: 2em;">在线客服配备一支专业金融工程师团队，每日14个小时的在线值守，解答投资者提出的各种软件使用问题。</p>
			<p style="text-indent: 2em;">wh6/随身行/wh10相关问题30分钟内回复、wh7/wh8/wh9相关问题10分钟内回复，每天我们处理上千个用户的提问，上一个年度我们解答了122040个用户的问题。
金融工程师团队成员，由具有金融类本科学历、持有期货从业资格证的专业人员组成。</p>
			<p style="text-indent: 2em;">金融工程师团队成员，由具有金融类本科学历、持有期货从业资格证的专业人员组成。</p>
			<p style="font-weight: bold;margin-top: 10px;">在线客服入口：软件界面的右上角菜单，[帮助]－>在线客服</p> -->
		</div>
		<div class="mpic1"></div>
	</div>
	<div class="mode" style="height: 200px;">
		<div class="mt"><span class="modeNm">渠道二：</span><ResTxt id="0814cb3c6f66d" /></div>
		<div class="mCon mcon2">
            <ResTxt style="text-indent: 2em;" id="7f8f679a731bc" /><br>
            <ResTxt style="text-indent: 2em;" id="00679ecceec4a" /><br>
			<!-- <p style="text-indent: 2em;">呼叫中心配备一支金融工程师团队，接听用户电话，解决用户问题。</p>
			<p style="text-indent: 2em;">金融工程师团队成员，由具有金融类本科学历、持有期货从业资格证的专业人员组成。</p> -->
			<!-- <p style="margin-top: 10px;"><span style="font-weight: bold;color: #888;">400-811-3366</span>（工作日8:00～22:00，五一、十一、春节初三至初六16:30～20:30）</p> -->
            <div style="margin-top: 10px;"><ResTxt style="font-weight: bold;color: #888;" id="75057a2f7340e" /><ResTxt id="8a4de1e44327e" /></div>
		</div>
		<div class="mpic2"></div>
	</div>
	<div class="mode" style="height: 280px;" id="service3">
		<div class="mt"><span class="modeNm">渠道三：</span>
        <!-- <ResTxt id="3c447094b84c9" /> -->
        <span style="position:relative">文文通<img src="../../assets/images/vip.png" style="position:absolute;right:-25px;top:0"></span>
        </div>
		<div class="mCon mcon3">
			<div class="modThree">
                <div style="text-indent: 2em;">文文通是与文华WT8软件紧密结合的一款工具软件，除具有一般IM软件的基本功能外，还提供页面、指标、模型的传送功能。</div>
                <!-- <ResTxt style="text-indent: 2em;" id="d5a41e769681e" /><br> -->
                <!-- <ResTxt style="text-indent: 2em;" id="c21eec69a17ed" /><br> -->
                <div style="text-indent: 2em;">购买WT8客户可同时获得文文通服务，与高级金融工程师对话，为您在产品使用过程中遇到的问题、出现的疑虑提供适合的解决方案。</div>
                <ResTxt style="text-indent: 2em;" id="e9c6df41a78e6" /><br>
                <div style="font-weight:bold;margin-top:10px">调用文文通方法：WT8界面的右上角菜单，[帮助]－>登录文文通Vip服务软件</div>
				<!-- <p style="text-indent: 2em;">文文通除具有一般IM软件的基本功能外，还提供页面、指标、模型的传送功能。</p>
				<p style="text-indent: 2em;">购买wh8客户可获得文文通服务，与高级金融工程师对话，为您在产品使用过程中遇到的问题、出现的疑虑提供适合的解决方案。</p>
				<p style="text-indent: 2em;">金融工程师团队成员，由具有金融类本科/研究生学历、持有期货从业资格证的专业人员组成。</p> -->
			</div>
		</div>
		<div class="mpic3"></div>
	</div>
    <div class="mode" style="height: 380px;">
		<div class="mt"><span class="modeNm">渠道四：</span>小课堂</div>
		<div class="mCon mcon3">
			<div class="modThree">
                <div style="text-indent: 2em;">文华财经小课堂，作为持续多年线下培训的互联网版本，由研究部的资深金融工程师进行授课。</div>
                <div style="text-indent: 2em;">小课堂的课程，从软件工具的角度出发，扩展到期权交易、量化投资、算法交易、套利交易、股票价值投资的策略实现层面，旨在帮助客户提高交易能力。</div>
                <div style="text-indent: 2em;">每周二、周四15:30，文华财经小课堂准时开播。</div>
			</div>
            <div class="goIn" style="margin-top:15px;font-weight:bold"><strong>·</strong>&nbsp;直播入口：微吼平台，<a :href=url target="_blank" style="cursor: pointer;color:#367ff5" id="whlink"> 点击进入直接看直播</a>；</div>
            <div class="goIn" style="font-weight:bold"><strong>·</strong>&nbsp;往期视频：微信视频号<br>
            <span style="display:block;height:3px"></span>
            <img src="../../assets/images/saoma2.png" style="margin-left:9px;width:100px"><span style="position: relative;top: -71px;margin-left:122px;display:block">  扫码关注 或者微信里搜索"文华财经小课堂"，在"视频号"里观看往期内容的视频回放，也可获得更多的直播开课信息。
</span></div>
		</div>
		<div class="mpic5"></div>
	</div>
	<!-- <div class="mode" style="height: 310px;">
		<div class="mt"><span class="modeNm">渠道四：</span>wh8宽语言编程培训</div>
		<div class="mCon mcon4" style="width: 580px;">
			<p style="text-indent: 2em;">由文华专业金融工程师、讲师通过系统、专业的课程安排，循序渐进现场讲解、解惑。课程包含：基本语法、模型结构、编写技巧、实战策略解析等。</p>
			<p><span class="mtTit">培训地址:</span>上海</p>
			@* <p><span class="mtTit">培训费用:</span>9B套餐有效期内用户可享<span style="color:#960f03;font-weight:700;">免费听课</span>礼遇，未购买的客户课程费用6000元/人/3天课程</p> *@
			<p><span class="mtTit">培训时长:</span>可选初级班和提高班，每期3天</p>
			<p><span class="mtTit">培训时间:</span>9:00~11:30 13:30~16:00 答疑辅导19:00~20:30</p>
			<br>
			<p>注：建议有Python、java或c语言编程基础的用户参加。</p>
			<p><a class="linka" style="margin-left: 0;" href="/Service/wh8SingUp" target="_blank">点击报名</a></p>
		</div>
		<div class="mpic4"></div>
	</div> -->
</div>
    <!-- <wh8footer></wh8footer> -->
  </div>
</template>

<script>
import ResTxt from '@/ResData/ResTxt'
// import wh8footer from "./components/wh8/wh8footer.vue";
import axios from "axios";
export default {
    data(){
        return{
            url:'',
        }
    },
  components: {
    // wh8footer,
    ResTxt
  },
  methods:{
        custormAnchor(anchorName) {
      // // 找到锚点
      // let anchorElement = document.getElementById(anchorName);

      // // 如果对应id的锚点存在，就跳转到锚点

      // if(anchorElement) {
      // this.$nextTick(()=>{
      //   window.scrollTo(0,anchorElement.offsetTop+50);

      // })

      //  }
        this.$nextTick(()=>{

       var target = document.getElementById(anchorName);
            if (target !== null) {
                // console.log(target.offsetTop)
                // window.scrollTo(target.offsetTop,target.offsetTop)
            window.scrollTo(0,target.offsetTop);
            }
        })

    },
  },
  watch:{
    "$route": {
      handler: function(val){
        console.log(val)
        this.custormAnchor(val.query.id);

      },
      immediate: true,
      deep: true
    },
  },
//   mounted(){
//     setTimeout(()=>{this.custormAnchor(this.$route.query.id)},1000)
//   },
  created(){
axios.get('https://wh-api.wenhua.com.cn/api/officemanage/smallclassurllist').then(res=>{
                var dat = res.data
                var date=new Date()
                var day=date.getDay()
                console.log(dat)
                if(day==5){
                this.url=dat[5].url
                }
                else if(day==2){
                this.url=dat[2].url
                }
                else if(day==3){
                this.url=dat[3].url
                }
                else if(day==4){
                this.url=dat[4].url
                }
                else if(day==1){
                this.url=dat[1].url
                }
                else if(day==6){
                this.url=dat[6].url
                }
                else{
                this.url=dat[0].url
                }
      })
  },
  mounted(){
        this.$nextTick(()=>{
            this.custormAnchor(this.$route.query.id);
        })
  }
};
</script>

<style scoped>
.serivehtml {
  background: url("../../assets/images/vip-top.png") no-repeat top;
  background-size:100% auto ;
  min-height: 839px;
  min-width: 1400px;
  width: 100%;
  padding-top: 110px;
}
.content {
    width: 940px;
    margin: 0px auto;
    position: relative;
    background: #fff;
    border-radius: 10px 10px 0 0 ;
    padding: 30px;
}
.prompt {
    width: 100%;
    color: #1f2d54;
    border-bottom: 1px solid #ccc;
    text-indent: 1em;
    font-size: 18px;
    font-weight: 800;
    line-height: 36px;
}

.mode {
    width: 100%;
    /* overflow: hidden; */
    /* background: #f5f5f5; */
    margin-bottom: 25px;
    height: 275px;
    position: relative;
    /* border-radius: 25px 0 0 0; */
}

.mode .myQuant {
    font-size: 16px;
    color: #555;
}

.mt {
    /* width: 100%;
    overflow: hidden; */
    /* border-left: 4px solid #02306e; */
    border-radius: 15px 0 0 0;
    background: #e0e7fa;
    font-weight: 700;
    line-height: 40px;
    font-size: 16px;
    padding-left: 28px;
    color: #253f62;
}

.modeNm {
    width: 70px;
    height: 50px;
    text-align: center;
    font-size: 17px;
    
    /*background: #3a84ff;*/
}

.modeTil {
    font-size: 17px;
    width: 760px;
    margin-left: 12px;
}

.mCon {
    width: 570px;
    margin-left: 33px;
}

.mcon1 {
    margin-top: 48px;
}

.mcon2 {
    margin-top: 48px;
}

.mcon3 {
    margin-top: 48px;
}

.mcon4 {
    margin-top: 30px;
}

.mCon div {
    font-size: 14px;
    color: #888;
    line-height: 25px;
}

.modThree {
    width: 100%;
    margin: 15px 0;
}

.mtTit {
    font-size: 14px;
    font-weight: 700;
    line-height: 25px;
    color: #888;
}

.linka {
    margin-right: 20px;
    font-size: 13px;
    color: #495388;
    font-weight: normal;
    margin-left: 10px;
}

.mpic1,
.mpic2,
.mpic3,
.mpic4,
.mpic5 {
    position: absolute;
    top: 89px;
    right: 40px;
}

.mpic1 {
    background: url("../../assets/images/mpic1.png") no-repeat;
    width: 221px;
    height: 164px;
}

.mpic2 {
    background: url("../../assets/images/mpic2.png") no-repeat;
    width: 222px;
    height: 140px;
    margin-top: -5px;
}

.mpic3 {
    background: url("../../assets/images/mpic3.png") no-repeat;
    width: 280px;
    height: 110px;
    margin-top: 15px;
}

.mpic4 {
    top: 80px;
    background: url("../../assets/images/mpic4.png") no-repeat;
    width: 246px;
    height: 176px;
}
.mpic5 {
    top: 148px;
    background: url("../../assets/images/mpic5.png") no-repeat;
    width: 280px;
    height: 176px;
}


</style>