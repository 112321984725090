import Vue from 'vue'
import ResLogin from "./ResLogin";
import axios from 'axios';

// 待请求的数据表
let data = {}

// 已经请求过的缓存
let cache = {}

let collecting = false

function init() {
    let dom = document.createElement('div')
    document.body.appendChild(dom)

    new Vue({
        render: h => h(ResLogin),
    }).$mount(dom)
}

init();

export default {
    add(id, value) {
        if (!collecting) {

            // 异步计时收集 id
            setTimeout(async () => {
                // 初始化收集 id 后触发请求
                let id_list = Object.keys(data)

                let result = await axios.get('https://assets.wenhua.com.cn/api/Copywriter/GetDocuments?docids=' + id_list, {
                    headers: {
                        Authorization: ''
                    }
                })
                result = result.data.resultData

                result && result.forEach(item => {
                    let value

                    try {
                        value = JSON.parse(item.content)
                    } catch (e) {
                        console.log('json 数据解析错误', item.content)
                    }

                    cache[item.docid] = value

                    let target = data[item.docid]
                    target && target.callback(value)
                    delete data[item.docid]
                })

                collecting = false

                // 回调没有数据的 id
                Object.keys(data).forEach(id => {
                    data[id].callback(null);
                    delete data[id];
                })

            }, 0)
            collecting = true
        }
        data[id] = value
    }
}