import axios from "axios";


const service = axios.create({
    method: "get",
    // 请求超时，8s
    // timeout: 10000
});
/**
 * 请求拦截器
 */
service.interceptors.request.use(
    config => config,
    error => error
);
/**
 * 响应拦截器
 * 拦截相应请求并且直接返回data字段
 */
service.interceptors.response.use(
    res => res.data,
    error => error
);
export const stateform = () => {
    return service({
        url: "//wh-api.wenhua.com.cn/api/Version/GetItemItTypeList",
        
    });
};
export const banben = (data) => {
    return service({
        url: "https://wt9.wenhua.com.cn/api/Version/GetVersion",
        data:data,
    });
};