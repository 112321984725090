<template>
  <div class="aboveallhtml">
    <div class="aboveallmid">
      <div class="aboveallhead">
        <transition name="flip-list" mode="out-in">
          <div
            :style="{
              background: 'url(' + imagesrc[imgindex] + ') no-repeat center',
            }"
            :src="imagesrc[imgindex]"
            :key="imagesrc[imgindex]"
            class="imgsrc"
            id="imgsrc"
            @mouseenter="swiperEnter"
            @mouseleave="swiperLeave"
          >
           <!-- <div class="swiper-list" style="min-width: 50px;
    height: 40px;
    position: absolute;
    bottom: 0;
    left: 70%;">
              <i v-for="(item,index) in imagesrc" :style="{background:imgindex == index?'#b29035':'#fff'}" :key = 'index' @click="swiperIndex(index)"></i>
           </div> -->
          </div>
        </transition>
      </div>
      <div class="aboveallyellow">
        <div class="yellowtext">
          <div class="yellowleft">
            <p
              style="
                color: white;
                font-size: 20px;
                text-align: right;
                line-height: 30px;
                font-weight: bold;
                margin-top: 35px;
                margin-right: 30px;
                letter-spacing: 1px;
              "
            >
             麦语言
            </p>
            <p
              style="
                color: white;
                font-size: 20px;
                text-align: right;
                line-height: 30px;
                font-weight: bold;
                margin-right: 30px;
                letter-spacing: 1px;
              "
            >
              量化交易软件
            </p>
          </div>
          <div class="yellowright">
            <ResTxt class="righttext" id="78c32985c2e58" />
            <div class="aquestion">
              <img src="../../assets/images/wenh.png" />
              <div
                style="
                  position: relative;
                  bottom: 4px;
                  display: inline-block;
                  margin-left: 5px;
                "
              >
                <a
                  style="text-decoration: none; color: #304560; font-size: 12px"
                  target="_blank"
                  href="https://pay.wenhua.com.cn/#/question"
                >
                  购买相关常见问题</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="aboveallbody">
        <!-- <div class="body">
          <div>
            <img src="../../assets/images/1.jpg" alt="">
          </div>
          <div style="margin-left:36px;">
            <img src="../../assets/images/2.jpg" alt="">
          </div>  
        </div> -->
      
        <div class="body">
          <div class="bodylf">
            <div class="abbodylf">
              <div style="width: 630px; height: 80px">
                <img src="../../assets/images/liyou.png" style="float: right" />
              </div>
              <div class="back1" @click="yijiduoping" :name="yjdp">
                <p class="back11">主连链回测</p>
              </div>
              <div class="back2" @click="yunduangongneng">
                <p class="back21">头寸管理</p>
              </div>

              <div class="back4" @click="kuozhanfenxi">
                <div class="A03b"></div>
                <p class="back41">远程监控模型运行情况</p>
                
              </div>
            </div>
          </div>
          <div class="bodyrg">
            <div class="abbodyrg">
              <div class="back5" @click="xuanpei">
              <div class="A04b"></div>
                <p
                  style="
                    margin-top: 40%;
                    margin-left: 10%;
                    font-size: 18px;
                    color: #664712;
                  "
                >
                  模组持仓匹配校验
                </p>
                <!-- <p
                  style="
                    margin-top: 21px;
                    margin-left: 10%;
                    font-size: 14px;
                    color: #a27f2c;
                  "
                >
                  五档行情数据、外盘行情数据
                </p> -->
              </div>
              <div class="back6" @click="kxianzengjia">
              <div class="A05b"></div>
                <p
                  style="
                    margin-top: 50%;
                    margin-left: 10%;
                    font-size: 18px;
                    color: #fff;
                  "
                >
                  产品申购/赎回管理
                </p>
                
              </div>
              <div class="back7" @click="gaoduan">
                <p
                  style="
                    margin-top: 45%;
                    margin-left: 50%;
                    font-size: 18px;
                    color: #fff;
                  "
                >
                股票T+1策略运行池
                </p>
               
              </div>
              <div class="back8" >
               
              </div>
              
            </div>
          </div>
        </div>


      
      </div>
    </div>
    <div class="fixed">
      <div style="width:50%;text-align:right;">
      <a href="https://lhw.wenhua.com.cn/"  target="_blank" style="margin-right:540px;pointer-events:all; ">
      <img src="../../assets/images/baoming.png" style="z-index:999;" alt="">
      </a>
      </div>
    </div>
    <wh8footer></wh8footer>
  </div>
</template>

<script>
import ResTxt from '@/ResData/ResTxt'
import wh8footer from "./components/wh8/wh8footer.vue";

 
export default {
  components: {
    wh8footer,
    ResTxt
  },
  data() {
    return {
      yjdp: false,

      activeName: "first",
      imgindex: 0,
      imagesrc: [
        // require("../../assets/images/banner.jpg")
      require("../../assets/images/banner2.jpg")
      // ,require("../../assets/images/wh8banner3.jpg")
      ],
      test: {},
      timeset: null,
    };
  },
  methods: {
    // wh8lunbo(){
    //   while(this.index<this.imagesrc.length){
    //   setInterval(function(){ this.index=this.index+1 }, 2000);

    //   console.log(this.imagesrc[this.index])
    //   }
    //   this.index=0
    //    this.wh8lunbo()
    // },
    swiperEnter(){
      clearInterval(this.timer)
    },
    swiperLeave(){
      this.wh8lunbo();
    },
    swiperIndex(index){
      this.imgindex = index;
    },
    wh8lunbo() {
      this.timer = setInterval(() => {
        this.gotoPage(this.nextIndex);
      }, 2000);
    },
    gotoPage(index) {
      this.imgindex = index;
    },
    yijiduoping() {
      var newpage = this.$router.resolve({ path: `/proc/productcenter/productcenter5` });
      window.open(newpage.href, "_blank");
    },
    yunduangongneng() {
      var newpage = this.$router.resolve({ path: `/proc/productcenter/productcenter12` });
      window.open(newpage.href, "_blank");
    },
    yidongkxian() {
      var id = "productcenterone";
      var newpage = this.$router.resolve({ path: `/proc/productcenter/${id}` });
      window.open(newpage.href, "_blank");
    },
    kuozhanfenxi() {
      var newpage = this.$router.resolve({ path: `/proc/productcenter/productcenter2` });
      window.open(newpage.href, "_blank");
    },
    xuanpei() {
      var newpage = this.$router.resolve({ path: `/proc/productcenter/productcenter3` });
      window.open(newpage.href, "_blank");
    },
    kxianzengjia() {
     var newpage = this.$router.resolve({ path: `/proc/productcenter/productcenter4` });
      window.open(newpage.href, "_blank");
    },
    gaoduan() {
      var newpage = this.$router.resolve({ path: `/proc/productcenter/productcenter11` });
      window.open(newpage.href, "_blank");
    },
    shendu() {
      var id = "productcentereight";
      var newpage = this.$router.resolve({ path: `/proc/productcenter/${id}` });
      window.open(newpage.href, "_blank");//单页面跳转路由锚点并打开新页面
    },
    // myswiper: function() {
		// 		var swiper = new Swiper('.swiper-container', {
		// 			pagination: {
		// 				el: '.swiper-pagination',
		// 			},
		// 		});
		// 	}

  },
  computed: {

    nextIndex() {
      if (this.imgindex == this.imagesrc.length - 1) {
        return 0;
      } else {
        return this.imgindex + 1;
      }//首页最上方图片轮播
    },
  },
  mounted() {
    this.wh8lunbo();
    
 
  },
};
</script>

<style scoped>

td {
  height: 35px;
  border: 1px solid #cdcdcd;
  line-height: 35px;
  vertical-align: middle;
  font-size: 14px;
  padding-left: 40px;
  letter-spacing: 1px;
}
.aboveallhtml {
  z-index: 30;
  margin: 0 auto;
  /* min-width: 1400px; */
  width: 100%;
}
.aboveallhead {
  text-align: center;
  height: 390px;
  min-width: 1400px;
  width: 100%;
  margin: 0 auto;
}
.imgsrc {
  background: url("../../assets/images/banner.jpg") no-repeat center;
  height: 100%;
  min-width: 1400px;
  width: auto;
  /* position: relative; */
}
.aboveallyellow {
  background: url("../../assets/images/about.jpg") no-repeat center;
  height: 126px;
  background-size: auto 100%;
  min-width: 1400px;
  width: 100%;
  margin: 0 auto;
}
.aboveallbody {
  background: url("../../assets/images/ly-bg.jpg") no-repeat center;
  height:665px;
  background-size: 100% 100%;
  width: 1400px;
  width: 100%;
  margin: 0 auto;
}
.yellowright {
  float: right;
  width: 870px;
  height: 129px;
}
.yellowleft {
  float: left;

  width: 330px;
  height: 129px;
}
.yellowtext {
  width: 1200px;
  margin: 0 auto;
  height: 129px;
}
.righttext {
  width: 764px;
  font-size: 14px;
  margin-left: 50px;
  margin-top: 37px;
  line-height: 20px;
  margin-right: 0px;
  letter-spacing: 1px;
  color: #5a5a5a;
}
.aquestion {
  /* float: right; */
  margin-top: 15px;
  margin-left: 50px;
  position: relative;
  z-index: 9;
}
.abbodylf {
  width: 605px;
  height: 600px;

  position: relative;
}
.abbodyrg {
  width: 590px;
  height: 600px;

  position: relative;
}
.back1 {
  background: url("../../assets/images/A01.png") no-repeat center;
  width: 277px;
  height: 444px;
  position: absolute;
  left: 10px;
  background-size: 100% 100%;
  top: 100px;
  cursor: pointer;
  transition: width 0.5s, height 0.5s;
  -moz-transition: width 0.5s, height 0.5s;
  -webkit-transition: width 0.5s, height 0.5s;
  -o-transition: width 0.5s, height 0.5s;
}
.back2 {
  background: url("../../assets/images/A02.png") no-repeat center;
  top: 100px;
  width: 339px;
  height: 274px;
  right: -30px;
  position: absolute;
  background-size: 100% 100%;
  cursor: pointer;
  transition: width 0.5s, height 0.5s;
  -moz-transition: width 0.5s, height 0.5s;
  -webkit-transition: width 0.5s, height 0.5s;
  -o-transition: width 0.5s, height 0.5s;
}
.back3 {
  background: url("../../assets/images/A03a.jpg") no-repeat center;
  width: 250px;
  height: 200px;
  position: absolute;
  left: 10px;
  background-size: 100% 100%;
  top: 345px;
  cursor: pointer;
  transition: width 0.5s, height 0.5s;
  -moz-transition: width 0.5s, height 0.5s;
  -webkit-transition: width 0.5s, height 0.5s;
  -o-transition: width 0.5s, height 0.5s;
}
.back4 {
  background: url("../../assets/images/A03a.jpg") no-repeat center;
  top: 382px;
  width: 339px;
  height: 162px;
  right: -30px;
  position: absolute;
  background-size: 100% 100%;
  cursor: pointer;
  transition: width 0.5s, height 0.5s;
  -moz-transition: width 0.5s, height 0.5s;
  -webkit-transition: width 0.5s, height 0.5s;
  -o-transition: width 0.5s, height 0.5s;
}
.back4 .A03b{
   background: url("../../assets/images/A03b.png") no-repeat center;
   width: 160px;
   height: 98px;
       right: -35px;
    position: absolute;
    top: -20px;
    z-index: 99;
}
.bodylf {
  margin-top: 60px;
  width: 630px;
  height: 600px;
  float: left;
  padding-left: 20px;
}
.bodyrg {
  margin-top: 80px;
  width: 590px;
  height: 580px;
  float: left;
  padding-left: 20px;
}
.back1:hover {
  width: 287px;
  height: 464px;
  z-index: 50;
}
.back2:hover {
  width: 349px;
  height: 284px;
  z-index: 50;
}
.back3:hover {
  width: 270px;
  height: 210px;
  z-index: 50;
}
.back4:hover {
  width: 349px;
  height: 170px;
  z-index: 50;
}
.back5 {
  background: url("../../assets/images/A04a.png") no-repeat center;
  width: 269px;
  height: 289px;
  position: absolute;

  background-size: 100% 100%;

  cursor: pointer;
  transition: width 0.5s, height 0.5s;
  -moz-transition: width 0.5s, height 0.5s;
  -webkit-transition: width 0.5s, height 0.5s;
  -o-transition: width 0.5s, height 0.5s;
}
.back5 .A04b{
   background: url("../../assets/images/A04b.png") no-repeat center;
   width: 138px;
   height: 149px;
       right: -30px;
    position: absolute;
    bottom: -2px;
    z-index: 99;
}
.back6 {
  background: url("../../assets/images/A05a.png") no-repeat center;
  width: 301px;
  height: 289px;
  position: absolute;
  right: 10px;
  background-size: 100% 100%;

  cursor: pointer;
  transition: width 0.5s, height 0.5s;
  -moz-transition: width 0.5s, height 0.5s;
  -webkit-transition: width 0.5s, height 0.5s;
  -o-transition: width 0.5s, height 0.5s;
}
.back6 .A05b{
   background: url("../../assets/images/A05b.png") no-repeat center;
   width: 161px;
   height: 143px;
       right: -36px;
    position: absolute;
    top: -13px;
    z-index: 99;
}

.back7 {
  background: url("../../assets/images/A06.png") no-repeat center;
  width: 366px;
  height: 223px;
  position: absolute;
  left: 0px;
  top: 300px;
  background-size: 100% 100%;
  z-index: 8;
  cursor: pointer;
  transition: width 0.5s, height 0.5s;
  -moz-transition: width 0.5s, height 0.5s;
  -webkit-transition: width 0.5s, height 0.5s;
  -o-transition: width 0.5s, height 0.5s;
}
.back8 {
  background: url("../../assets/images/A07.png") no-repeat center;
  width: 207px;
  height: 223px;
  position: absolute;
  right: 10px;
  top: 300px;
  background-size: 100% 100%;
}

.back6:hover {
  width: 311px;
  height: 299px;
  z-index: 50;
}
.back5:hover {
  width: 279px;
  height: 309px;
  z-index: 50;
}
.back7:hover {
  width: 376px;
  height: 243px;
  z-index: 50;
}

.body {
  width: 1280px;
  margin: 0 auto;
  min-height: 400px;
}
.body div{
  display: inline-block;
  /* margin-top: 50px; */
}
.flip-list-enter-active,
.flip-list-leave-active {
  transition: all 1s;
}
.flip-list-enter,
.flip-list-leave {
  opacity: 0;
}
.back11 {
  margin-top: 300px;
  margin-left: 145px;
  font-size: 18px;
  color: #fff;
  transition: margin-top 0.5s, margin-left 0.5s;
  -moz-transition: margin-top 0.5s, margin-left 0.5s;
  -webkit-transition: margin-top 0.5s, margin-left 0.5s;
  -o-transition: margin-top 0.5s, margin-left 0.5s;
}
.back1:hover .back11 {
  margin-top: 310px;
  margin-left: 155px;
}
.back21 {
  margin-top: 75px;
  margin-left: 25px;
  font-size: 18px;
  color: #fff;
  transition: margin-top 0.5s, margin-left 0.5s;
  -moz-transition: margin-top 0.5s, margin-left 0.5s;
  -webkit-transition: margin-top 0.5s, margin-left 0.5s;
  -o-transition: margin-top 0.5s, margin-left 0.5s;
}
.back22 {
  margin-top: 20px;
  margin-left: 25px;
  font-size: 14px;
  color: #2f4b62;
  transition: margin-top 0.5s, margin-left 0.5s;
  -moz-transition: margin-top 0.5s, margin-left 0.5s;
  -webkit-transition: margin-top 0.5s, margin-left 0.5s;
  -o-transition: margin-top 0.5s, margin-left 0.5s;
}
.back2:hover .back21 {
  margin-top: 85px;
  margin-left: 20px;
}
.back2:hover .back22 {
  margin-top: 20px;
  margin-left: 35px;
}
.back31 {
  margin-top: 120px;
  margin-left: 170px;
  font-size: 18px;
  color: #2f4b62;
  transition: margin-top 0.5s, margin-left 0.5s;
  -moz-transition: margin-top 0.5s, margin-left 0.5s;
  -webkit-transition: margin-top 0.5s, margin-left 0.5s;
  -o-transition: margin-top 0.5s, margin-left 0.5s;
}
.back32 {
  margin-top: 5px;
  margin-left: 150px;
  font-size: 18px;
  color: #2f4b62;
  transition: margin-top 0.5s, margin-left 0.5s;
  -moz-transition: margin-top 0.5s, margin-left 0.5s;
  -webkit-transition: margin-top 0.5s, margin-left 0.5s;
  -o-transition: margin-top 0.5s, margin-left 0.5s;
}
.back3:hover .back31 {
  margin-top: 140px;
    margin-left: 190px;
}
.back3:hover .back32 {
  margin-left: 165px;
}
.back41 {
  /* margin-top: 120px; */
  margin-left: 140px;
  position: absolute;
    bottom: 36px;
  font-size: 18px;
  color: #664712;
  transition: margin-top 0.5s, margin-left 0.5s;
  -moz-transition: margin-top 0.5s, margin-left 0.5s;
  -webkit-transition: margin-top 0.5s, margin-left 0.5s;
  -o-transition: margin-top 0.5s, margin-left 0.5s;
}
.back42 {
  margin-top: 20px;
  margin-left: 35px;
  font-size: 14px;
  color: #a27f2c;
  transition: margin-top 0.5s, margin-left 0.5s;
  -moz-transition: margin-top 0.5s, margin-left 0.5s;
  -webkit-transition: margin-top 0.5s, margin-left 0.5s;
  -o-transition: margin-top 0.5s, margin-left 0.5s;
}
.back43 {
  margin-top: 10px;
  margin-left: 35px;
  font-size: 14px;
  color: #a27f2c;
  transition: margin-top 0.5s, margin-left 0.5s;
  -moz-transition: margin-top 0.5s, margin-left 0.5s;
  -webkit-transition: margin-top 0.5s, margin-left 0.5s;
  -o-transition: margin-top 0.5s, margin-left 0.5s;
}
.back4:hover .back41 {
 /* margin-top: 140px; */
    margin-left: 140px;
}
.back4:hover .back42 {
  margin-top: 20px;
  margin-left: 40px;
}
.back4:hover .back43 {
  margin-top: 10px;
  margin-left: 40px;
}
.aboveallmid {
  min-width: 1400px;
  max-width: 100%;
}
.fixed{
  width: 100%;
  height: 264px;
  position: absolute;
  top: 380px;
  pointer-events:none; 
}
.swiper-list{

}
.swiper-list i{
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #fff;
  margin-right: 15px;
  cursor: pointer;
}
.swiper-list i:hover{
  background:#b29035;
}
</style>