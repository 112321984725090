<template>
  <div class="tongyongdl">
    <div class="tongyongcard" style="width:800px;height:auto">
      <img src="../../../../assets/images/mainf.png" style="margin-top: 40px;margin-bottom:35px" />
      <p
        style="
          color: #0e286d;
          line-height: 20px;
          letter-spacing: 1px;
          font-size: 14px;
          padding:7px 0;
          text-align:left;
        "
      >
        <strong style="font-weight:bold">· </strong>模型编写、回测等基本量化功能免费开放使用，页面盒子与运行模组二个月体验期后付费使用。<br>
        <strong style="font-weight:bold">· </strong>软件需注册体验账号（在软件登录界面有注册入口）后，登录使用。

      </p>
      <!-- <p
        style="
          color: #0e286d;
          letter-spacing: 1px;
          font-size: 14px;
          margin-bottom:18px;
          text-align:left;
        "
      >
        提醒： 量化软件没有多窗口等功能，不适合单纯做技术分析、看盘的客户使用。
      </p> -->
    </div>
    <div style="border-top:1px dashed #b5c3d8;width:800px;height:1px;margin:0 auto"></div>
    <div class="tongyongbody">
      <div class="tongyongbodydl">
        <div class="tongyongdllf">
          <h4
            style="
              color: #1e5c88;
              line-height: 50px;
              height: 50px;
              padding-top: 30px;
              letter-spacing: 1px;
            "
          >
            使用应满足的条件
          </h4>
          <ResTxt class="h4p" id="6d0e0248b2552" /><br>
          <ResTxt class="h4p" id="b5993041466ee" /><br>
          <ResTxt class="h4p" id="c781c5152f096" /><br>
          <ResTxt class="h4p" id="33ca9438d16c1" /><br>
          <ResTxt class="h4p" id="45f32e0852da6" /><br>
          <!-- <p class="h4p">1、CPU：X86架构，4核及以上配置；</p>
          <p class="h4p">2、内存：要达到8G以上；</p> -->
          <!-- <router-link to="/download/softAdvantage"><a class="linka" >64位软件的优势</a></router-link> -->
          <!-- <p class="h4p">3、操作系统：win7/win8/win10/win2008的64位；</p>
          <p class="h4p">4、建议使用电信、联通宽带，其它宽带会有卡顿的问题。</p>
          <p class="h4p">
            5、租用云主机仅做模组运行的情况下，最低配置2核CPU/4G内存就可以运行。
          </p> -->
        </div>
        <div class="tongyongdlrg">
          <!-- <p class="shangmen">
            <span @click="open"
              ><img
                src="../../../../assets/images/shangm.png"
                style="vertical-align: middle"
              />
              预约上门讲解软件使用</span
            >
          </p> -->
          <div class="dlbutton">
            <div
              style="
                background: #c4954d;
                border-radius: 0.5em;
                height: 45px;
                width: 140px;
                color: white;
                font-size: 18px;
                line-height: 45px;
                text-align: center;
                display: inline-block;

                cursor: pointer;
              "
              @click="dianxin"
            >
              电信下载
            </div>
            <div
              style="
                background: #364064;
                border-radius: 0.5em;
                height: 45px;
                width: 140px;
                color: white;
                font-size: 18px;
                line-height: 45px;
                text-align: center;
                display: inline-block;
                margin-left: 40px;
                cursor: pointer;
              "
              @click="liantong"
            >
              联通下载
            </div>
          </div>
          <div
            style="
              font-size: 12px;
              height: 54px;
              line-height: 54px;
              color: #9b9b9c;
              letter-spacing: 1px;
            "
          >
            {{ tybanben }}
          </div>
          <div
            style="
              width: 318px;
              font-size: 12px;
              letter-spacing: 1px;
              padding: 5px 5px 0 5px;
              background: #d6e7ff;
            "
          >
            <img
              src="../../../../assets/images/care.png"
              style="vertical-align: top; margin-right: 2px"
            />
            <span style="color: #727983"
              >请在文华官网下载正版文华软件，文华官网下载不会</span
            >
            <br />
            <span style="padding-left: 18px; line-height: 24px; color: #727983"
              >以任何形式向你索要电话号码，请注意谨防上当。</span
            >
          </div>
          <!-- <p class="shangmen">
            <span @click="open"
              ><img
                src="../../../../assets/images/shangm.png"
                style="vertical-align: middle"
              />
              预约上门讲解软件使用</span
            >
          </p> -->
        </div>
      </div>
      <div style="margin-left: 40px">
        <h4
          style="
            color: #1e5c88;
            line-height: 50px;
            height: 50px;
            padding-top: 30px;
            letter-spacing: 1px;
          "
        >
          注册体验账号
        </h4>
        <ResTxt
          class="h4p"
          id="6e77ae46daa36"
          style="
            text-indent: 2em;
            margin-right: 20px;
            letter-spacing: 1px;
            font-size: 15px;
            height: auto;
          "
        />
        <!-- <p
          class="h4p"
          style="
            text-indent: 2em;
            margin-right: 20px;
            letter-spacing: 1px;
            font-size: 15px;
          "
        >
          体验账号及密码在注册成功后将通过短信形式发送至你注册时预留的手机号。
        </p> -->
        <div style="margin-top: 25px; margin-left: 220px">
          <img src="../../../../assets/images/mn.png" />
        </div>
      </div>
    </div>
    <pingjia></pingjia>
  </div>
</template>

<script>
import ResTxt from '@/ResData/ResTxt'
import pingjia from "./pingjia.vue";
import { jsonp } from "vue-jsonp";
export default {
  data() {
    return {
      tybanben: "",
      tydata: "",
    };
  },
  components: {
    pingjia,
    ResTxt
  },
  methods: {
    dianxin() {
      if (this.tydata.split("|")[0] == "") {
        alert("下载信息加载中，请稍后再试");
      } else {
        window.location.href =
          "//ftpc.wenhua.com.cn/files/WT8sim_setup_" +
          this.tydata.split("|")[0] +
          ".exe";
      }
    },
    liantong() {
      if (this.tydata.split("|")[0] == "") {
        alert("下载信息加载中，请稍后再试");
      } else {
        window.location.href =
          "//ftpc1.wenhua.com.cn/files/WT8sim_setup_" +
          this.tydata.split("|")[0] +
          ".exe";
      }
    },
    open() {
      this.$emit("openModal");
    },
  },
  created() {
    jsonp("https://db.wenhua.com.cn/inc/update.asp", {
      appid: 11,
      t: 1,
    })
      .then((data) => {
        this.tydata = data;

        this.tybanben =
          " 版本:" +
          this.tydata.split("|")[0] +
          "" +
          " (第" +
          this.tydata.split("|")[3] +
          "次修改)" +
          "" +
          " 大小:" +
          this.tydata.split("|")[2] +
          "" +
          " 日期:" +
          this.tydata.split("|")[1];
      })
      .catch((err) => {
        console.log(err);
      });
  },
};
</script>

<style >
.tongyongdl {
  width: 1005px;
  /* background: url("../../../../assets/images/1.png") no-repeat top; */
  /* background-size: 1005px auto; */
  min-height: 200px;
}
.tongyongcard {
  width:800px;
  margin: 0 auto;

  text-align: center;
  /* padding-top: 30px; */
}
.linka {
    /* margin-right: 20px; */
    font-size: 13px;
    color: #495388;
    font-weight: normal;
    margin-left: 10px;
}
.tongyongbody {
  width: 1003px;
  min-height: 633px;
  /* border: 1px solid #7f83b3; */
  border-top: none;
  border-bottom: none;
  background: #e8edf5;
  padding-bottom: 100px;
}
.tongyongbodydl {
  width: 1005px;
  min-height: 220px;
}
.tongyongdllf {
  width: 528px;
  float: left;
  height: 190px;
  margin-left: 20px;
}
.tongyongdlrg {
  padding-top: 45px;
  width: 400px;
  float: right;
  height: 180px;
  position: relative;
}
.h4p {
  height: 28px;
  line-height: 28px;
  color: #727983;
}
.dlbutton {
  /* margin-top: 100px; */
}
.pingjia {
  width: 1003px;
  background: url("../../../../assets/images/2.png") no-repeat top;
  background-size: 1003px auto;
  min-height: 520px;
  margin-bottom: 3px;
  /* border: 1px solid #7f83b3; */
  border-top: none;
  border-bottom: none;
  padding-top: 100px;
  margin-top: -100px;
}
.yongpingjia {
  /* margin-top: 50px; */
  letter-spacing: 1px;
  background: url("../../../../assets/images/yonghpj.png") no-repeat left;
  margin-left: -9px;
  color: white;
  height: 40px;
  width: 110px;
  text-align: center;
  line-height: 30px;
}

.pagebt {
  height: 25px;
  width: 25px;
  text-align: center;
  line-height: 25px;
  cursor: pointer;
  background: #ffffff;
  color: #a6a6a6;
  float: right;
  margin-left: 5px;
  margin-right: 5px;
  user-select: none;
  border: 1px solid #dfdfdf;
}
.clickpagebt {
  height: 25px;
  width: 25px;
  text-align: center;
  line-height: 25px;
  cursor: pointer;
  background: #044c7e;
  color: #ffffff;
  margin-left: 5px;
  margin-right: 5px;
  float: right;
  user-select: none;
  border: 1px solid #dfdfdf;
}
.notclick {
  height: 25px;
  width: 25px;
  text-align: center;
  line-height: 25px;
  cursor: pointer;
  background: #ffffff;
  color: #a6a6a6;
  float: right;
  margin-left: 5px;
  margin-right: 5px;
  pointer-events: none;
  user-select: none;
  border: 1px solid #dfdfdf;
}
.star {
  margin-left: 5px;
  position: relative;
  top: 3px;
}
.starpj {
  height: 60px;
  line-height: 60px;
  color: #575759;
  font-size: 16px;
  margin-left: 40px;
  font-weight: bold;
  letter-spacing: 1px;
}
.shangmen {
  font-size: 15px;
  line-height: 34px;
  vertical-align: middle;
  letter-spacing: 1px;
  /* margin-top: 38px; */
  color: #193ea5;
  cursor: pointer;
}
</style>