<template>
  <div>
    <router-view></router-view>

    <wh8footer></wh8footer>
  </div>
</template>

<script>
import wh8footer from "./components/wh8/wh8footer.vue";
export default {
  components: {
    wh8footer,
  },
  watch: {
    $route: function () {
      // document.body.scrollTop = 0;
      // document.documentElement.scrollTop = 0;
    },
  },
};
</script>

<style>
</style>