<template>
    <div class="container">
        <div class="content">
            <div class="panel">
                <div class="inner">
                    <Logo />
                    <Form />
                </div>
            </div>
            <div class="cancel" @click="hide"></div>
        </div>
    </div>
</template>

<script>
import Logo from "./logo";
import Form from "./form";

export default {
    name: "Login",
    components: {
        Logo,
        Form
    },
    methods: {
        hide() {
            this.$parent && this.$parent.set_hide()
        }
    }
};
</script>

<style scoped>
.container {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    background: rgba(108, 118, 183, 0.5);
}

.content {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.cancel {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
}

.panel {
    width: 466px;
    height: 497px;
    position: relative;
    z-index: 9;
}

.inner {
    width: 290px;
    margin: 0 auto;
    padding: 34px 0 0;
    z-index: 1;
    position: relative;
}

.panel::before,
.panel::after {
    width: 100%;
    height: 100%;
    content: "";

    top: 0;
    left: 0;
    position: absolute;
}

.panel::before {
    background: url(../assets/panel-widget.svg) no-repeat;
}

.panel::after {
    background: url(../assets/panel.svg) no-repeat;
    filter: drop-shadow(0 10px 88px rgba(0, 0, 0, 0.2));
}
</style>