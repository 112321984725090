<template>
  <div class="learningMaterials">
    <div class="content">
      <div class="article">
        <!-- <img src="../../assets/images/xxzl.png" style="margin:0 auto;display:block;" alt=""> -->
        <p class="title">文档教程</p>
        <div class="flex">
          <div class="item">
            <img src="../../assets/images/xsrm.png" alt="" />
            <p>2024.06.18</p>
            <p>
              <a
                href="https://wt8.wenhua.com.cn/dl.asp?mod=dl&id=18"
                target="_blank"
                @click="download('18')"
                >点击下载</a
              >
            </p>
            <p>下载次数：{{ data["d18"] }}</p>
          </div>
          <!-- <div class="item">
            <img src="../../assets/images/lhjy.png" alt="" />
            <p>2024.06.18</p>
            <p>
              <a
                href="https://wt8.wenhua.com.cn/dl.asp?mod=dl&id=1"
                target="_blank"
                @click="download('1')"
                >点击下载</a
              >
            </p>
            <p>下载次数：{{ data["d1"] }}</p>
          </div> -->




          <div class="item">
            <img src="../../assets/images/twjd.png" alt="" />
            <p>2024.06.18</p>
            <p>
              <a
                href="https://wt8.wenhua.com.cn/dl.asp?mod=dl&id=19"
                target="_blank"
                @click="download('19')"
                >点击下载</a
              >
            </p>
            <p>下载次数：{{ data["d19"] }}</p>
          </div>
          <div class="item">
            <img src="../../assets/images/clyh.png" alt="" />
            <p>2024.06.18</p>
            <p>
              <a
                href="https://wt8.wenhua.com.cn/dl.asp?mod=dl&id=7"
                target="_blank"
                @click="download('7')"
                >点击下载</a
              >
            </p>
            <p>下载次数：{{ data["d7"] }}</p>
          </div>
        </div>
        <p class="title">视频教程</p>
        <div class="video_item" style="margin-left: 0">
          <div class="video video4" @click="clicktjnew(29)">
            <div>
              <img class="bof" src="../../assets/images/bof.png" alt="" />
            </div>
            <div class="videoNum">观看次数{{ data["d29"] }}次</div>
          </div>
          <div class="ppt">
          </div>
        </div>

        <!-- 
        <div class="video_item" style="margin-left: 0">
          <div class="video video1" @click="clicktjnew(22)">
            <div>
              <img class="bof" src="../../assets/images/bof.png" alt="" />
            </div>
            <div class="videoNum">观看次数{{ data["d22"] }}次</div>
          </div>
          <div class="ppt">
          </div>
        </div>
        <div class="video_item">
          <div class="video video2" @click="clicktjnew(26)">
            <div>
              <img class="bof" src="../../assets/images/bof.png" alt="" />
            </div>
            <div class="videoNum">观看次数{{ data["d26"] }}次</div>
          </div>
          <div class="ppt">
          </div>
        </div> -->
        <!-- <div class="video_item">
          <div class="video video3" @click="clicktjnew(27)">
            <div>
              <img class="bof" src="../../assets/images/bof.png" alt="" />
            </div>
            <div class="videoNum">观看次数{{ data["d27"] }}次</div>
          </div>
          <div class="ppt">

          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "learningMaterials",
  data() {
    return {
      data: {
        d18: "",
        d1: "",
        d6: "",
        d7: "",
        d19: "",
        d22: "",
        d23: "",
        d26: "",
        d27: "",
        d29: ""
      },
    };
  },
  mounted() {
    // this.download("18");
    // this.download("1");
    // this.download("6");
    // this.download("7");
    // this.download("19");
    var data = [18, 1, 6, 7, 19, 22, 23, 26, 27 ,29];
    data.forEach((el) => {
      this.download(el);
    });
  },
  methods: {
    download(str) {
      axios.get(`dl.asp?mod=cat&id=${str}`).then((res) => {
        // console.log(res);
        this.data["d" + str] = res.data;
      });
    },
    clicktjnew(lj) {
      var mask_bg = document.createElement("div");
      mask_bg.id = "mask_bg";
      mask_bg.style.position = "fixed";
      mask_bg.style.top = "0px";
      mask_bg.style.left = "0px";
      mask_bg.style.width = "100%";
      mask_bg.style.height = "100%";
      mask_bg.style.backgroundColor = "rgba(0,0,0,0.6)";
      // mask_bg.style.opacity = 0.6;
      mask_bg.style.zIndex = 1111111;
      document.body.appendChild(mask_bg);
      var alertcont = document.createElement("div");

      alertcont.style.width = "1000px";
      alertcont.style.height = "600px";
      alertcont.style.position = "fixed";
      alertcont.style.background = "black";
      alertcont.style.left = "20%";
      alertcont.style.top = "20%";
      alertcont.style.zIndex = 111111111;
      mask_bg.appendChild(alertcont);
      var alertcont2 = document.createElement("div");
      alertcont2.style.position = "relative";
      alertcont2.style.width = "100%";
      alertcont2.style.height = "100%";
      alertcont.appendChild(alertcont2);
      var bao = document.createElement("iframe");
      // switch (lj) {
      //   case 26:
      //     axios.get(`/dl.asp?mod=dl&id=${lj}`);
      //     this.download(lj);
      //     bao.src =
      //     "https://outin-7436d088141511ebb1d500163e1c7426.oss-cn-shanghai.aliyuncs.com/sv/6adfd24-183cf0a36a5/6adfd24-183cf0a36a5.mp4";
      //       // "https://outin-7436d088141511ebb1d500163e1c7426.oss-cn-shanghai.aliyuncs.com/sv/2321dd62-18316693d00/2321dd62-18316693d00.mp4";
      //     break;
      //   case 27:
      //     axios.get(`/dl.asp?mod=dl&id=${lj}`);
      //     this.download(lj);
      //     bao.src =
      //       "https://outin-7436d088141511ebb1d500163e1c7426.oss-cn-shanghai.aliyuncs.com/sv/532b6f1b-1833a819bb6/532b6f1b-1833a819bb6.mp4";
      //     break;
      //   default:
      //     bao.src = "/dl.asp?mod=dl&id=" + lj + "";
      // }
      bao.src = "/dl.asp?mod=dl&id=" + lj + "";
      bao.style.width = "100%";
      bao.style.height = "100%";
      alertcont2.appendChild(bao);
      var chabox = document.createElement("div");
      chabox.innerHTML = "x";
      chabox.style.color = "white";
      chabox.style.position = "absolute";
      chabox.style.right = "-5px";
      chabox.style.top = "0";
      chabox.style.width = "15px";
      chabox.style.height = "15px";
      chabox.style.cursor = "pointer";
      chabox.onclick = function () {
        var mask_bg = document.getElementById("mask_bg");
        if (mask_bg != null) mask_bg.parentNode.removeChild(mask_bg);
      };
      alertcont2.appendChild(chabox);
      this.download(lj);
    },
  },
};
</script>
<style  scoped>
.learningMaterials {
  /* background: url("../../assets/images/xiaz-top.png") no-repeat top; */
  background-size: 100% auto;
  /* min-width: 1400px; */
  width: 100%;
  min-height: 62vh;
  padding-top: 10px;
  position: relative;
}
.learningMaterials .content {
  width: 100%;
  margin: 0 auto;
  /* margin-top: 100px; */

  /* background: #fff; */

  /* border-radius: 10px 10px 0 0; */
  /* padding: 50px; */
  /* position: absolute; */
  top: 0;
  /* bottom: 80px; */
  left: 0;
  right: 0;
}
.learningMaterials .content .header {
  color: #1f2d54;
  border-bottom: 1px solid #ccc;
  text-indent: 1em;
  font-size: 18px;
  font-weight: 800;
  line-height: 36px;
}
.learningMaterials .content .article {
  padding: 50px 40px;

  color: #838383;
  font-size: 14px;
}
.learningMaterials .content .article p {
  line-height: 28px;
}
.learningMaterials .content .article .title {
  border-left: 5px solid #3d57a9;
  font-size: 16px;
  margin-bottom: 20px;
  padding-left: 5px;
  line-height: 20px;
  color: #333;
}
.learningMaterials .content .article .flex {
  display: flex;
  flex-direction: row;
  justify-content:flex-start;
  padding-bottom: 30px;
  /* border-bottom: 1px solid #ccc; */
  margin-bottom: 30px;
}
.learningMaterials .content .article .flex div {
  width: 160px;
  text-align: center;
  color: #848484;
  font-size: 14px;
  margin-right: 100px;
}
.learningMaterials .content .article .flex div a {
  display: block;
  width: 100px;
  margin: 0 auto;
  background-color: #3a5485;
  height: 30px;
  color: #fff;
  line-height: 30px;
  text-decoration: none;
}
.video {
  width: 194px;
  height: 131px;
  /* background: url(../../assets/images/sp-lhzd.png) no-repeat center;
  background-size: contain; */
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  cursor: pointer;
  position: relative;
}
.video1 {
  background: url(../../assets/images/sp-lhzd.jpg) no-repeat center;
  background-size: contain;
}
.video2 {
  background: url(../../assets/images/wh8jy.jpg) no-repeat center;
  background-size: contain;
}
.video3 {
  background: url(../../assets/images/t8lh.jpg) no-repeat center;
  background-size: contain;
}
.video4 {
  background: url(../../assets/images/01.png) no-repeat center;
  background-size: contain;
}
.videoNum {
  position: absolute;
  bottom: 0;
  height: 28px;
  line-height: 28px;
  width: calc(100% - 10px);
  text-align: right;
  background: rgba(0, 0, 0, 0.3);
  color: #fff;
  padding-right: 10px;
}
.ppt {
  margin-top: 10px;
}
.ppt span {
  display: inline-block;
  height: 30px;
  line-height: 30px;
  padding: 0 5px;
}
.downloadPPT {
  background: #c4954d;
  color: #fff;
}
.video_item {
  display: inline-block;
  vertical-align: top;
  margin-left: 30px;
}
.video img {
  position: absolute;
  right: 3px;
  bottom: 30px;
  width: 30px;
}
</style>