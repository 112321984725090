<template>
  <div class="downloadhtml">
    <div class="downloadall">
      <div style="height:110px;"></div>
       
      <div class="downloadbody">
       
        <div class="downloadbodyhead">
          <div
            v-bind:class="[isActive[0] ? isclicklf : noclick,isActive[2]?'geduan1':'']"
            @click="tabSelect(0)"
            style="border-radius:10px 0 0 0;"
          >
            下载实盘通用版
          </div>
          <!-- <div
            style="
              height: 33px;
              width: 1px;
              display: inline-block;
              background:rgb(195,210,255)
            "
            
          ></div> -->
          <div
            v-bind:class="[isActive[1] ? isclicklf : noclick]"
            @click="tabSelect(1)"
            
          >
            下载体验版
          </div>
          <!-- <div
            style="
              height: 33px;
              width: 1px;
              display: inline-block;
            "
          ></div> -->
          <div
             v-bind:class="[isActive[2] ? isclicklf : noclick,isActive[0]?'geduan2':'']"
            @click="tabSelect(2)"
            style="border-radius: 0 10px 0 0;"
          >
            学习资料
          </div>
        </div>

        <tongyong v-if="isActive[0]" @openModal="openModal"></tongyong>
        <shiyong v-if="isActive[1]" @openModal="openModal"></shiyong>
        <learningMaterials v-if="isActive[2]"></learningMaterials>
      </div>
      
    </div>
    <!-- <wh8footer></wh8footer> -->
    <div
      style="
        width: 100%;
        height: 100%;
        position: fixed;
        background: rgba(0, 0, 0, 0.3);
        left: 0;
        top: 0;
        z-index: 999;
      "
      v-if="modal"
      @click="modal = false;"
    ></div>
    <div class="aCon" v-if="modal">
      <div class="denote">
        预约上门讲解软件使用
        <div class="closeYY" @click="modal = false;"></div>
      </div>
      <div class="bCon">
        <p>
          预约金融工程师上门讲解软件，该服务仅针对<span>机构用户</span>，文华金融工程师可针对量化交易方案、策略回测、完善优化，算法交易等方面进行专业、系统的讲解，聆听你的建议。
        </p>
        <div class="subscribe sqzl">
          <div class="warp">
            <h2>机构预约资料填写</h2>
            <form id="form_list">
              <table class="table19">
                <tbody>
                  <tr>
                    <td class="note-td0">所在城市：</td>
                    <td>
                      <div class="group">
                        <div class="select text amp s_btn2" tag="select_city" @click="cityListShow = !cityListShow">
                          <span
                            tag=""
                            class="turnbla"
                            style="color: rgb(0, 24, 44); margin-left: 8px"
                            >{{form.city}}</span
                          >
                          <s class="teg2"></s>
                        </div>
                        <ul class="list none" tag="select_city" v-show="cityListShow">
                          <li @click="selectLi('北京')">北京</li>
                          <li @click="selectLi('上海')">上海</li>
                          <li @click="selectLi('杭州')">杭州</li>
                          <li @click="selectLi('深圳')">深圳</li>
                          <li @click="selectLi('广州')">广州</li>
                        </ul>
                      </div>
                      <input
                        type="hidden"
                        id="hidden"
                        name="city"
                        value="北京"
                      />
                    </td>
                  </tr>
                  <tr class="place"></tr>
                  <tr>
                    <td class="note-td0">机构名称：</td>
                    <td>
                      <div class="amp">
                        <input
                          type="text"
                          class="inp"
                          id="company"
                          name="company"
                          placeholder="请输入机构名称"
                          v-model="form.company"
                        />
                      </div>
                    </td>
                  </tr>
                  <tr class="place"></tr>
                  <tr>
                    <td class="note-td0">姓名：</td>
                    <td>
                      <div class="amp">
                        <input
                          type="text"
                          class="inp"
                          id="applyname"
                          name="applyname"
                          placeholder="请输入真实姓名"
                          v-model="form.applyname"
                        />
                      </div>
                    </td>
                  </tr>
                  <tr class="place"></tr>
                  <tr>
                    <td class="note-td0">联系电话：</td>
                    <td>
                      <div class="amp">
                        <input
                          type="text"
                          class="inp"
                          id="tel"
                          name="tel"
                          placeholder="请输入联系电话"
                          v-model="form.tel"
                        />
                      </div>
                    </td>
                  </tr>
                  <tr class="place"></tr>
                  <tr>
                    <td class="note-td0">详细地址：</td>
                    <td>
                      <div class="amp">
                        <input
                          type="text"
                          class="inp"
                          id="address"
                          name="address"
                          placeholder="请输入具体地址"
                          v-model="form.address"
                        />
                      </div>
                    </td>
                  </tr>
                  <tr class="place">
                    <td class="note-td0"></td>
                    <td class="zu"><div>注：以上为必填项</div></td>
                  </tr>
                  <tr align="left">
                    <td class="note-td1" colspan="2">
                      <input
                        type="button"
                        id="submit"
                        class="submit"
                        value="提交"
                        @click="submitForm"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </form>
          </div>
        </div>
      </div>
    </div>
    <wh8footer></wh8footer>
  </div>
</template>

<script>
import wh8footer from "./components/wh8/wh8footer.vue";
import axios from "axios";
// import wh8footer from "./components/wh8/wh8footer.vue";
import shiyong from "./components/download/shiyong.vue";
import tongyong from "./components/download/tongyong.vue";
import learningMaterials from "./learningMaterials.vue"
export default {
  components: {
    tongyong,
    shiyong,
    learningMaterials,
    wh8footer,
  },
  data() {
    return {
      isclickrg: "isclickrg",
      noclick: "noclick",
      isclicklf: "isclicklf",
      isActive: [true,false,false],
      cityListShow:false,
      modal:false,
      form:{
        city:'北京',
        company:'',
        applyname:'',
        tel:'',
        address:'',
        type:1
      }
    };
  },
  watch:{
    $route: {
    handler: function(val){
      //  console.log(val);
      if(val.path == '/download'){
      this.tabSelect(0)
     }
      this.isActive = [false,false,false];
      this.isActive[val.params.id] = true;
      
    },
    
    deep: true
    }
  },
  mounted(){
    //  console.log(this.$route);
     if(this.$route.path == '/download'){
      this.tabSelect(0)
     }
    this.isActive = [false,false,false];
      this.isActive[this.$route.params.id] = true;
  },
  methods: {
    tabSelect(num){
      // this.isActive = [false,false,false];
      // this.isActive[num] = true;
      this.$router.push({ path: `/download/download/${num}`});
    },
    selectLi(name){
      this.form.city = name;
      this.cityListShow = false;
    },
    openModal(){
      this.modal = true;
    },
    submitForm(){
      if(this.form.company == ''){
        alert('请填写机构名称')
        return
      }
      if(this.form.applyname == ''){
        alert('请填写真实姓名')
        return
      }
      if(this.form.tel == ''){
        alert('请填写联系电话')
        return
      }
      if(this.form.address == ''){
        alert('请填写具体地址')
        return
      }
      axios.post('https://wt9.wenhua.com.cn/api/AddApi/AddWh9Apply',this.form).then(res=>{
          // console.log(res);
          alert(res.data.message);
      })


    }
  },
};
</script>

<style scoped>
.downloadhtml {
  min-width: 1400px;
  width: 100%;
  background-size: auto 1000px;
  min-height: 775px;
  z-index: 3;
  margin: 0 auto;
  /* background: #eeeff4; */
}
.downloadall {
  background: url("../../assets/images/xiaz-top.png") no-repeat top;
  background-size: 100% auto;
  /* min-width: 1400px; */
  width: 100%;
}
.downloadhead {
  position: relative;
  height: 140px;
  width: 1000px;
  margin: 0 auto;
}
.downloadheadtext {
  position: absolute;
  top: 100px;
}
.downloadbody {
  width: 1005px;
  margin: 0 auto;
  /* margin-top: 100px; */
  background: url("../../assets/images/bg-xz.png") #e8edf5 no-repeat top;
  min-height: 100px;
  border-radius: 15px 15px 0 0;
}
.isclickrg {
  background: transparent;
  width: 335px;
  height: 52px;
  text-align: center;
  font-size: 18px;
  display: inline-block;
  line-height: 52px;
  color: #0e286e;
  font-weight: bold;
  letter-spacing: 1px;
  cursor: pointer;
  position: relative;
  
 
}
.isclicklf {
  background: transparent;
  width: 335px;
  height: 52px;
  text-align: center;
  font-size: 18px;
  display: inline-block;
  line-height: 52px;
  color: #0e286e;
  font-weight: bold;
  cursor: pointer;
  letter-spacing: 1px;
  position: relative;
}
.isclicklf::before{
  content: " ";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 7px 7px 0 7px;
  border-color: #c89d5d transparent transparent transparent;
  display: block;
  position: absolute;
  bottom: -10px;
  left: 160px;
}
.noclick {
  background-color: #e0e7f0;
  display: inline-block;
  width: 335px;
  height: 52px;
  text-align: center;
  font-size: 18px;
  display: inline-block;
  letter-spacing: 1px;
  line-height: 52px;
  color: #7782a9;
  font-weight: bold;
  cursor: pointer;
  position: relative;
}
.geduan1::before{
  content:'';
  width: 1px;
  height: 100%;
  position: absolute;
  background: url("../../assets/images/geduan.png") no-repeat;
  right: 0;
  background-position-y: 6px;

}
.geduan2::before{
  content:'';
  width: 1px;
  height: 100%;
  position: absolute;
  background: url("../../assets/images/geduan.png") no-repeat;
  left: 0;
  background-position-y: 6px;

}
.downloadbodyhead {
  background: #c89d5d;
  height: 56px;
  border-radius: 10px 10px 0 0;
}
.aCon {
    width: 700px;
    background-color: #FBFBFB;
    position: fixed;
    top: 100px;
    left: 50%;
    margin-left: -350px;
    z-index: 999;
}
.denote {
    height: 32px;
    background: #d8e5f8;
    color: #012f6d;
    font-size: 16px;
    line-height: 32px;
    text-align: center;
    font-weight: 700;
}

.closeYY::before {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
}
.closeYY::before, .closeYY::after {
    content: '';
    top: 50%;
    left: 0;
    position: absolute;
    margin-top: -1px;
    background: #000;
    width: 70%;
    height: 2px;
    display: block;
}
.closeYY::after {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
}
.closeYY {
    width: 18px;
    height: 18px;
    position: absolute;
    right: 10px;
    top: 8px;
    cursor: pointer;
    overflow: hidden;
}
.bCon {
    padding: 20px 50px 60px 50px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}
.aCon p {
    text-indent: 2em;
    font-size: 14px;
    line-height: 25px;
    margin: 0 0 20px 0;
    color: #00182c;
    text-align: justify;
}
.aCon p span {
    color: #ad2d0a;
}
.sqzl .warp {
    width: 100%;
    height: 100%;
}
.sqzl h2 {
    display: block;
    height: 36px;
    line-height: 36px;
    text-align: center;
    width: 100%;
    font-size: 16px;
    color: #00182c;
    font-weight: normal;
    position: relative;
    padding: 0 0 15px 0;
}
.sqzl .table19 {
    clear: both;
    margin: 0 auto;
}
.sqzl .note-td0 {
    text-align: right;
    color: #00182c;
    font-size: 14px;
    padding-right: 5px;
    vertical-align: middle;
}
.sqzl .group {
    display: inline-block;
    position: relative;
}
.sqzl .select {
    width: 159px;
    height: 30px;
    display: inline-block;
    text-align: left;
    cursor: pointer;
    overflow: hidden;
    border: 1px solid #ababab;
    border-radius: 3px;
}
.sqzl .select span {
    font-size: 14px;
    line-height: 30px;
    margin-left: 12px;
    color: #757575;
}
.sqzl .select s {
    display: inline-block;
    float: right;
    margin-top: 12px;
    margin-right: 2px;
    width: 20px;
    height: 10px;
    overflow: hidden;
    background: url(https://wt9.wenhua.com.cn/static/img/arrbot.png) no-repeat center bottom;
}
.sqzl .list {
    width: 159px;
    background: #fff;
    border: 1px solid #ccc;
    border-top: none;
    max-height: 202px;
    overflow-y: auto;
    overflow-x: hidden;
    position: absolute;
    top: 32px;
    left: 0;
    z-index: 1;
}
.sqzl .list li {
    height: 35px;
    text-align: center;
    line-height: 35px;
    cursor: pointer;
    font-size: 16px;
    color: #333;
}
.sqzl .table19 .place {
    height: 23px;
}
.sqzl .inp {
    width: 320px;
    height: 20px;
    padding: 5px 8px;
    border: 1px solid #ababab;
    border-radius: 3px;
    font-size: 14px;
}
.place .zu div {
    color: #A3A19B;
    white-space: nowrap;
    margin-top: 9px;
}
.sqzl .note-td1 {
    background: transparent;
}
.sqzl .note-td1 .submit {
    display: block;
    width: 104px;
    height: 34px;
    background-color: #3a5ddf;
    color: #ffffff;
    font-size: 14px;
    letter-spacing: 1em;
    border: none;
    margin: 0 auto;
    margin-top: 40px;
    cursor: pointer;
    text-align: center;
    text-indent: 1em;
}
tbody{
  vertical-align: middle;
}
</style>