<template>
  <div class="qitadiqu">
    <div class="qitadqtable1" v-if="page.page1">
      <table>
        <tr>
          <td
            style="
              width: 620px;
              background: #e2e8f4;
              text-align: center;
              color: #454d60;
              padding-left: 0px;
            "
          >
            公司名称
          </td>
        </tr>
        <tr>
          <td>
            <a href="http://www.xmhrtz.com/" class="comlink" target="_blank"
              >厦门恒瑞投资管理有限公司</a
            >
          </td>
        </tr>
        <tr>
          <td>
            <a href="http://www.mingzhecap.com/" class="comlink" target="_blank"
              >河北明哲投资管理有限公司</a
            >
          </td>
        </tr>
        <tr>
          <td>
           天津国融方舟投资管理有限公司
          </td>
        </tr>
        <tr>
          <td>江苏和棋投资管理有限公司</td>
        </tr>
        <tr>
          <td>河南伊洛投资管理有限公司</td>
        </tr>
        <tr>
          <td>张家界旺金资产管理有限公司</td>
        </tr>
        <tr>
          <td>平阳炽浪投资管理合伙企业（有限合伙）</td>
        </tr>
       
        <tr>
          <td>青岛葳尔量化投资管理有限公司</td>
        </tr>
        <tr>
          <td>舜盛资产管理集团有限公司</td>
        </tr>
        <tr>
          <td>福建善一投资管理有限公司</td>
        </tr>
         <tr>
          <td>龙岩市金钿投资管理有限公司</td>
        </tr>
      </table>
    </div>
    <div class="qitadqtable2" v-if="page.page2">
      <table>
        <tr>
          <td
            style="
              width: 620px;
              background: #e2e8f4;
              text-align: center;
              color: #454d60;
              padding-left: 0px;
            "
          >
            公司名称
          </td>
        </tr>
        <tr>
          <td>江苏济凡资产管理有限公司</td>
        </tr>
        <tr>
          <td>中资宏德资本管理有限公司</td>
        </tr>
        <tr>
          <td>西藏中睿合银投资管理有限公司</td>
        </tr>
        <tr>
          <td>广西东明鸿达资产管理有限公司</td>
        </tr>
        <tr>
          <td>江苏锦盈资本管理有限公司</td>
        </tr>
        <tr>
          <td>成都细水资产管理有限公司</td>
        </tr>
        <tr>
          <td>成都乙木资产管理有限公司</td>
        </tr>
        <tr>
          <td>共青城冬泉谷投资管理合伙企业（有限合伙）</td>
        </tr>
        <tr>
          <td>厦门红琦资产管理有限公司</td>
        </tr>
        <tr>
          <td>宁夏众鋆资产管理有限公司</td>
        </tr>
        <tr>
          <td>烟台市万川投资管理有限公司</td>
        </tr>
      </table>
    </div>
    <div class="qitadqtable3" v-if="page.page3">
      <table>
        <tr>
          <td
            style="
              width: 620px;
              background: #e2e8f4;
              text-align: center;
              color: #454d60;
              padding-left: 0px;
            "
          >
            公司名称
          </td>
        </tr>
        <tr>
          <td>苏州沙漠草资产管理有限公司</td>
        </tr>
        <tr>
          <td>青岛昊华基金管理有限公司</td>
        </tr>
        <tr>
          <td>共青城静实投资管理合伙企业（有限合伙）</td>
        </tr>
        <tr>
          <td>青岛易持资产管理有限公司</td>
        </tr>
        <tr>
          <td>成都赚呗投资管理有限公司</td>
        </tr>
        <tr>
          <td>湖北华锦祥云资产管理有限公司</td>
        </tr>
        <tr>
          <td>济南春晖资产管理有限公司</td>
        </tr>
        <tr>
          <td>江西可伟资产管理有限公司</td>
        </tr>
        <tr>
          <td>西安铂石基金管理有限公司</td>
        </tr>
        <tr>
          <td>海南魔豆基金管理有限公司</td>
        </tr>
        <tr>
          <td>成都德邻资产管理有限公司</td>
        </tr>
      </table>
    </div>
    <div class="qitadqtable4" v-if="page.page4">
      <table>
        <tr>
          <td
            style="
              width: 620px;
              background: #e2e8f4;
              text-align: center;
              color: #454d60;
              padding-left: 0px;
            "
          >
            公司名称
          </td>
        </tr>
        <tr>
          <td>青岛青石资产管理有限公司</td>
        </tr>
        <tr>
          <td>南京宏龙资产管理有限公司</td>
        </tr>
      </table>
    </div>
    <div class="successcomfoot">
      <div v-bind:class="[page.page1 ? class1 : class2]" @click="showp1">1</div>
      <div v-bind:class="[page.page2 ? class1 : class2]" @click="showp2">2</div>
      <div v-bind:class="[page.page3 ? class1 : class2]" @click="showp3">3</div>
      <div v-bind:class="[page.page4 ? class1 : class2]" @click="showp4">4</div>
      共4页，35条
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      page: {
        page1: true,
        page2: false,
        page3: false,
        page4:false,
      },
      class2: "pagebutton",
      class1: "pageclickbutton",
    };
  },
  methods: {
    showp1() {
      Object.keys(this.page).forEach((item) => {
        this.page[item] = false;
      });
      this.page.page1 = true;
    },
    showp2() {
      Object.keys(this.page).forEach((item) => {
        this.page[item] = false;
      });
      this.page.page2 = true;
    },
    showp3() {
      Object.keys(this.page).forEach((item) => {
        this.page[item] = false;
      });
      this.page.page3 = true;
    },
    showp4() {
      Object.keys(this.page).forEach((item) => {
        this.page[item] = false;
      });
      this.page.page4 = true;
    },
  },
};
</script>

<style >
a:hover {
  color: blue;
}
</style>