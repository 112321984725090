import Vue from 'vue'
import App from './App.vue'
import router from "./router"
import ElementUI from 'element-ui';
import lodash from 'lodash'
import 'element-ui/lib/theme-chalk/index.css';
import vuescroll from 'vuescroll';
import * as VueJsonp from 'vue-jsonp';
import axios from "axios";
Vue.use(vuescroll, {
  ops: {}, // 在这里设置全局默认配置
  name: 'vuescroll' // 在这里自定义组件名字，默认是vueScroll
});
Vue.use(VueJsonp)
Vue.use(ElementUI);
Vue.config.productionTip = false;
Vue.prototype.axios = axios;
Vue.prototype.axios.interceptors.response.use(data=>{
  return data;
})
Vue.prototype.axios.interceptors.request.use(config=>{
  return config;
})
const requireComponent = require.context(
  // 其组件目录的相对路径
  './components/components/components',
  // 是否查询其子目录
  true,
  // 匹配基础组件文件名的正则表达式
  /(\w+\.vue$)|(index.js$)/
)
requireComponent.keys().forEach(fileName => {
  // 获取组件配置
  const componentConfig = requireComponent(fileName)

  let componentName = null

  let arr = fileName.split('/').slice(1).map(item => lodash.upperFirst(item.replace(/(\w).vue$/, '$1')))
  // 检测该文件与父文件夹是否同名
  if (arr.length > 1 && arr[arr.length - 1] == arr[arr.length - 2]) {
      // 组合文件名
      componentName = arr.slice(0, -1).reduce((accu, current) => `${accu}${current}`)
  } else if (arr.length == 2 && arr[1] == 'Index.js') {
      // 直接以index.js的父文件夹名命名组件
      componentName = arr[0]
  } else if (arr.length == 1) {
      // 直接命名
      componentName = lodash.upperFirst(
          lodash.camelCase(
              // 剥去文件名开头的 `./` 和结尾的扩展名
              fileName.replace(/^\.\/(.*)\.\w+$/, '$1')
          )
      )
  }

  if (!componentName) return;
console.log(componentName)
  // 全局注册组件
  Vue.component(
      componentName,
      // 如果这个组件选项是通过 `export default` 导出的，
      // 那么就会优先使用 `.default`，
      // 否则回退到使用模块的根。
      componentConfig.default || componentConfig
  )
})
new Vue({
  router,
  
  render: h => h(App),
}).$mount('#app')
