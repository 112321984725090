<template>
  <div class="productcenterhtml">
    
    <div class="productcenterbody">
      <div class="productcenterbodytag">
        <ul style="padding-top: 70px">
          <span class="title">多账号下单</span>
          <li class="pli">
            <div v-bind:class="[form[0] ? class4 : class3]"></div>
            <div
              v-bind:class="[form[0] ? class1 : class5]"
              style="line-height: 20px"
              @click="clickLi(0)"
            >
              <div style="width: 26px; height: 40px; float: left"></div>
              <div class="tagmid">简介</div>
              <div style="width: 30px; height: 40px; float: left"></div>
            </div>
            <div
              style="width: 9px; height: 7px; float: left; ine-height: 7px"
            ></div>
          </li>
          <!-- <li class="pli">
            <div v-bind:class="[form[1] ? class4 : class3]"></div>
            <div v-bind:class="[form[1] ? class1 : class2]" @click="clickLi(1)">
              <div style="width: 26px;  height: 40px; float: left"></div>
              <div class="tagmid">支持跨平台交易</div>
              <div style="width: 30px; height: 40px; float: left"></div>
            </div>
            
          </li>
          <li class="pli">
            <div v-bind:class="[form[2] ? class4 : class3]"></div>
            <div v-bind:class="[form[2] ? class1 : class2]" @click="clickLi(2)">
              <div style="width: 26px;  height: 40px; float: left"></div>
              <div class="tagmid">多账号的概念</div>
              <div style="width: 30px; height: 40px; float: left"></div>
            </div>
            
          </li> -->
          <li class="pli">
            <div v-bind:class="[form[3] ? class4 : class3]"></div>
            <div v-bind:class="[form[3] ? class1 : class2]" @click="clickLi(3)">
              <div style="width: 26px;  height: 40px; float: left"></div>
              <div class="tagmid">多账号登录</div>
              <div style="width: 30px; height: 40px; float: left"></div>
            </div>
            
          </li>
          <li class="pli">
            <div v-bind:class="[form[4]? class4 : class3]"></div>
            <div v-bind:class="[form[4] ? class1 : class2]" @click="clickLi(4)">
              <div style="width: 26px; height: 40px; float: left"></div>
              <div class="tagmid">查看各账号持仓</div>
              <div style="width: 30px; height: 40px; float: left"></div>
            </div>
            
          </li>
          <li class="pli">
            <div v-bind:class="[form[5]? class4 : class3]"></div>
            <div v-bind:class="[form[5]? class1 : class2]" @click="clickLi(5)">
              <div style="width: 26px; height: 40px; float: left"></div>
              <div class="tagmid">设置不同下单倍数</div>
              <div style="width: 30px; height: 40px; float: left"></div>
            </div>
            
          </li>
          <li class="pli">
            <div v-bind:class="[form[6] ? class4 : class3]"></div>
            <div v-bind:class="[form[6] ? class1 : class2]" @click="clickLi(6)">
              <div style="width: 26px; height: 40px; float: left"></div>
              <div class="tagmid">多账号交易</div>
              <div style="width: 30px; height: 40px; float: left"></div>
            </div>
            
          </li>
          <li class="pli">
            <div v-bind:class="[form[7]? class4 : class3]"></div>
            <div v-bind:class="[form[7]? class1 : class2]" @click="clickLi(7)">
              <div style="width: 26px; height: 40px; float: left"></div>
              <div class="tagmid">批量撤单、改价</div>
              <div style="width: 30px; height: 40px; float: left"></div>
            </div>
            
          </li>
          <li class="pli">
            <div v-bind:class="[form[8] ? class4 : class3]"></div>
            <div v-bind:class="[form[8] ? class1 : class2]" @click="clickLi(8)">
              <div style="width: 26px; height: 40px; float: left"></div>
              <div class="tagmid">多账号云条件单/预备单</div>
              <div style="width: 30px; height: 40px; float: left"></div>
            </div>
            
          </li>
          <li class="pli">
            <div v-bind:class="[form[9] ? class4 : class3]"></div>
            <div v-bind:class="[form[9] ? class1 : class2]" @click="clickLi(9)">
              <div style="width: 26px; height: 40px; float: left"></div>
              <div class="tagmid">多账号云止损单/止盈单</div>
              <div style="width: 30px; height: 40px; float: left"></div>
            </div>
            
          </li>
          <li class="pli">
            <div v-bind:class="[form[10] ? class4 : class3]"></div>
            <div v-bind:class="[form[10] ? class1 : class2]" @click="clickLi(10)">
              <div style="width: 26px; height: 40px; float: left"></div>
              <div class="tagmid">多账号下单参数设置</div>
              <div style="width: 30px; height: 40px; float: left"></div>
            </div>
            
          </li>
         
          <li class="pli">
            <div v-bind:class="[form[11] ? class4 : class3]"></div>
            <div v-bind:class="[form[11]  ? class1 : class2]" @click="clickLi(11)">
              <div style="width: 26px; height: 40px; float: left"></div>
              <div class="tagmid">多账号量化下单</div>
              <div style="width: 30px; height: 40px; float: left"></div>
            </div>
            
          </li>
          <li class="pli">
            <div v-bind:class="[form[12]  ? class4 : class3]"></div>
            <div v-bind:class="[form[12]  ? class1 : class2]" @click="clickLi(12)">
              <div style="width: 26px; height: 40px; float: left"></div>
              <div class="tagmid">常见问题解答</div>
              <div style="width: 30px; height: 40px; float: left"></div>
            </div>
            
          </li>
          
          
        </ul>
      </div>
      <div class="productcenterbodytext" ref="box">
        <div
          style="
            width: 780px;
           
            border-radius: 2%;
            position: relative;
          "
        >
          <div class="midtext">
            <div v-if="form[0]">
            <p class="pproduct" id="productcenter0">
            支持多账号量化下单、多账号条件单、多账号止损单。支持跨期货公司的不同账号，支持跨多种后台系统的不同账号。
            </p>
            <div class="head4"  style="width:100px">
              <span>一</span>&nbsp;简介
            </div>
            <p class="pproduct">
              “多账号”批量下单是用操作一个账号的过程，达到操作多个账号进行下单交易的目的。
            </p>
            <p class="pproduct">
              多账号支持跨不同期货公司、跨不同交易后台（CTP、金仕达、恒生多种后台）登陆，支持最多配置10个交易账号进行"多账号"下单交易，<router-link to="/trade" target="_blank">点击查看WT8支持交易的期货公司</router-link>。
            </p>
            <p>
              注：多账号支持内外盘期货交易和股票期权交易，要在账户区“期货户”、“外盘户”和“股票期权账户”处分别登录。
            </p>
            </div>
             <!-- <div v-if="form[1]">
            <div class="head4" id="productcenter1" style="width:250px">
              <span>二</span>&nbsp;支持跨交易平台的多个账号
            </div>
            <p class="pproduct">
             支持跨CTP、金仕达、恒生多种后台，多个账号同时登陆交易。
            </p>
             </div>
             <div v-if="form[2]">
            <div class="head4" id="productcenter2" style="width:235px">
              <span>三</span>&nbsp;什么是多账号下单
            </div>
            <p class="pproduct">
              “多账号”批量下单是用操作一个账号的过程，达到操作多个账号进行下单交易的目的。
            </p>
             </div> -->
             <div v-if="form[3]">
            <div class="head4" id="productcenter3" style="width:140px">
              <span>二</span>&nbsp;多账号登录
            </div>
            <p class="pproduct">
              “多账号”下单需要同时登陆多个账号，点击软件右下方的" 期货户"按钮打开登陆界面，在登陆界面登陆多个账号。
            </p>
            <p class="pproduct">
              如下图所示，是如何登录多个账号：
            </p>
            <p class="pimg"><img src="../../assets/images/dzh-1.gif"/></p>
             </div>
            <div v-if="form[4]" style="margin-bottom:20px;">

            <div class="head4" id="productcenter4"  style="width:170px">
              <span>三</span>&nbsp;查看各账号持仓
            </div>
            <p class="pproduct">
            在左侧账号树中勾选哪个账号，右侧持仓栏就显示哪个账号的持仓，如下图所示：
            </p>
            <p class="pimg"><img src="../../assets/images/dzh-2.jpg" /></p>
            <p>
              可将多个账号合并为账号“组”，对组内账号同时下单，如下图，是如何设置账号“组”。
            </p>
            <p class="pimg"><img src="../../assets/images/dzh-3.jpg" /></p>
            <p>
              通过右键菜单“调入账号”和“调入组”，可切换账号列表和组界面查看持仓情况。
            </p>
            <p class="pimg"><img src="../../assets/images/dzh-4.jpg" /></p>
            </div>
            <div v-if="form[5]">
            <div class="head4" id="productcenter5" style="width:185px">
              <span>四</span>&nbsp;设置不同下单倍数
            </div>
            <p class="pproduct">
            “多账号”下单中各个账号的资金量可能是不同的，下单的手数也是不同的。软件中可以对每个单账号设置不同的下单倍数。
            </p>
            <p class="pproduct">
           如下图，是对单账号设置下单倍数的方法，选中需要设置下单倍数的账号，在右键菜单“设置账号参数”中进行调整。
            </p>
            <p class="pimg"><img src="../../assets/images/dzh-5.jpg" /></p>
            <p class="pproduct">
           如下图，是对账号组中各个账号设置下单倍数的操作方法，倍数支持设置为小数。
            </p>
            <p class="pimg"><img src="../../assets/images/dzh-6.jpg" /></p>
            </div>
            <div v-if="form[6]" style="margin-bottom:30px;">
            <div class="head4" id="productcenter6" style="width:140px">
              <span>五</span>&nbsp;多账号交易
            </div>
            <p class="pproduct">
            在下单界面选择要交易的账号或设置好的“组”，就是对所选账号或组内账号下单，组内的单账号的下单手数为“下单界面手数×单账号的下单倍数”。
            </p>
            <p class="pproduct">
            如下图，在独立大窗口界面勾选参与交易的账号或组，点击即可快速下单。
            </p>
            <p class="pimg"><img src="../../assets/images/dzh-7.jpg" /></p>
            <p>①点击【价格】，会弹出排队价、对手价、市价、最新价、超价委托方式，选中其中一个，再点击下单按钮就会以选中方式发委托。</p>
            <p>“排队价”时，买入以买价发委托，卖出以卖价发委托。</p>
            <p>“对手价”时，买入以卖价发委托，卖出以买价发委托。</p>
            <p>“市价”时，买入以涨停价发委托，卖出以跌停价发委托。（交易所撮合最优价成交，因此和市价下单效果是一样的）</p>
            <p>“最新价”时，买入/卖出都以最新价发委托。</p>
            <p>“超价”时，买入以“基准价”+N个变动价位发委托，卖出以“基准价”-N个变动价位发委托。“基准价”和N可以在参数设置->【超价参数】中设置。</p>
            <p>②在持仓列表双击鼠标左键会对选中合约以对手价发平仓委托；或者在持仓列表单击鼠标右键，在弹出窗口中可以对持仓进行平仓操作。</p>
            </div>
            <div v-if="form[7]">
            <div class="head4" id="productcenter7" style="width:170px">
              <span>六</span>&nbsp;批量撤单、改价
            </div>
            <p class="pproduct">
              调入要操作的组，对组进行的委托如果未成交，在委托列表单击鼠标右键->撤单/改价，可以对该组的未成交委托完成批量撤单或批量改价，不需要针对组中的合约进行一个一个撤单或改价的重复操作。
            </p>
            <p class="pimg"><img src="../../assets/images/dzh-9.jpg" /></p>
            </div>
            <div v-if="form[8]" style="margin-bottom:30px;">
            <div class="head4" id="productcenter8" style="width:230px">
              <span>七</span>&nbsp;多账号云条件单/预备单
            </div>
            <p>
              <strong>
              1、选择账号设置云端条件单、预备单
              </strong>
            </p>
            <p class="pproduct">
              如下图，是对多个账号同时设置条件单的操作方法。在【条件单】列表点击右键，可对已设置的云端条件单进行修改。
            </p>
            <p class="pimg"><img src="../../assets/images/dzh-10.jpg"  /></p>
            <p class="pproduct">
              如下图，是对多个账号同时设置预备单的操作方法。在【预备单】列表中可以添加、发出或删除预备单。
            </p>
            <p class="pimg"><img src="../../assets/images/dzh-11.jpg" /></p>
            <p>
              <strong>
              2、对账号“组”设置云端条件单、预备单
              </strong>
            </p>
            <p class="pproduct">
              多账号“组”同样支持云端条件单，组条件单在云端储存，本机断电、断网、关机都不受影响。
            </p>
            <p class="pproduct">
              如下图，是对账户组设置云端条件单的操作方法。可在【条件单】列表点击右键，对已设置的条件单进行修改。
            </p>
            <p class="pimg"><img src="../../assets/images/dzh-12.jpg" /></p>
            <p class="pproduct">
              如下图，是对账户组设置预备单的操作方法。可在【预备单】列表点击右键，添加、发出或删除预备单。
            </p>
            <p class="pimg"><img src="../../assets/images/dzh-13.jpg" /></p>
            <p>注：</p>
            <p>1、预备单不能自动委托，需要手动在“预备单”列表右键-》立即发出，才会发送委托。</p>
            <p>2、开盘触发条件单需要在非交易时间设置，在开盘时间会触发。商品期货的09:00、10:30、13:30，股指期货的09:30、13:00，夜盘的21:00的时间都属于开盘时间。</p>
            <p>3、设置时间和价格条件单中，会有“永久有效”和“当前交易日有效”的选项。</p>
            <p class="pproduct" style="padding-top:4px">永久有效：条件单设置后在云端运行永久有效。</p>
            <p class="pproduct" style="padding-top:4px">永久有效：条件单设置后在云端运行永久有效。</p>
            <p>4、时间条件单根据条件单服务器时间触发（条件单服务器时间和交易所后台时间会进行校时）。</p>
            <p>5、条件单中的“条件”价格是判断是否发出委托的依据，委托时会按照“订单”里的委托形式下单，如设置了对手价，那么会以市场上当时的对手价发出委托；成交价是由交易所撮合成交的结果，所以成交价可能和条件价格不同。</p>
            </div>
            <div v-if="form[9]" style="margin-bottom:30px;">
            <div class="head4" id="productcenter9" style="width:230px">
              <span>八</span>&nbsp;多账号云止损单/止盈单
            </div>
            <p>
              <strong>
              1、软件中提供的止损止盈策略
              </strong>
            </p>
            <p>
              <strong>
              ①、限价止损、限价止盈原理：
               </strong>
            </p>
            <p class="pproduct">
              传统止损止盈方式，以设置的固定价差做止损止盈。
            </p>
             <p>
                <strong>
              ②、跟踪止损原理：
               </strong>
            </p>
            <p class="pproduct">
              这是一种动态止损方法，止损价位会随着盈利的增加而变化；这种方法可以最大程度实现“让盈利奔跑”。做多开仓，设置跟踪止损后的最高价每上涨一个价位，止损平仓价就跟着上涨一个价位，当价格从最高价回撤了设置的止损价差时，触发止损。下图为做多跟踪止损示意图，做空则相反。
            </p>
            <p>
              最高价：此最高价是从设置止损后开始记录的，不一定是开仓后的最高价。
            </p>
            <p class="pimg"><img src="../../assets/images/dzh-14.gif"/></p>
            <p> <strong>③、保本策略原理： </strong></p>
            <p class="pproduct">做多开仓后，在“开仓均价+设置的保本价差”位置产生了一条保本线，最新价超过设置的保本止损线后，再回落到这个保本止损线时才触发止损。这是一种现代人的止损思想——盈利状态下止损；目的是保住赚到的利润，文华软件中通常称之为“保本”。下图为做多保本止损示意图，做空则相反。</p>
            <p class="pimg"><img src="../../assets/images/dzh-15.gif"/></p>
            <p> <strong>2、设置开仓自动止损止盈 </strong></p>
            <p class="pproduct">
              开仓自动止损止盈是指点下单界面的下单按钮后开的仓，持仓自动带止损止盈设置。 如下图所示是如何设置开仓自动止损止盈：
            </p>
            <p class="pproduct">
              点击下单按钮开仓后，持仓会按照“默认策略”和“国内合约默认止损点差参数”加载止损止盈。按下图所示步骤开仓成交后，持仓单会自动带止损止盈设置。
            </p>
            <p class="pimg"><img src="../../assets/images/dzh-17.jpg" /></p>
            <p> <strong>3、对持仓合约设置止损止盈 </strong></p>
            <p class="pproduct">
             对账户已经持有的合约，可在持仓列表的右键菜单中添加止损/止盈单
            </p>
            <p>
              <strong>
              ①选择账号设置云端止损单/止盈单
               </strong>
            </p>
            <p class="pproduct">
              如下图，是对多个账号同时设置损盈单的操作方法。在持仓列表点击右键，可对已选账号中的持仓添加止损/止盈/保本单。
            </p>
            <p class="pimg"><img src="../../assets/images/dzh-18.jpg" /></p>
            <p>注：</p>
            <p>对账号设置的云端止损/止盈单，默认手数是已选账户内该合约的全部持仓；</p>
            <p>
              <strong>
              ②对账号“组”设置云端止损单/止盈单
               </strong>
            </p>
            <p class="pproduct">
              账号“组”也支持云端止损/止盈单。如下图，在持仓列表点击右键，可对组中的持仓添加止损/止盈/保本单。
            </p>
            <p class="pimg"><img src="../../assets/images/dzh-19.jpg" /></p>
            <p>注：</p>
            <p>对组设置的云端止损/止盈单，默认手数是交易组所有账户中该合约的全部持仓。</p>
            <p> <strong>4、云端止损止盈单的删除、修改、暂停、启动 </strong></p>
             <p>
              <strong>
              ① 在持仓列表修改
               </strong>
            </p>
            <p class="pproduct">如下图所示，在持仓列表右键选择“设置云止损/止盈/保本单”，可在弹出的损盈信息列表上进行调整。</p>
            <p class="pimg"><img src="../../assets/images/dzh-20.jpg" /></p>
            <p>
              <strong>
              ② 在止损单列表修改
               </strong>
            </p>
            <p class="pproduct">如下图所示，在云端损盈单列表点击鼠标右键，可以删除、修改、暂停、启动云端止损止盈单。</p>
            <p class="pimg"><img src="../../assets/images/dzh-21.jpg" /></p>
            <p class="pproduct">在止损单列表的右键菜单修改单账号/组止损单时，需要点开止损单的下拉菜单才能查看到全部的止损单并进行调整，如下图所示。</p>
            <p class="pimg"><img src="../../assets/images/dzh-22.jpg" /></p>
            <p>注：</p>
            <p>1、如果暂停再重新启用跟踪止损，会按照启用后出现的最高（低）价减(加)回撤价差重新计算止损价。</p>
            <p>2、同一个合约上设置的多个云端止损/止盈单，哪一个先触发条件，就先执行哪一条止损。</p>
            <p> <strong>5、止损止盈单的委托价格</strong></p>
            <p class="pproduct">如下图所示是如何设置止损止盈委托价格。</p>
            <p class="pimg"><img src="../../assets/images/dzh-23.jpg" /></p>
            </div>
            <div v-if="form[10]" style="margin-bottom:30px;">
            <div class="head4" id="productcenter10" style="width:220px">
              <span >九</span>&nbsp;多账号下单参数设置
            </div>
            <p class="pproduct">在下单界面【参数设置】中可以对“多账号”下单的超价参数、追价参数、止损参数等进行设置，如下图所示</p>
            <p class="pimg"><img  src="../../assets/images/dzh-27.jpg" /></p>
            <p> <strong>1、选项设置： </strong></p>
            <p class="pimg"><img src="../../assets/images/dzh-33.gif" /></p>
            <p>①、<strong>交易声音提示：</strong>勾选该项软件会在委托发出，委托成交时给出声音提示。</p>
            <p>②、<strong>双击持仓操作确认：</strong>勾选该项，当双击持仓列表时，相应操作会弹出确认提示。</p>
            <p>③、<strong>持仓列表多空分列：</strong>勾选该项，持仓列表会自动按照多单持仓和空单持仓分别排列显示仓位。</p>
            <p>④、<strong>点击持仓切换主图合约：</strong>勾选该项，点击持仓列表时主图合约跟随切换。</p>
            <p>⑤、<strong>点击最大可开仓手数填单：</strong>勾选该项，点击下单界面的最大可开仓手数，系统会自动按照该手数填写委托单下单手数。</p>
            <p>⑥、<strong>单账号点持仓列表填单手数：</strong>可以通过点持仓列表快速填入手数，支持“该合约全部可用持仓”和“该合约默认手数”两种形式。</p>
            <p>⑦、<strong>下单默认指定价下单：</strong>勾选该项，在下单时，软件默认以固定的价格下单。</p>
            <p>⑧、<strong>双击挂单操作确认：</strong>勾选该项，当双击挂单列表时，相应操作会弹出确认提示。</p>
            <p>⑨、<strong>百分比开仓：</strong>勾选该项，可按照可用资金的百分比开仓。</p>
            <p>⑩、<strong>单账号点持仓列表填单手数：</strong>可以通过点持仓列表快速填入手数，支持“该合约全部可用持仓”和“该合约默认手数”两种形式。</p>
            <p>⑪、<strong>反手默认下单方式：</strong>是指在持仓列表单击鼠标右键->反手或者点击【反手】按钮的委托方式，反手会平仓，平仓成功后再反向开仓。</p>
            <p>⑫、<strong>比例平仓下单方式：</strong>是指通过点击下单界面的比例平仓按钮时的下单方式。</p>
            <p>⑬、<strong>账户清仓下单方式：</strong>是指在持仓列表单击鼠标右键->撤单平仓+账户清仓的委托方式。撤单平仓+账户清仓会先撤掉已有挂单，然后平掉当前所有持仓。</p>
            <p>⑭、<strong>委托确认：</strong>启用后点击【下单】按钮会弹出下单确认框，点击框中的“确认”按钮才下单。</p>
            <p><strong>2、默认手数：</strong></p>
            <p>如下图是如何设置默认下单手数</p>
            <p class="pimg"><img src="../../assets/images/dzh-28.gif" /></p>
            <p><strong>3、超价参数：</strong></p>
            <p class="pproduct">可以为每个合约设置“买超价”“卖超价”参数。在下单界面使用超价下单买入时以“基准价”+N个变动价位发委托，卖出时以“基准价”-N个变动价位发委托。“基准价”和N可以在交易界面左侧菜单【超价参数】中设置。如股指的买超价参数设置为2，在下单界面用超价发买委托时，会以市场的卖价+2*0.2.的价格委托。</p>
            <p>
              如下图所示，是如何设置超价参数：
            </p>
            <p class="pimg"><img src="../../assets/images/dzh-29.gif" /></p>
            <p><strong>4、追价参数：</strong></p>
            <p>设置追价触发时间以及追价的委托方式。如下图是如何设置追价参数</p>
            <p class="pimg"><img src="../../assets/images/dzh-30.jpg" /></p>
            <p><strong>5、止损参数：</strong></p>
            <p class="pimg"><img src="../../assets/images/dzh-31.gif" /></p>
            <p>
                ①、<strong>时效：</strong>
            </p>
            <p class="pproduct">永久有效：止损止盈单设置后在云端运行永久有效</p>
            <p>
              ②、<strong>开仓自动止损的基准价：</strong>
            </p>
            <p class="pproduct">
              计算止损价格离不开基准价，比如设置开仓后亏损10个点止损，我们要有一个基准价，用它来判断现在亏损了几个点。软件中有两种形式的基准价，分别是第一批成交价和委托发出时对手价。
            </p>
            <p>
              ③、<strong>默认策略：</strong>
            </p>
            <p class="pproduct">
              这里设置的是开仓自动止损止盈的策略形式，软件提供多种策略组合，一个合约可以同时启用多个止损策略。
            </p>
            <p>
              ④、<strong>国内合约默认止损点差参数：</strong>
            </p>
            <p class="pproduct">
              止损参数是在计算止损止盈价位时用到的，可以设置跳点（几个最小变动价位），也可以设置价差，价差不同于跳点是具体价格，以股指IF为例：
            </p>
            <p class="pimg"><img src="../../assets/images/dzh-32.png" /></p>
            <p>
              限价止损：开仓后亏损2个点止损，3000开多，2998止损平仓。
            </p>
            <p>
              限价止盈：开仓后盈利1.6个点止盈，3000开多，3001.6止盈平仓。
            </p>
            <p>
              跟踪止损：最高价回撤2个点止损，3000开多，开仓后的最高价达到3010，在3008平仓。
            </p>
            <p>
              保本策略：盈利超过1.6个点后，再次回到盈利1.6个点的价位时止损，3000开多，价格上涨超过3001.6后，再次回落到3001.6，保本平仓。
            </p>
            <p style="font-size:12px">
              注：当价差设置为0，相当于不启动止损或止盈
            </p>
            </div>
            <div v-if="form[11]" style="margin-bottom:30px;">
            <div class="head4" id="productcenter11" style="width:185px">
              <span >十</span>&nbsp;多账号量化下单
            </div>
            <p class="pproduct">运行模组支持管理多个交易账号同步下单，一个运行单元可以绑定不同的交易账号，支持根据账号资金情况对账号设置不同的下单倍数，模组单元出信号委托时会按照账号的下单倍数乘以信号手数计算下单数量进行交易。</p>
            <BaseBigimg :src="require('../../assets/images/dzh-38.jpg')"></BaseBigimg>
            <p>
              <strong>运行单元绑定多账号的方法：</strong>
            </p>
            <p class="pproduct">
              第一种：登录多账户->在模组界面上新建运行单元时选择要交易的账号并设置账号倍数，如下图所示。
            </p>
            <BaseBigimg :src="require('../../assets/images/dzh-36.jpg')"></BaseBigimg>
            <p class="pproduct">
              第二种：创建副本->绑定账户，如下图所示。
            </p>
            <BaseBigimg :src="require('../../assets/images/dzh-37.jpg')"></BaseBigimg>
            <p class="pproduct">
              注：</p>
            <p class="pproduct">
              使用运行模组多账号下单需购买<a href="https://pay.wenhua.com.cn/#/detail/1" target="_blank">运行模组</a>授权并选配多账号下单模块；</p>
            <p class="pproduct">
              单/多账户建立的运行单元，多/单账户状态下无法使用；
            </p>
            </div>
            <div v-if="form[12]">
            <div class="head4" id="productcenter12" style="width:170px">
              <span style="width:56px;">十一</span>&nbsp;常见问题解答
            </div>
            <p>
              <strong>1、如果账号的“账号倍数”与“下单界面手数”的乘积不是整数倍，下单手数为多少？</strong>
            </p>
            <p>
              答：乘积按照向下取整确定下单手数，如账号倍数是1.5，下单界面的下单手数是3，那么单账号的下单手数是4手（1.5*3=4.5，向下取整）。
            </p>
            <p>
              <strong>2、运行单元绑定多账号交易时，如果某个账号手数不足，其他平仓手数充足的账号能否平仓成功？</strong>
            </p>
            <p>
              答：可以平仓成功，平仓手数不足的账号会平掉现有持仓。
            </p>
            <p>
              <strong>3、如何查询和确认账单？</strong>
            </p>
            <p>
              答：在软件上方菜单的【账户】->【期货账户】->账单查询及密码修改。
            </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: [
        true,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
      ],
      class1: "onclick",
      class2: "noclick",
      class3: "touming",
      class4: "yellow",
      class5: "noclickone",
      class6: "noclickele",
      scroll: "",
    };
  },
  methods: {
    custormAnchor(anchorName) {
      var target = document.getElementById(anchorName);   
      if(target !== null){
         this.$refs.box.scrollTop = target.offsetTop;
      }
     
        this.zero();  
        this.form[anchorName.replace(/[^0-9]/g,"")] = true;
        this.$forceUpdate();
        this.$router.push({ path: `/proc/productcenter1/${anchorName}`});
    },
    clickLi(num) {
      // this.form.forEach((item,index) => {
      //   this.form[index] = false;
      // });
      // this.form[num] = true;
      // this.$forceUpdate();
      var id = "productcenter"+num;
      this.$router.push({ path: `/proc/productcenter1/${id}` });
      this.custormAnchor(id);
    },
    
    zero() {
      this.form.forEach((item,index)=>{
        
        this.form[index] = false;
      })
    },

  },

  mounted() {
    setTimeout(()=>{this.custormAnchor(this.$route.params.id);},1000)
    
  },
};
</script>

<style scoped>
p{
  font-size: 15px;
    padding-top: 10px;
    /* padding-bottom: 10px; */
    color: #727983;
    line-height: 25px;
    /* width: 640px; */
    letter-spacing: 1px;
}
.productcenterhtml {
  background: url("../../assets/images/cpzx-top.png") no-repeat top;
  background-size: 100% ;
  min-width: 1400px;
  width: 100%;
  /* min-height: 839px; */
  padding-top: 110px;
  /* background-position-y: 23px */
}
.productcenterhead {
  position: relative;
  height: 140px;
  width: 1000px;
  margin: 0 auto;
}
.productcenterheadtext {
  position: absolute;
  top: 105px;
  left: 3px;
}
.productcenterbody {
  width: 1020px;
  margin: 0 auto;
  /* height: 695px; */
  position: relative;
}
.productcenterbodytag {
  width: 280px;
  height: 750px;
  border-radius: 2%;
  background: url("../../assets/images/duozh.png") no-repeat center;
  background-size: 100% 100%;
  display: inline-block;
}
.productcenterbodytext {
  /* position: absolute;
  top: 30px;
  left: 210px; */
  width: 780px;
  min-height: 781px;
  border-top-left-radius: 1em;
  /* background: url("../../assets/images/ts-1_02.jpg") no-repeat center;
  background-size: 100% 100%; */
  background: #ebf2fc;
  /* overflow-y: auto;
  overflow-x: hidden; */
  filter: drop-shadow(1px 1px 2px #c2c5d4);
  box-shadow: -2px 0px rgb(65 97 153 / 10%);
  padding-top: 25px;
  display: inline-block;
  margin-left: -40px;
  margin-top: 30px;
  vertical-align: top;
}
.noclick {
  height: 41px;
  line-height: 41px;

  width: 235px;
  /* border-top: 1px solid #2f5476; */
  letter-spacing: 1px;
  color: #323f62;
  text-align: left;
  float: left;
  font-size: 14px;
  font-weight: normal;
  cursor: pointer;
}
.onclick {
  height: 42px;
  line-height: 42px;
  background: #ebf2fc;

  width: 235px;

  color: #323f62;
  text-align: left;
  letter-spacing: 1px;

  float: left;
  font-size: 14px;
  font-weight: normal;
  cursor: pointer;
}
.touming {
  width: 5px;
  height: 42px;
  float: left;
}
.yellow {
  width: 5px;
  height: 42px;
  background: #c49c71;
  float: left;
  border-top-left-radius: 0.1em;
  border-bottom-left-radius: 0.1em;
}
.head4 {
  font-size: 15px;
  /* padding-top: 20px; */
  /* padding-bottom: 10px; */
  /* font-weight: bold; */
  letter-spacing: 1px;
  height: 26px;
  line-height: 26px;
   background-color: #dde4f7;
  color: #182640;
  margin-top: 20px;
  
  
}
.head4 span{
  color: #fff;
  /* padding-left: 5px; */
  display: inline-block;
  height: 26px;
  line-height: 26px;
  width: 45px;
  background: url("../../assets/images/cp.png") no-repeat left;
   background-size: 100%;
  /* text-indent: 1em; */
  text-align: center;
}
.head4 .span2{
  width: 80px;
  background: url("../../assets/images/cp2.png") no-repeat left;
}
.midtext {
  /* height: 680px; */
  width: 690px;
  margin: 0 auto;
  /* padding-bottom: 100px; */
}
.pproduct {
  font-size: 15px;
  padding-top: 10px;
  padding-bottom: 0;
  text-indent: 2em;
  color: #727983;
  line-height: 25px;
  /* width: 640px; */
  letter-spacing: 1px;
}
.pimg {
  padding-top: 10px;
  padding-bottom: 10px;
  min-height: 50px;
  width: 100%;
  text-align: center;
}
.noclick:hover {
  color: #e3a200;
}
.pli {
  width: 250px;
  height: 42px;
  /* margin-left: -5px; */
  position: relative;
  z-index: 88;
}
.noclickone {
  height: 41px;
  line-height: 41px;

  letter-spacing: 1px;
  width: 235px;

  /* border-top: 1px solid #2f5476; */
  color: #323f62;
  text-align: left;
  float: left;
  font-size: 14px;
  font-weight: normal;
  cursor: pointer;
}
.noclickele {
  height: 40px;
  line-height: 40px;

  letter-spacing: 1px;
  width: 235px;
  /* border-top: 1px solid #2f5476;
  border-bottom: 1px solid #2f5476; */
  color: #85c0ea;
  text-align: left;
  float: left;
  font-size: 14px;
  font-weight: normal;
  cursor: pointer;
}
.noclickone:hover {
  color: #323f62;
}
.noclickele:hover {
  color: #323f62;
}
.tagmid {
  width: 208px;
  height: 42px;
  float: left;
  font-weight: bold;
  line-height: 42px;
  border-bottom: 1px dashed #ccc;
}
.productcenterbodytag .title{
    display: block;
    height: 60px;

    color: #fff;
    font-weight: bold;
    text-indent: 2em;
    font-size: 16px;
}
strong{
  font-weight: 700;
}
</style>