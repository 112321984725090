<template>
  <div>
    <router-view></router-view>

    <wh8footer></wh8footer>
  </div>
</template>

<script>
import wh8footer from "./components/wh8/wh8footer.vue";
export default {
  components: {
    wh8footer,
  },
  watch: {
    $route: function (val) {
      // console.log(val);
      if(val.path == '/proc'){
        this.$router.push({ path: `/proc/productcenter/productcenter1`});
      }
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
  },
  mounted(){
    // console.log('route',this.$route);
    if(this.$route.name == 'shengjishuoming'){
      this.$router.push({ path: `/proc/shengjishuoming`});
    } 
    if(this.$route.name == 'productcenter1'){
      this.$router.push({ path: `/proc/productcenter/${this.$route.params.id}`});
    }
    if(this.$route.name == 'proc'){
      this.$router.push({ path: `/proc/productcenter/productcenter1`});
    }
  }
};
</script>

<style>
.flexbox{
  position: absolute;
    top: 73px;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    flex-direction: column;
}
</style>