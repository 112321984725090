<template>
    <div
        ref="root"
        class="res-txt-wrap"
        :tabindex="Math.random()"
        :class="{ active: editable }"
        @dblclick.prevent="dblclick"
        @mouseenter="hover = true"
        @mouseleave="hover = false"
    >
        <div
            v-if="init_content"
            :contenteditable="editable"
            @input="input"
            ref="content"
            v-text="init_content"
            style="outline: 0;"
            :title="this.$store.state.is_admin ? '双击或点击修改按钮编辑内容' : ''"
        ></div>
        <div
            v-else
            :contenteditable="editable"
            @input="input"
            ref="content"
            style="outline: 0;"
            :title="this.$store.state.is_admin ? '双击或点击修改按钮编辑内容' : ''"
        >
            <slot v-if="init_content !== undefined && auth">请编辑此处内容</slot>
            <slot v-else></slot>
        </div>
        <transition name="res-txt-fade" @leave="leave" @after-leave="after_leave">
            <div v-if="show" class="res-txt-panel-wrap">
                <div class="res-txt-topbar" title="单击鼠标左键复制ID">
                    <div class="res-txt-id">{{ title }}</div>
                </div>
                <div class="res-txt-fill-space"></div>
                <div class="res-txt-panel">
                    <div class="res-txt-left">
                        <div class="res-txt-button" :class="{ 'res-txt-disable': editable }" @click="edit">修改</div>
                    </div>
                    <div class="res-txt-right">
                        <div class="res-txt-button" :class="{ 'res-txt-disable': difference }" @click="reset">还原</div>
                        <div class="res-txt-button" :class="{ 'res-txt-disable': difference }" @click="save('',$event)">保存</div>
                    </div>
                </div>
                <div class="res-txt-panel-background"></div>
            </div>
        </transition>
        <div class="loading" v-if="isLoading"></div>
        <Notification :content="finishMessage.msg" v-if="finishMessage.flag" />
    </div>
</template>

<script>
import service from "../service";
import proto from "../proto";
import sha256 from 'crypto-js/sha256';
import axios from 'axios';
import Notification from '../ReNotification'

export default {
    ...proto,
    name: "ResTxt",
    data: () => ({
        promiseArr: [],
        isLoading: false,
        finishMessage: {
            flag: false,
            msg: ''
        },
        editable: false,
        hover: false,
        value: null,
        init_content: undefined,
        _parent: null,
        _index: null // 作为列表内容时，对应数据的 index
    }),
    components: {
        Notification
    },
    computed: {
        show() {
            return this.auth && (this.hover || this.editable);
        },
        difference() {
            return this.value === this.init_content;
        },
        auth() {
            return this.$store.state.is_admin;
        },
        real_id() {
            if (this.bind) {
                return sha256(this.id + this.bind).toString().slice(0, 13)
            } else {
                return this.id
            }
        },
        title() {
            if (this._parent) {
                return `父ID: ${this._parent.real_id} 属性: ${this.name}`
            } else {
                let str = ''
                if (this.bind) {
                    str = ` 原ID: ${this.id} 绑定ID: ${this.bind}`
                }
                return `ID: ${this.real_id}${str}`
            }
        }
    },
    watch: {
        value(data) {
            if (!this._parent) return;
            this._parent.update_data(this._index, this.name, data);
        },
        real_id: {
            handler: function(id) {
                if (!id) return;
                
                service.add(id, {
                    type: 'txt',
                    callback: (content) => {
                        // console.log('ccc', content)
                        this.init_content = content;
                        this.value = content;
                    }
                });
            },
            immediate: true
        }
    },
    mounted() {
        window.addEventListener('click', this.whitespace_click);

        // TODO: 指定 parent 关联
        // 判断有 name 属性，视为 ResList 内部组件
        if (this.name) {

            // 获取当前组件对应的数据 index
            let index_obj = this.find_parent('ResIndex')
            let index = index_obj.index
            this._index = index

            let target = this.$refs.content
            // target && (this.init_content = target.innerText)
            this.value = target.innerText

            // 如果是新追加的数据，初始数据设置为空
            // parent.update_init_data(this._index, this.name, '');
            let is_new = index_obj.is_new
            if (!is_new) {
                this.init_content = this.value
            }

            // 查找 ResList 组件
            let parent = this.find_parent('ResList')
            if (!parent) throw '未找到 ResList 组件'

            this._parent = parent

            return;
        }
    },
    destroyed() {
        window.removeEventListener('click', this.whitespace_click);
    },
    methods: {
        whitespace_click(e) {
            if (!this.$refs.root.contains(e.target)) {
                this.editable = false;
            }
        },
        find_parent(name, target = this) {
            if (target.$parent) {
                if (target.$parent.constructor.options.name === name) {
                    return target.$parent;
                } else {
                    return this.find_parent(name, target.$parent);
                }
            } else {
                return null;
            }
        },
        edit() {
            if (!this.auth) return;

            this.editable = true;
        },
        reset() {
            let target = this.$refs.content
            target && (target.innerText = this.init_content)
            this.value = this.init_content
        },
        save(without_ajax) {

            if (!this.auth) return;

            //判断base64格式图片，上传oss重置路径
            this.isLoading = true;
            let newDom = document.createElement('div');
            newDom.innerText = this.value;
            let imgList = Array.from(newDom.querySelectorAll('img'))
            imgList.length > 0 ? this.saveImage(imgList, newDom, this.CopySave, without_ajax): this.CopySave(without_ajax)
            //上传结束
                
        },
        CopySave(without_ajax){
            this.editable = false;
            this.init_content = this.value;
            this._parent && this._parent.update_init_data(this._index, this.name, this.init_content);

            if (!without_ajax) {
                
                // 保存内容
                axios.post('https://assets.wenhua.com.cn/api/Copywriter/Sav', [{
                    docid: this.real_id,
                    content: JSON.stringify(this.value)
                }]).then(()=>{
                    this.isLoading = false;
                    this.finishMessage.msg = '保存成功'
                    this.finishMessage.flag = true;
                    setTimeout(() => {
                        this.close();
                    }, 2000);
                }).catch(err=>{
                    this.isLoading = false;
                    this.finishMessage.msg = '保存失败'
                    this.finishMessage.flag = true;
                    setTimeout(() => {
                        this.close();
                    }, 2000);
                    console.log(err)
                });
            }
        },
        saveImage(imgList ,newDom ,callback ,without_ajax){
            let imgArr = ['data:image/jpg;base64,', 'data:image/png;base64,', 'data:image/gif;base64,', 'data:image/svg;base64,']

            var OSS = require('ali-oss');
            axios.get('https://assets.wenhua.com.cn/api/Copywriter/GetStsToken').then( res => {
                if (res.data.status) {
                    let client = new OSS({
                        region: 'oss-cn-beijing',
                        accessKeyId: res.data.resultData.accessKeyId,
                        accessKeySecret: res.data.resultData.accessKeySecret,
                        stsToken: res.data.resultData.stsToken,
                        bucket: 'wenhua-assets',
                        secure: true
                    });

                    this.timeArr = []
                    this.srcArr = []
                    for (let i = 0; i < imgList.length; i++) {
                        const element = imgList[i];
                        if(imgArr.some(function(name){
                            return element.currentSrc.indexOf(name) > -1;
                        })){
                            this.promiseArr.push(this.putObject(client, element.currentSrc));
                        }
                    }
                    Promise.all(this.promiseArr).then(results => {
                        let i = 0;
                        imgList.forEach((item)=> {
                            if(imgArr.some(function(name){
                                return item.currentSrc.indexOf(name) > -1;
                            })){
                                item.src = results[i]
                                i++;
                            }
                        })
                        this.value = newDom.outerHTML
                        callback(without_ajax)
                    }).catch(err =>{
                        console.log(err);
                    })
                }
            })
        },
        putObject (oss_cli, base64Content) {// 上传图片到oss
            let p = new Promise((resolve, reset)=> {
                let random_name = this.createPic()
                const imgfile = this.dataURLtoFile(base64Content);

                oss_cli.multipartUpload('wh7/' + random_name +'.'+imgfile.type.split('/')[1], imgfile).then(result => {
                    let obj = result.res.requestUrls[0].split('?')[0];
                    resolve(obj)
                }).catch(() => {
                    reset()
                })
            })

            return p
        },
        createPic(){//根据时间生成随机文件名
            var now=new Date(); 
            var year = now.getFullYear(); //得到年份
            var month = now.getMonth();//得到月份
            var date = now.getDate();//得到日期
            var hour = now.getHours();//得到小时
            var minu = now.getMinutes();//得到分钟
            var seconds = now.getSeconds();//得到秒
            month = month + 1;
            if (month < 10) month = "0" + month;
            if (date < 10) date = "0" + date;
            var time = year.toString() + month.toString() + date.toString()+hour.toString()+minu.toString()+seconds.toString();

            //长度15的随机字符串
            var returnStr = "";
            var arr = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
            for(var i=0; i<15 ; i++){
                var index = Math.round(Math.random() * (arr.length-1));
                returnStr += arr[index];
            }

            return time+"_"+returnStr;
        },      
        dataURLtoFile(dataurl) {//base64转图片
            const arr = dataurl.split(',');
            const mime = arr[0].match(/:(.*?);/)[1];
            const bstr = atob(arr[1]);
            let n = bstr.length;
            const u8arr = new Uint8Array(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new Blob([u8arr], { type: mime });// if env support File, also can use this: return new File([u8arr], filename, { type: mime });
        },
        addImage(){
            this.bus.$emit('NewImage', true);
        },
        input(e) {
            this.value = e.target.innerText
        },
        dblclick() {
            if (!this.auth) return;

            this.editable = true;

            this.$nextTick(() => {
                // 修正鼠标双击时光标位置
                if (window.getSelection) {
                    if (window.getSelection().collapse) {
                        window.getSelection().collapseToStart();
                    }
                }
            });
        },
        leave() {
            this.$refs.root.style.zIndex = 10;
        },
        after_leave() {
            this.$refs.root.style.zIndex = null;
        },
        close() {
            this.finishMessage.flag = false;
            this.finishMessage.msg = "";
        }
    },
};
</script>

<style scoped src="./style.css"></style>
