<template>
  <div class="productcenterhtml">
    <div class="productcenterbody">
      <div class="productcenterbodytag">
        <div class="tag_top"><span class="title" style="padding-top:70px">特色功能</span></div>
        <div class="tag_mid" style="padding:20px 0 30px 0">
          <ul >
            <li class="pli">
              <!-- <a href="#productcentertwo"> -->
              <div v-bind:class="[form.productcenter1 ? class4 : class3]"></div>
              <div
                v-bind:class="[form.productcenter1 ? class1 : class2]"
                @click="go('productcenter1')"
              >
                <div style="width: 26px; height: 40px; float: left"></div>
                <div class="tagmid">
                  模型运行管理中心
                </div>
                <div style="height: 40px; float: left"></div>
              </div>
              <!-- </a> -->
            </li>
            <li class="pli">
              <div v-bind:class="[form.productcenter2 ? class4 : class3]"></div>
              <div
                v-bind:class="[form.productcenter2 ? class1 : class5]"
                style="line-height: 20px"
                @click="go('productcenter2')"
              >
                <div style="width: 26px; height: 40px; float: left"></div>
                <div class="tagmid">远程监控模型运行情况</div>
                <div style="height: 40px; float: left"></div>
              </div>
              <div
                style="width: 9px; height: 7px; float: left; ine-height: 7px"
              ></div>
              <!-- </a> -->
            </li>
            <li class="pli">
              <div v-bind:class="[form.productcenter12 ? class4 : class3]"></div>
              <div
                v-bind:class="[form.productcenter12 ? class1 : class5]"
                style="line-height: 20px"
                @click="go('productcenter12')"
              >
                <div style="width: 26px; height: 40px; float: left"></div>
                <div class="tagmid">头寸管理</div>
                <div style="height: 40px; float: left"></div>
              </div>
              <div
                style="width: 9px; height: 7px; float: left; ine-height: 7px"
              ></div>
              <!-- </a> -->
            </li>
            <li class="pli">
              <!-- <a href="#productcentertwo"> -->
              <div v-bind:class="[form.productcenter3 ? class4 : class3]"></div>
              <div
                v-bind:class="[form.productcenter3 ? class1 : class2]"
                @click="go('productcenter3')"
              >
                <div style="width: 26px; height: 40px; float: left"></div>
                <div class="tagmid">模组持仓匹配校验</div>
                <div style="height: 40px; float: left"></div>
              </div>
              <!-- </a> -->
            </li>
            <li class="pli">
              <!-- <a href="#productcentertwo"> -->
              <div v-bind:class="[form.productcenter4 ? class4 : class3]"></div>
              <div
                v-bind:class="[form.productcenter4 ? class1 : class2]"
                @click="go('productcenter4')"
              >
                <div style="width: 26px; height: 40px; float: left"></div>
                <div class="tagmid">资管产品的申购/赎回管理</div>
                <div style="height: 40px; float: left"></div>
              </div>
              <!-- </a> -->
            </li>
            <li class="pli">
              <!-- <a href="#productcentertwo"> -->
              <div v-bind:class="[form.productcenter13 ? class4 : class3]"></div>
              <div
                v-bind:class="[form.productcenter13 ? class1 : class2]"
                @click="go('productcenter13')"
              >
                <div style="width: 26px; height: 40px; float: left"></div>
                <div class="tagmid">多账户量化</div>
                <div style="height: 40px; float: left"></div>
              </div>
              <!-- </a> -->
            </li>
            <li class="pli">
              <!-- <a href="#productcentertwo"> -->
              <div v-bind:class="[form.productcenter5 ? class4 : class3]"></div>
              <div
                v-bind:class="[form.productcenter5 ? class1 : class2]"
                @click="go('productcenter5')"
              >
                <div style="width: 26px; height: 40px; float: left"></div>
                <div class="tagmid">主连链回测</div>
                <div style="height: 40px; float: left"></div>
              </div>
              <!-- </a> -->
            </li>
            <li class="pli">
              <!-- <a href="#productcentertwo"> -->
              <div v-bind:class="[form.productcenter6 ? class4 : class3]"></div>
              <div
                v-bind:class="[form.productcenter6 ? class1 : class2]"
                @click="go('productcenter6')"
              >
                <div style="width: 26px; height: 40px; float: left"></div>
                <div class="tagmid">组合回测</div>
                <div style="height: 40px; float: left"></div>
              </div>
              <!-- </a> -->
            </li>
            <li class="pli">
              <!-- <a href="#productcentertwo"> -->
              <div v-bind:class="[form.productcenter7 ? class4 : class3]"></div>
              <div
                v-bind:class="[form.productcenter7 ? class1 : class2]"
                @click="go('productcenter7')"
              >
                <div style="width: 26px; height: 40px; float: left"></div>
                <div class="tagmid">期货多因子</div>
                <div style="height: 40px; float: left"></div>
              </div>
              <!-- </a> -->
            </li>
            <li class="pli">
              <!-- <a href="#productcenterfive"> -->
              <div v-bind:class="[form.productcenter11 ? class4 : class3]"></div>

              <div
                v-bind:class="[form.productcenter11 ? class1 : class2]"
                @click="go('productcenter11')"
              >
                <div style="width: 26px; height: 40px; float: left"></div>
                <div class="tagmid">股票T+1策略运行池</div>
                <div style="height: 40px; float: left"></div>
              </div>
              <!-- </a> -->
            </li>
            <!-- <li class="pli">
              <div v-bind:class="[form.productcenter10 ? class4 : class3]"></div>

              <div
                v-bind:class="[form.productcenter10 ? class1 : class2]"
                @click="go('productcenter10')"
              >
                <div style="width: 26px; height: 40px; float: left"></div>
                <div class="tagmid">积木式编程语言</div>
                <div style="height: 40px; float: left"></div>
              </div>
            </li> -->
          </ul>
        </div>
        <div class="tag_bottom"></div>
      </div>
      <div class="productcenterbodytext" ref="box">
        <div style="width: 780px; border-radius: 2%; position: relative">
          <div class="midtext">
            <div v-if="form.productcenter1">
              <div class="head4" id="productcenter1">
                <span>优势一</span>&nbsp;模型运行管理中心
              </div>
              <p class="pproduct" style="padding-bottom: 5px">
                模组是WT8的运行管理平台，列表式的管理界面，对各个模型的运行集中管理，每一个运行单元的总收益，当前的信号、下单、持仓、持仓盈亏等数据一目了然。
              </p>
              <p class="pproduct" style="padding: 0">模组还支持收益分析、资金管理、持仓匹配校验、手动加减仓干预等功能。
              </p>
              <BaseBigimg :src="require('../../assets/images/T8-mz1.jpg')"></BaseBigimg>
              <!-- <p class="pproduct">
              模组还支持查看各分区的合计持仓、可用资金等基本信息，以及每个分区的组合资金曲线，直观的反映一段时间内，投资组合内每个模组单元和整体的资金变动情况，为优化投资组合提供依据。
            </p>
            <p class="pimg"><img src="../../assets/images/pro2.png" style="margin-bottom: 30px;" /></p> -->
            </div>
            <div v-if="form.productcenter2">
              <div class="head4" id="productcenter2">
                <span>优势二</span>&nbsp;远程监控模型运行情况
              </div>
              <p class="pproduct" style="padding-bottom: 5px">
                远程监控是交易员的操作页面，支持在交易员接触不到模型的情况下，监控模型运行，保证模型的下单指令得到有效执行。交易员虽然不直接参与下单，但当出现网络不稳定、行情剧烈波动等情况，导致信号委托无法成交时，需要交易员进行撤单、追价等操作，保证每一个信号能够完整、连续的执行。
              </p>
              <p class="pproduct" style="padding-top: 0">
                WT8支持远程监控模组各个单元在云主机上的运行和网络状态，需要时可手动干预，进行撤单和追价操作。
              </p>
              <p class="pimg">
                <img
                  src="../../assets/images/pic_04.png"
                  style="margin-bottom: 30px"
                />
              </p>
            </div>
            <div v-if="form.productcenter3">
              <div class="head4" id="productcenter3">
                <span>优势四</span>&nbsp;模组持仓匹配校验
              </div>
              <p class="pproduct" style="padding-bottom: 5px">
                这个功能用于在电脑、网络以及其他交易环境故障，导致信号没有得到有效执行的情况下，进行手动处理。
              </p>
              <p class="pproduct" style="padding: 0">
                当模组的运行单元数量较多时，逐一去检查单元持仓和理论持仓是否匹配费时费力。WT8提供智能的持仓匹配校验功能，可一键校验运行单元的持仓匹配情况，方便进行手动处理。
              </p>
              <BaseBigimg :src="require('../../assets/images/cpys3.jpg')"></BaseBigimg>
              <p class="pproduct" style="padding-bottom: 5px">
                手动处理的方法如下：单元持仓小于理论持仓的情况下，可在模组里点击“加仓”按钮来补仓，完成持仓匹配。模组会把以上加仓操作的成交价格自动带入运行单元，作为收益计算的依据，不会影响模组的继续运行和实际收益的统计。
              </p>
              <BaseBigimg :src="require('../../assets/images/cpys3_1.jpg')"></BaseBigimg>
            </div>
            <div v-if="form.productcenter4">
              <div class="head4" id="productcenter4" >
                <span>优势五</span>&nbsp;资管产品的申购/赎回管理
              </div>
  
              <p class="pproduct">
                在一个运行单元带一个账号的情况下，软件支持申购/赎回管理。模型写头寸管理函数实现按理论资金比例下单，当一只资管产品需要进行申购/赎回时，可在资金管理界面，对该产品的运行单元进行出入金操作，后续的开仓指令，模型会根据调整后的资金重新计算下单手数。
              </p>
              <p class="pproduct">
                投资经理可以根据每个运行单元的实盘运行情况，确定申购/赎回的金额，通过汇总查看此次申购/赎回的总金额。
              </p>
              <BaseBigimg :src="require('../../assets/images/T8-mz501.jpg')"></BaseBigimg>
            </div>
            <div v-if="form.productcenter5">
              <div class="head4" id="productcenter5">
                <span>优势七</span>&nbsp;主连链回测
              </div>
              <p class="pproduct">
                主连链回测（<a
                  href="https://www.wenhua.com.cn/popwin/wh8zhulianlian.htm"
                  target="_blank"
                  >点击查看运行机制</a
                >）是一种严谨的利用长期数据验证策略的方法，不再使用品种主连自身的k线数据计算信号，而是采用各个月份合约自挂牌以来的全部k线数据计算信号，规避品种主连k线数据换月跳空、新旧合约趋势相反等情况对量化计算准确性的影响。主力换月时，旧主力月份平仓，新主力根据月份合约的k线数据重新计算开仓信号。
              </p>
              <p class="pimg">
                <img
                  src="../../assets/images/630-tu.jpg"
                  style="margin-bottom: 30px"
                />
              </p>
            </div>
            <div v-if="form.productcenter6">
              <div class="head4" id="productcenter6">
                <span>优势八</span>&nbsp;组合回测
              </div>
              <p class="pproduct">
                多模型组合交易能够分散交易风险，组合测试功能分分钟为你出具历史数据回测报告。以tick精准历史回测为基础，进行多品种、多策略、多周期任意组合的测试，助你搭建最优的投资组合。
              </p>
              <p class="pimg"><img src="../../assets/images/pic_02.png" /></p>
            </div>
            <div v-if="form.productcenter7">
              <div class="head4" id="productcenter7">
                <span>优势九</span>&nbsp;期货多因子
              </div>
              <p class="pproduct" style="padding-bottom: 5px">
                多因子分析是一种构建最优投资组合的方法。量化策略大多基于技术分析的，管理的是买卖点，赚取买卖价差，属于择时交易。WT8的期货多因子分析实现了期货品种横向的对比，可更多从非技术面的角度去筛选能够带来更多超额收益的品种。
              </p>
              <p class="pproduct" style="padding-top: 0">
                文华精选期货因子囊括量价、动量、期限结构、持仓、基本面、BETA六大类精选因子，以期货市场全品种为标的，提供单因子筛选、多因子排名分析等功能。可借助多因子综合排名信息，筛选出能够带来更多超额收益的品种，构建最优组合。还可以对多个品种在同一时间点的强弱情况进行对比分析，建立对冲组合，实现期货横截面交易策略。
              </p>
              <p class="pimg"><img src="../../assets/images/dyz.png" /></p>
            </div>
            <!-- <div v-if="form.productcenter8">
              <div class="head4" id="productcenter8" style="width: 200px">
                <span>优势九</span>&nbsp;策略优化函数
              </div>
              <p class="pproduct">
                同样的策略搬到T8来跑，盈利效果大大改观！<router-link
                  to="/download/download/2"
                  active-class="nosee"
                  >点击查看详细说明</router-link
                >
              </p>
              <p class="pimg"><img src="../../assets/images/pic_08-2.png" /></p>
            </div> -->
            <!-- <div v-if="form.productcenter9">
            <div class="head4" id="productcenter9" style="width: 180px">
              <span>优势九</span>&nbsp;基本面函数
            </div>
            <p class="pproduct">
              将基本面数据、突发事件引用到模型中驱动程序，实现基本面量化的自动交易。
            </p>
           
            <p class="pimg"><img src="../../assets/images/pic_09.png" /></p>
             </div> -->
            <div v-if="form.productcenter11">
              <div class="head4" id="productcenter11">
                <span>优势十</span>&nbsp;股票T+1策略运行池
              </div>
              <p class="pproduct">
                股票T+1策略运行池是进行股票趋势量化投资的工具，可通过编写股票T+1策略模型构建一篮子股票的量化投资策略。借助云计算技术，软件可在短时间内生成一篮子股票投资组合的回测报告，报告囊括了八大类，近百项统计数据，并提供资金曲线、持仓明细、交易列表等多维度、多视角的回测数据助力策略研发。最新交易列表支持导出下单文件，可配合文件下单功能实现股票半自动交易（<a
                  href="https://www.wenhua.com.cn/popwin/xdwj.html"
                  target="_blank"
                  >点击查看文件下单说明</a
                >）。
              </p>
              <p class="pimg">
                <img
                  style="margin-bottom: 50px"
                  src="../../assets/images/gpt1-1.png"
                />
              </p>
            </div>

            <div v-if="form.productcenter12">
              <div class="head4" id="productcenter12" >
                <span>优势三</span>&nbsp;头寸管理
              </div>
              <p class="pproduct">
                头寸管理，即量化模型中的资金使用计划，包括使用的保证金数量、下单手数的规划、风险管理等。如果说模型赚不赚钱是由进出场条件决定的，那么大赚还是小赚则是由头寸管理决定。
              </p>
              <p class="pproduct">
                WT8提供39个头寸管理函数，对于用户设计资金使用比例、下单手数、结合盈亏动态调控仓位、根据权益回撤进行风控等场景都能有效支持，在资金合理利用的前提下让盈利奔跑。
              </p>
              <p class="pproduct" >
                含有头寸管理函数的模型装入到模组中运行时，模组可为每个运行单元分配独立的资金，各单元独立计算盈亏、可用资金及权益变化，为机构多策略、多周期、多合约组合运行提供了有力的保障。
              </p>
              <BaseBigimg :src="require('../../assets/images/TCGL-2.jpg')"></BaseBigimg>
            </div>
             <div v-if="form.productcenter13">
              <div class="head4" id="productcenter12" >
                <span>优势六</span>&nbsp;多账户量化
              </div>
              <p class="pproduct">
                模型可以绑定多个账号，每一个账户可以设置手数倍率，模组可以根据每一个账户的量化下单记录，做收益分析以及胜率、盈亏比等统计。
              </p>
              <p class="pproduct">
                多账户可以跨不同期货公司、跨不同交易后台（CTP、金仕达、恒生多种后台）登录，支持最多配置10个交易账户。
              </p>
              <BaseBigimg :src="require('../../assets/images/dzh-38.jpg')"></BaseBigimg>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        productcenter1: true,
        productcenter2: false,
        productcenter3: false,
        productcenter4: false,
        productcenter5: false,
        productcenter6: false,
        productcenter7: false,
        productcenter8: false,
        productcenter9: false,
        productcenter10: false,
        productcenter11: false,
        productcenter12: false,
        productcenter13: false,
      },
      class1: "onclick",
      class2: "noclick",
      class3: "touming",
      class4: "yellow",
      class5: "noclickone",
      class6: "noclickele",
      scroll: "",
    };
  },
  watch: {
    $route: {
      handler: function (val) {
        // console.log(val.params.id);
        this.custormAnchor(val.params.id);
        this.zero();
        this.form[val.params.id] = true;
      },

      deep: true,
    },
  },
  methods: {
    custormAnchor(anchorName) {
      // // 找到锚点
      // let anchorElement = document.getElementById(anchorName);

      // // 如果对应id的锚点存在，就跳转到锚点

      // if(anchorElement) {
      // this.$nextTick(()=>{
      //   window.scrollTo(0,anchorElement.offsetTop+50);

      // })

      //  }
      var target = document.getElementById(anchorName);
      if (target !== null) {
        this.$refs.box.scrollTop = target.offsetTop;
      }

      //  window.scrollTo(0,target.offsetTop);
    },

    go(id) {
      this.$router.push({ path: `/proc/productcenter/${id}` });
      // this.custormAnchor(id);
    },

    zero() {
      Object.keys(this.form).forEach((item) => {
        this.form[item] = false;
      });
    },
  },

  mounted() {
    // this.$refs.box.addEventListener("scroll", this.menu);
    this.zero();
    this.form[this.$route.params.id] = true;
    this.custormAnchor(this.$route.params.id);
  },
};
</script>

<style scoped>
.productcenterhtml {
  background: url("../../assets/images/cpzx-top.png") no-repeat top;
  background-size: 100%;
  min-width: 1400px;
  width: 100%;
  /* height: 839px; */
  padding-top: 110px;
  /* background-position-y: 23px */
}
.productcenterhead {
  position: relative;
  height: 140px;
  width: 1000px;
  margin: 0 auto;
}
.productcenterheadtext {
  position: absolute;
  top: 105px;
  left: 3px;
}
.productcenterbody {
  width: 1030px;
  margin: 0 auto;
  /* height: 695px; */
  position: relative;
}
.productcenterbodytag {
  width: 290px;
  border-radius: 2%;
  display: inline-block;
}
.productcenterbodytext {
  /* position: absolute; */
  /* top: 30px; */
  /* left: 250px; */
  width: 770px;
  min-height: 642px;
  border-top-left-radius: 1em;
  background: url("../../assets/images/ts-1_02.jpg") no-repeat center;
  background-size: 100% 100%;
  /* overflow-y: scroll;
  overflow-x: hidden; */
  filter: drop-shadow(1px 1px 2px #c2c5d4);
  /*box-shadow: -13px 0px rgb(218 184 135 / 10%);*/
  padding-top: 25px;
  display: inline-block;
  margin-left: -30px;
  margin-top: 30px;
  vertical-align: top;
}
.noclick {
  height: 41px;
  line-height: 41px;

  /*width: 270px;*/
  /* border-top: 1px solid #2f5476; */
  letter-spacing: 1px;
  color: #97826e;
  text-align: left;
  float: left;
  font-size: 14px;
  font-weight: normal;
  cursor: pointer;
}
.onclick {
  height: 42px;
  line-height: 42px;
  background: #fdfdfd;

  /*width: 270px;*/

  color: #6f5429;
  text-align: left;
  letter-spacing: 1px;

  float: left;
  font-size: 14px;
  font-weight: normal;
  cursor: pointer;
}
.touming {
  width: 5px;
  height: 42px;
  float: left;
}
.yellow {
  width: 5px;
  height: 42px;
  background: #c49c71;
  float: left;
  border-top-left-radius: 0.1em;
  border-bottom-left-radius: 0.1em;
}
.head4 {
  font-size: 15px;
  /* padding-top: 20px; */
  /* padding-bottom: 10px; */
  /* font-weight: bold; */
  letter-spacing: 1px;
  height: 26px;
  line-height: 26px;
  background-color: #e4e3e1;
  color: #6f5429;
  margin-top: 20px;
  vertical-align: middle;
  margin-bottom: 10px;
  display: inline-block;
  padding-right: 10px;
}
.head4 span {
  color: #fff;
  /* padding-left: 5px; */
  display: inline-block;
  height: 26px;
  line-height: 26px;
  width: 74px;
  background: url("../../assets/images/yous.png") no-repeat left;
  background-size: 100%;
  /* text-indent: 1em; */
  text-align: center;
}
.head4 .cp2 {

  width: 80px;
  background: url("../../assets/images/cp2.png") no-repeat left;
}
.midtext {
  /* height: 680px; */
  width: 680px;
  margin: 0 auto;
  /* padding-bottom: 100px; */
}
.pproduct {
  font-size: 15px;
  padding: 3px 0;
  text-indent: 2em;
  color: #727983;
  line-height: 25px;
  /* width: 640px; */
  letter-spacing: 1px;
  text-align: justify;
}
.pimg {
  padding-top: 10px;
  padding-bottom: 10px;
  min-height: 50px;
  width: 100%;
  text-align: center;
}
.noclick:hover {
  color: #e3a200;
}
.pli {
  width: 300px;
  height: 42px;
  /* margin-left: -5px; */
  position: relative;
  z-index: 88;
}
.noclickone {
  height: 41px;
  line-height: 41px;

  letter-spacing: 1px;
  /*width: 270px;*/

  /* border-top: 1px solid #2f5476; */
  color: #97826e;
  text-align: left;
  float: left;
  font-size: 14px;
  font-weight: normal;
  cursor: pointer;
}
.noclickele {
  height: 40px;
  line-height: 40px;

  letter-spacing: 1px;
  /*width: 270px;*/
  /* border-top: 1px solid #2f5476;
  border-bottom: 1px solid #2f5476; */
  color: #85c0ea;
  text-align: left;
  float: left;
  font-size: 14px;
  font-weight: normal;
  cursor: pointer;
}
.noclickone:hover {
  color: #f59726;
}
.noclickele:hover {
  color: #f59726;
}
.tagmid {
  width: 230px;
  height: 42px;
  float: left;
  font-weight: bold;
  line-height: 42px;
  border-bottom: 1px dashed #ccc;
}
.productcenterbodytag .title {
  display: block;
  height: 60px;
  color: #fff;
  font-weight: bold;
  text-indent: 2em;
  font-size: 16px;
  /* line-height: 35px; */
}
.tag_top{
  height: 108px;
  background: url(../../assets/images/cpzx_01.png) no-repeat top;
}
.tag_mid{
  background: url(../../assets/images/cpzx_02.png) no-repeat center;
  background-size:100% 100%;
}
.tag_bottom{
  height: 20px;
  background: url(../../assets/images/cpzx_03.png) no-repeat bottom;
}
</style>