<template>
  <div class="tongyongdl">
    <div class="tongyongcard">
      <img src="../../../../assets/images/togny.png" style="margin-top: 40px" />
      <p
        style="
          color: #0e286d;
          line-height: 25px;
          letter-spacing: 1px;
          font-size: 14px;
        "
      >
        这是一款收费软件，需要授权账号/密码才能登录，提供量化、多账号等功能授权
      </p>
    </div>
    <div style="border-top:1px dashed #b5c3d8;width:800px;height:1px;margin:0 auto"></div>
    <div class="tongyongbody">
      <div class="tongyongbodydl">
        <div class="tongyongdllf">
          <h4
            style="
              color: #1e5c88;
              line-height: 50px;
              height: 50px;
              padding-top: 30px;
              letter-spacing: 1px;
            "
          >
            使用应满足的条件
          </h4>
          <ResTxt class="h4p" id="21f9c930ee9ef" /><br>
          <ResTxt class="h4p" id="4339f32066630" /><br>
          <ResTxt class="h4p" id="b32f6d890511b" /><br>
          <ResTxt class="h4p" id="0eb00a21cc889" /><br>
          <ResTxt class="h4p" id="9342987754b86" /><br>
          <!-- <p class="h4p">1、CPU：X86架构，4核及以上配置；</p>
          <p class="h4p">2、内存：要达到8G以上；</p> -->
          <!-- <router-link to="/download/softAdvantage"><a class="linka" >64位软件的优势</a></router-link> -->
          <!-- <p class="h4p">3、操作系统：win7/win8/win10/win2008的64位；</p>
          <p class="h4p">4、建议使用电信、联通宽带，其它宽带会有卡顿的问题。</p>
          <p class="h4p">
            5、租用云主机仅做模组运行的情况下，最低配置2核CPU/4G内存就可以运行。
          </p> -->
        </div>
        <div class="tongyongdlrg">
          <!-- <p class="shangmen">
            <span @click="open"
              ><img
                src="../../../../assets/images/shangm.png"
                style="vertical-align: middle"
              />
              预约上门讲解软件使用</span
            >
          </p> -->
          <div class="dlbutton">
            <div
              style="
                background: #c4954d;
                border-radius: 0.5em;
                height: 45px;
                width: 140px;
                color: white;
                font-size: 18px;
                line-height: 45px;
                text-align: center;
                display: inline-block;

                cursor: pointer;
              "
              @click="dianxin"
            >
              电信下载
            </div>
            <div
              style="
                background: #364064;
                border-radius: 0.5em;
                height: 45px;
                width: 140px;
                color: white;
                font-size: 18px;
                line-height: 45px;
                text-align: center;
                display: inline-block;
                margin-left: 40px;
                cursor: pointer;
              "
              @click="liantong"
            >
              联通下载
            </div>
          </div>
          <div
            style="
              font-size: 12px;
              height: 54px;
              line-height: 54px;
              color: #9b9b9c;
              letter-spacing: 1px;
            "
          >
            {{ tybanben }}
          </div>
          <div
            style="
              width: 318px;
              font-size: 12px;
              letter-spacing: 1px;
              padding: 5px 5px 0 5px;
              background: #d6e7ff; ;
            "
          >
            <img
              src="../../../../assets/images/care.png"
              style="vertical-align: top; margin-right: 2px"
            />
            <span style="color: #727983"
              >请在文华官网下载正版文华软件，文华官网下载不会</span
            >
            <br />
            <span style="padding-left: 18px; line-height: 24px; color: #727983"
              >以任何形式向你索要电话号码，请注意谨防上当。</span
            >
          </div>
          <!-- <div style="margin-top:15px;font-size:14px;">
            <a href="https://ftpc.wenhua.com.cn/files/SPwh8.3_setup_x64_8.3.989.exe" style="color:#193ea5;">wh8旧版本下载</a>
            (Ver8.3.989，不支持主连链回测)
          </div> -->
          <!-- <p class="shangmen">
            <span @click="open"
              ><img
                src="../../../../assets/images/shangm.png"
                style="vertical-align: middle"
              />
              预约上门讲解软件使用</span
            >
          </p> -->
        </div>
      </div>
      <div style="margin-left: 40px">
        <h4
          style="
            color: #1e5c88;
            line-height: 50px;
            height: 50px;
            padding-top: 30px;
            letter-spacing: 1px;
          "
        >
          登录实盘账号
        </h4>
        <ResTxt
          class="h4p"
          id="53b71b74b11d5"
          style="
            text-indent: 2em;
            margin-right: 20px;
            letter-spacing: 1px;
            font-size: 15px;
            height: auto;
          "
        /><br>
        <!-- <p
          class="h4p"
          style="
            text-indent: 2em;
            margin-right: 20px;
            letter-spacing: 1px;
            font-size: 15px;
          "
        >
          如下图所示，在登录界面输入购买的授权账号及密码，即可登录行情。如需交易需点击“期货户/外盘户”按钮，在弹出窗口中选择开户公司，输入资金账号及密码，即可登录交易。
        </p> -->
        <div style="margin-top: 20px; margin-left: 120px">
          <img src="../../../../assets/images/ty.png" />
        </div>
      </div>
    </div>
    <pingjia></pingjia>
  </div>
</template>

<script>
import ResTxt from '@/ResData/ResTxt'
import pingjia from "./pingjia.vue";
import { jsonp } from "vue-jsonp";

export default {
  components: {
    pingjia,
    ResTxt
  },
  data() {
    return {
      tybanben: "",
      tydata: "",
    };
  },
  methods: {
    dianxin() {
      if (this.tydata.split("|")[0] == "") {
        alert("下载信息加载中，请稍后再试");
      } else {
        window.location.href =
          "//ftpc.wenhua.com.cn/files/WT8_setup_" +
          this.tydata.split("|")[0] +
          ".exe";
      }
    },
    liantong() {
      if (this.tydata.split("|")[0] == "") {
        alert("下载信息加载中，请稍后再试");
      } else {
        window.location.href =
          "//ftpc1.wenhua.com.cn/files/WT8_setup_" +
          this.tydata.split("|")[0] +
          ".exe";
      }
    },
    open() {
      this.$emit("openModal");
    },
    getpj() {},
  },

  mounted() {
    jsonp("https://db.wenhua.com.cn/inc/update.asp", {
      appid: 11,
      t: 0,
    })
      .then((data) => {
        this.tydata = data;

        this.tybanben =
          "  版本:" +
          this.tydata.split("|")[0] +
          "" +
          " (第" +
          this.tydata.split("|")[3] +
          "次修改)" +
          "" +
          " 大小:" +
          this.tydata.split("|")[2] +
          "" +
          " 日期:" +
          this.tydata.split("|")[1];
      })
      .catch((err) => {
        console.log(err);
      });
  },
};
</script>

<style>
.tongyongdl {
  width: 1005px;
  /* background: url("../../../../assets/images/1.png") no-repeat top; */
  min-height: 200px;
  /* background: #ebf2fc; */
}
.tongyongcard {
  width: 980px;
  margin: 0 auto;
  height: 315px;
  position: relative;
  text-align: center;
  /* padding-top: 30px; */
}
/* .tongyongcard ::after {
  content: " ";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 15px 15px 0 15px;
  border-color: #212a6b transparent transparent transparent;
  display: block;
  position: absolute;
  bottom: -14px;
  left: 485px;
} */
.tongyongbody {
  width: 1003px;
  min-height: 633px;
  /* border: 1px solid #7f83b3; */
  border-top: none;
  border-bottom: none;
  background: #e8edf5;
}
.tongyongbodydl {
  width: 1005px;
  min-height: 220px;
}
.tongyongdllf {
  width: 528px;
  float: left;
  height: 190px;
  margin-left: 40px;
}
.tongyongdlrg {
  padding-top: 45px;
  width: 400px;
  float: right;
  height: 180px;
  position: relative;
}
.h4p {
  height: 28px;
  line-height: 28px;
  color: #727983;
  letter-spacing: 1px;
  font-size: 14px;
}
.dlbutton {
  /* margin-top: 100px; */
}

.yongpingjia {
  /* margin-top: 50px; */
  letter-spacing: 1px;
  background: url("../../../../assets/images/yonghpj.png") no-repeat left;
  margin-left: -9px;
  color: white;
  height: 40px;
  width: 110px;
  text-align: center;
  line-height: 30px;
}
.pingjiatable {
  width: 910px;
  min-height: 420px;
  margin: 0 auto;
}
.pingj {
  min-height: 70px;
  border-top: 1px dashed #8e8f90;
}
.pagebt {
  height: 25px;
  width: 25px;
  text-align: center;
  line-height: 25px;
  cursor: pointer;
  background: #ffffff;
  color: #a6a6a6;
  float: right;
  margin-left: 5px;
  margin-right: 5px;
  user-select: none;
  border: 1px solid #dfdfdf;
}
.clickpagebt {
  height: 25px;
  width: 25px;
  text-align: center;
  line-height: 25px;
  cursor: pointer;
  background: #044c7e;
  color: #ffffff;
  margin-left: 5px;
  margin-right: 5px;
  float: right;
  user-select: none;
  border: 1px solid #dfdfdf;
}
.notclick {
  height: 25px;
  width: 25px;
  text-align: center;
  line-height: 25px;
  cursor: pointer;
  background: #ffffff;
  color: #a6a6a6;
  float: right;
  margin-left: 5px;
  margin-right: 5px;
  pointer-events: none;
  user-select: none;
  border: 1px solid #dfdfdf;
}
.pjcss1 {
  padding-top: 15px;
  color: #5e5f5f;
  font-size: 14px;
  line-height: 25px;
  letter-spacing: 1px;
}
.pjcss2 {
  padding-bottom: 5px;
  letter-spacing: 1px;
  color: #868686;
  font-size: 12px;
  line-height: 25px;
}
</style>