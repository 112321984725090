import store from "./store";

export default {
    store,
    props: {
        id: {
            type: String,
            required: false
        },
        name: {
            type: String,
            required: false
        },
        bind: {
            type: String,
            required: false
        },
        parent: {
            type: String,
            required: false
        }
    },
}