<template>
  <div class="shanghai">
    <div class="shanghtable1" v-if="page.page1">
      <table>
        <tr>
          <td
            style="
              width: 620px;
              background: #e2e8f4;
              text-align: center;
              color: #454d60;
              padding-left: 0px;
            "
          >
            公司名称
          </td>
        </tr>

        <tr>
          <td>
            <a
              href="https://www.youshitouzi.com/website/w/h"
              class="comlink"
              target="_blank"
              >上海由势投资管理有限公司</a
            >
          </td>
        </tr>
        <tr>
          <td>
            <a href="https://www.ucanfund.cn/" class="comlink" target="_blank"
              >上海元葵资产管理中心（有限合伙）</a
            >
          </td>
        </tr>
        <tr>
          <td>上海朔阳资产管理有限公司</td>
        </tr>
        <tr>
          <td>上海和鲁股权投资管理有限公司</td>
        </tr>
        <tr>
          <td>上海红叶枫资产管理有限公司</td>
        </tr>
        <tr>
          <td>上海昊仁投资管理中心（有限合伙）</td>
        </tr>
        <tr>
          <td>上海济桓投资管理有限公司</td>
        </tr>
        <tr>
          <td>上海荣祁投资管理有限公司</td>
        </tr>
        <tr>
          <td>上海裕灏投资管理有限公司</td>
        </tr>
        <tr>
          <td>上海远翰资产管理有限公司</td>
        </tr>
        <tr>
          <td>上海龙龟投资管理有限公司</td>
        </tr>
      </table>
    </div>
    <div class="shanghtable2" v-if="page.page2">
      <table>
        <tr>
          <td
            style="
              width: 620px;
              background: #e2e8f4;
              text-align: center;
              color: #454d60;
              padding-left: 0px;
            "
          >
            公司名称
          </td>
        </tr>

        <tr>
          <td>上海睦沣投资管理有限公司</td>
        </tr>
        <tr>
          <td>上海星楼投资管理有限公司</td>
        </tr>
        <tr>
          <td>上海泽源投资管理有限公司</td>
        </tr>
        <tr>
          <td>上海秉奕资产管理有限公司</td>
        </tr>
        <tr>
          <td>上海准格投资管理有限公司</td>
        </tr>
        <tr>
          <td>上海步耘投资管理有限公司</td>
        </tr>
        <tr>
          <td>上海象弈资产管理有限公司</td>
        </tr>
        <tr>
          <td>上海弘震资产管理有限公司</td>
        </tr>
        <tr>
          <td>上海勤远投资管理中心(有限合伙)</td>
        </tr>
      </table>
    </div>
    <div class="successcomfoot">
      <div v-bind:class="[page.page1 ? class1 : class2]" @click="showp1">1</div>
      <div v-bind:class="[page.page2 ? class1 : class2]" @click="showp2">2</div>
      共2页，20条
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      page: {
        page1: true,
        page2: false,
      },
      class2: "pagebutton",
      class1: "pageclickbutton",
    };
  },
  methods: {
    showp1() {
      Object.keys(this.page).forEach((item) => {
        this.page[item] = false;
      });
      this.page.page1 = true;
    },
    showp2() {
      Object.keys(this.page).forEach((item) => {
        this.page[item] = false;
      });
      this.page.page2 = true;
    },
  },
};
</script>

<style >
.pagebutton {
  display: inline-block;
  height: 25px;
  width: 25px;
  margin-right: 15px;
  border: 1px solid #dfdfdf;
  text-align: center;
  line-height: 25px;
  cursor: pointer;
  color: #adadad;
  user-select: none;
}
.pageclickbutton {
  display: inline-block;
  height: 25px;
  width: 25px;
  margin-right: 15px;
  border: 1px solid #dfdfdf;
  color: white;
  text-align: center;
  line-height: 25px;
  background: #22427d;
  cursor: pointer;
  user-select: none;
}
.comlink {
  color: #000000;
  text-decoration: none;
}
a:hover {
  color: blue;
}
</style>