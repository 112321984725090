<template>
  <div class="tradehtml">
    <div class="tradeall">
      <div class="tradebody">
        <div class="tradebodyhead">
          <div v-bind:class="[form.guonei ? class1 : class2]">
            <div
              style="
                height: 50px;
                text-align: center;
                line-height: 50px;
                font-weight: bold;
              "
              @click="change('guonei')"
            >
              期货/期权
            </div>
          </div>
          <div
            v-bind:class="[form.touzi ? class7 : class8]"
            style="width: 4px; height: 50px"
          ></div>
          <div v-bind:class="[form.touzi ? class9 : class10]">
            <div
              style="
                height: 50px;
                text-align: center;
                line-height: 50px;
                font-weight: bold;
              "
              @click="change('touzi')"
            >
              投资机构
            </div>
          </div>

          <div
            v-bind:class="[form.guonei ? class7 : class8]"
            style="width: 4px; height: 50px"
          ></div>

          <!-- <div v-bind:class="[form.guowai ? class3 : class4]">
            <div
              style="
                height: 50px;
                text-align: center;
                line-height: 50px;
                font-weight: bold;
              "
              @click="change('guowai')"
            >
              境外期货
            </div>
          </div> -->

          <!-- <div
            v-bind:class="[form.guonei ? class7 : class8]"
            style="width: 4px; height: 50px"
          ></div> -->
          <div v-bind:class="[form.gupiao ? class5 : class6]">
            <div
              style="
                height: 50px;
                text-align: center;
                line-height: 50px;
                font-weight: bold;
              "
              @click="change('gupiao')"
            >
              股票期权
            </div>
          </div>
        </div>
        <div class="tradebodybody">
          <p
            v-if="form.guonei"
            style="
              height: 60px;
              line-height: 25px;
              text-indent: 3em;
              font-size: 14px;
              color: #5a5a5a;
            "
          >
            提示：文华财经只是一家软件服务公司，开户/入金等业务请另外找期货公司办理。
          </p>
          <guonei v-if="form.guonei"></guonei>
          <guowai v-if="form.guowai"></guowai>
          <!-- <qiquan v-if="form.qiquan"></qiquan> -->
          <div v-if="form.touzi">
            
          
              <div class="tradetable">
                <p class="guowaip">◆ 机构的博易鑫管家</p>
                <p class="guowaip">◆ 机构的恒生资产管理系统</p>
                <p class="guowaip">◆ 机构的杰宜斯金融管理系统</p>
                <p class="guowaip">◆ 机构的金牛资产管理系统</p>
                <p class="guowaip">◆ 机构的金仕达资管交易系统</p>
                <p class="guowaip">◆ 机构的融航AMS资管平台</p>
                <p class="guowaip">◆ 机构的文华TMS交易管理系统</p>
                <p class="guowaip">◆ 机构的迅投投资交易系统</p>
                <p class="guowaip" style="margin-top:8px;">机构使用以上的技术系统，都支持对接。</p>
              </div>
             
           
          </div>
          <div v-if="form.gupiao">
            <div class="tradetable">
                <!-- <p class="guowaip">◆ 国投安信期货有限公司 </p> -->
                <p class="guowaip">◆ 金瑞期货股份有限公司 </p>
                <p class="guowaip" style="margin-top:8px;">以上期货公司支持股票期权交易</p>
              </div>
           
          </div>

        </div>
      </div>
      <div style="height: 4px"></div>
    </div>
    <wh8footer></wh8footer>
  </div>
</template>

<script>
import guonei from "./components/trade/guoneinew.vue";
import guowai from "./components/trade/guowai.vue";
// import qiquan from "./components/trade/qiquan.vue";
import wh8footer from "./components/wh8/wh8footer.vue";
export default {
  components: { guonei, guowai, 
  // qiquan,
   wh8footer },
  data() {
    return {
      form: {
        guonei: true,
        guowai: false,
        qiquan: false,
        touzi: false,
        gupiao:false
      },
      class1: "clickchange1",
      class2: "nochange1",
      class3: "clickchange2",
      class4: "nochange2",
      class5: "clickchange3",
      class6: "nochange3",
      class7: "kongxi",
      class8: "nokongxi",
      class9: "clickchange4",
      class10: "nochange4",
    };
  },
  methods: {
  
    change(name) {
      Object.keys(this.form).forEach((item) => {
        this.form[item] = false;
      });
      this.form[name] = true;
    },

  },
};
</script>

<style  scoped>
.tradehtml {
  min-width: 1400px;
  width: 100%;
  background-size: auto 1000px;
  min-height: 835px;
  z-index: 3;
  margin: 0 auto;
  /* background: #eef0ff; */
}
.tradeall {
  background: url("../../assets/images/jytd-top.png") no-repeat top;
  background-size: 100% auto;
  min-width: 1400px;
  width: 100%;
  padding-top: 110px;
}
.tradehead {
  position: relative;
  height: 160px;
  width: 1000px;
  margin: 0 auto;
}
.tradeheadtext {
  position: absolute;
  top: 100px;
}
.tradebody {
  width: 1008px;
  min-height: 725px;
  margin: 0 auto;
}
.tradebodyhead {
  width: 1008px;
  height: 50px;
  vertical-align: bottom;
}
.clickchange1 {
  background-color: #c4944c;
  padding-top: 5px;
  /* width: 249px; tab=4 */
  width: 333px;
  float: left;
}
.clickchange1 div {
  /* width: 249px; tab=4 */
  width: 333px;

  background: white;

  /* box-shadow: inset 0px 5px 0px#054c7e;
  -webkit-box-shadow: inset 0px 5px 0px#054c7e;
  -ms-box-shadow: inset 0px 5px 0px#054c7e;
  -moz--box-shadow: inset 0px 5px 0px#054c7e; */
  color: #0e4b7a;
  /* filter: drop-shadow(0px 4px 2px #b9b9b8); */
  cursor: pointer;
}
.nochange1 {
  background-color: transparent;
  padding-top: 5px;
  /* width: 249px; tab=4 */
  width: 333px;
  float: left;
}
.nochange1 div {
  /* width: 249px; tab=4 */
  width: 333px;

  background: #354e86;
  filter: alpha(opacity=350);
  -moz-opacity: 3.5;
  -khtml-opacity: 3.5;
  opacity: 3.5;
  color: #e3f9ff;
  cursor: pointer;
}
.nothing {
  padding-top: 5px;
  float: left;
}
.tradebodybody {
  margin-top: 5px;
  width: 1008px;
  min-height: 700px;

  /* filter: drop-shadow(0px 4px 2px #b9b9b8); */
  padding-top: 45px;
  background: white;
}

.clickchange2 {
  background-color: #c4944c;
  padding-top: 5px;
  /* width: 249px; tab=4 */
  width: 333px;
  float: left;
}
.clickchange2 div {
  /* width: 249px; tab=4 */
  width: 333px;

  background: white;
  /* box-shadow: inset 0px 5px 0px#054c7e;
  -webkit-box-shadow: inset 0px 5px 0px#054c7e;
  -ms-box-shadow: inset 0px 5px 0px#054c7e;
  -moz--box-shadow: inset 0px 5px 0px#054c7e; */
  color: #0e4b7a;
  /* filter: drop-shadow(0px 4px 2px #b9b9b8); */
  cursor: pointer;
}

.clickchange4 {
  background-color: #c4944c;
  padding-top: 5px;
  /* width: 249px; tab=4 */
  width: 333px;
  float: left;
}
.clickchange4 div {
  /* width: 249px; tab=4 */
  width: 333px;

  background: white;
  /* box-shadow: inset 0px 5px 0px#054c7e;
  -webkit-box-shadow: inset 0px 5px 0px#054c7e;
  -ms-box-shadow: inset 0px 5px 0px#054c7e;
  -moz--box-shadow: inset 0px 5px 0px#054c7e; */
  color: #0e4b7a;
  /* filter: drop-shadow(0px 4px 2px #b9b9b8); */
  cursor: pointer;
}
.nochange2 {
  background-color: transparent;
  padding-top: 5px;
  /* width: 249px; tab=4 */
  width: 333px;
  float: left;
}
.nochange2 div {
  /* width: 249px; tab=4 */
  width: 333px;

  background: #354e86;
  filter: alpha(opacity=350);
  -moz-opacity: 3.5;
  -khtml-opacity: 3.5;
  opacity: 3.5;
  color: #e3f9ff;
  cursor: pointer;
}
.clickchange3 {
  background-color: #c4944c;
  padding-top: 5px;
  /* width: 249px; tab=4 */
  width: 333px;
  float: left;
}
.clickchange3 div {
  /* width: 249px; tab=4 */
  width: 334px;
  background: white;
  /* box-shadow: inset 0px 5px 0px#054c7e;
  -webkit-box-shadow: inset 0px 5px 0px#054c7e;
  -ms-box-shadow: inset 0px 5px 0px#054c7e;
  -moz--box-shadow: inset 0px 5px 0px#054c7e; */
  color: #0e4b7a;
  /* filter: drop-shadow(0px 4px 2px #b9b9b8); */
  cursor: pointer;
}
.nochange3 {
  background-color: transparent;
  padding-top: 5px;
  /* width: 249px; tab=4 */
  width: 333px;
  float: left;
}
.nochange3 div {
  /* width: 249px; tab=4 */
  width: 334px;

  background: #354e86;
  filter: alpha(opacity=350);
  -moz-opacity: 3.5;
  -khtml-opacity: 3.5;
  opacity: 3.5;
  color: #e3f9ff;
  cursor: pointer;
}

.nochange4 {
  background-color: transparent;
  padding-top: 5px;
  /* width: 249px; tab=4 */
  width: 333px;
  float: left;
}
.nochange4 div {
  /* width: 249px; tab=4 */
  width: 333px;

  background: #354e86;
  filter: alpha(opacity=350);
  -moz-opacity: 3.5;
  -khtml-opacity: 3.5;
  opacity: 3.5;
  color: #e3f9ff;
  cursor: pointer;
}
.kongxi {
  background: transparent;
  float: left;
}
.nokongxi {
  background: transparent;
  float: left;
}
.guowaip{
      height: 30px;
    line-height: 30px;
    letter-spacing: 1px;
    /* margin-top: 15px; */
}
.guowaip:first-child{
  margin-top: 0;
}
.tradetable{
  width: 580px;
    margin: 0 auto;
}
</style>