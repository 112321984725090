<template>
  <div>
    <div class="successhtml">
      <div class="successbody">
        <div class="successbodytext">
          <div class="successleft">
            <div class="martop">成功案例</div>
            <div
              v-for="(item, index) in form"
              :key="index"
              @click="choosearea(index)"
              v-bind:class="[item ? class1 : class2]"
            >
              <div>
                {{ formn[index] }}
              </div>
              <!-- <div v-bind:class="[item ? class3 : class4]"></div> -->
            </div>
          </div>
          <div class="successright">
            <div class="righttext">
              <!-- <div v-for="(item,index) in form" :key="index"  :class="{disp:item==false}"> -->
              <div>
                <table style="margin: 0 auto; border: 1px solid #c9d8f7">
                  <tr>
                    <td
                      style="
                        width: 620px;
                        background: #e2e8f4;
                        text-align: center;

                        padding-left: 0px;
                      "
                    >
                      公司名称
                    </td>
                  </tr>
                  <tbody>
                    <tr
                      v-for="(item1, index1) in formpage"
                      :key="index1"
                      @click="goto(item1.website)"
                    >
                      <td :class="{ active: item1.website != '' }">
                        {{ item1.companyname }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="successcomfoot">
                <div
                  v-for="(it, id) in yeshu"
                  v-bind:key="id"
                  @click="choosepage(id + 1, area)"
                  class="pagebutton"
                  :class="{ pageclickbutton: yeshu[id] == true }"
                >
                  {{ id + 1 }}
                </div>
                共{{ page }}页，{{ tiao }}条
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <wh8footer></wh8footer>
  </div>
</template>

<script>
import wh8footer from "./components/wh8/wh8footer.vue";
import axios from "axios";
export default {
  data() {
    return {
      class1: "clicktext",
      class2: "lefttext",
      form: {
        bj: true,
        sh: false,
        gd: false,
        zj: false,
        others: false,
      },
      formn: {
        bj: "北京",
        sh: "上海",
        gd: "广东",
        zj: "浙江",
        others: "其他地区",
      },
      class3: "showsanjiao",
      class4: "nosanjiao",
      tiao: "",
      page: "",
      yeshu: "",
      formtextall: {
        bj: [
          
        ],
        sh: [],
        gd: [],
        zj: [],
        others: [],
      },
      formtext: {
        bj: [],
        sh: [],
        gd: [],
        zj: [],
        others: [],
      },
      formpage: "",
      youxi: "",
      area: "bj",
    };
  },
  components: { wh8footer },

  methods: {
    choosearea(idx) {
      Object.keys(this.form).forEach((item) => {
        this.form[item] = false;
      });

      this.yeshu = [];
      this.area = idx;

      this.form[idx] = true;

      this.tiao = this.formtextall[idx].length;
      this.page = Math.ceil(this.formtextall[idx].length / 11);

      for (var it = 0; it < this.page; it++) {
        this.$set(this.yeshu, it, false);
      }

      this.choosepage(1, idx);
    },
    choosepage(p, idx) {
      this.formtext = {
        bj: [],
        sh: [],
        gd: [],
        zj: [],
        others: [],
      };
      for (var it = 0; it < this.page; it++) {
        this.$set(this.yeshu, it, false);
      }
      this.yeshu[p - 1] = true;

      if (this.formtextall[idx].length < p * 11) {
        for (var x = (p - 1) * 11; x < this.formtextall[idx].length; x++) {
          this.$set(
            this.formtext[idx],
            x - (p - 1) * 11,
            this.formtextall[idx][x]
          );
        }
      } else {
        for (var y = (p - 1) * 11; y < p * 11; y++) {
          this.$set(
            this.formtext[idx],
            y - (p - 1) * 11,
            this.formtextall[idx][y]
          );
        }
      }

      this.formpage = this.formtext[idx];
    },
    goto(newpage) {
      if (newpage != "") {
        window.open(newpage, "_blank");
      }
    },
  },
  mounted() {
    axios
      .get("/api/case.asp")
      .then((res) => {
        // console.log(res);
        res.data.forEach((item)=>{
          if(item.psort == 1){
              this.formtextall.bj.push(item)
          }else if(item.psort == 2){
              this.formtextall.sh.push(item)
          }else if(item.psort == 3){
              this.formtextall.gd.push(item)
          }else if(item.psort == 4){
              this.formtextall.zj.push(item)
          }else if(item.psort == 5){
              this.formtextall.others.push(item)
          }
        })
        // this.formtextall = res.data;
        this.choosearea("bj");

      })
      .catch((err) => {
        console.log(err);
      });

    // this.formtextall = {
    //   bj: [
    //     {
    //       companyname: "北京路亚投资管理有限公司",
    //       csort: 0,
    //       id: 1786,
    //       prov: "北京",
    //       psort: 1,
    //       website: "",
    //     },
    //     {
    //       companyname: "北京美枫阁投资管理有限公司",
    //       csort: 0,
    //       id: 1786,
    //       prov: "北京",
    //       psort: 1,
    //       website: "",
    //     },
    //     {
    //       companyname: "北京木叶山资产管理有限公司",
    //       csort: 0,
    //       id: 1786,
    //       prov: "北京",
    //       psort: 1,
    //       website: "",
    //     },
    //     {
    //       companyname: "北京诚亿家资产管理有限公司",
    //       csort: 0,
    //       id: 1786,
    //       prov: "北京",
    //       psort: 1,
    //       website: "",
    //     },
    //     {
    //       companyname: "北京国钊资本管理有限公司",
    //       csort: 0,
    //       id: 1786,
    //       prov: "北京",
    //       psort: 1,
    //       website: "",
    //     },
    //     {
    //       companyname: "北京厚润资本管理有限公司",
    //       csort: 0,
    //       id: 1786,
    //       prov: "北京",
    //       psort: 1,
    //       website: "",
    //     },
    //     {
    //       companyname: "北京冲和资产管理有限公司",
    //       csort: 0,
    //       id: 1786,
    //       prov: "北京",
    //       psort: 1,
    //       website: "",
    //     },
    //     {
    //       companyname: "华夏桥水（北京）投资管理有限公司",
    //       csort: 0,
    //       id: 1786,
    //       prov: "北京",
    //       psort: 1,
    //       website: "",
    //     },
    //     {
    //       companyname: "中融子午（北京）资产管理有限公司",
    //       csort: 0,
    //       id: 1786,
    //       prov: "北京",
    //       psort: 1,
    //       website: "",
    //     },
    //     {
    //       companyname: "启辰金航资产管理（北京）有限公司",
    //       csort: 0,
    //       id: 1786,
    //       prov: "北京",
    //       psort: 1,
    //       website: "",
    //     },
    //   ],
    //   gd: [
    //     {
    //       companyname: "深圳市凡得基金管理有限公司",
    //       csort: 0,
    //       id: 1786,
    //       prov: "广东",
    //       psort: 3,
    //       website: "http://www.finderfunds.com/Company.php",
    //     },
    //     {
    //       companyname: "广州市大树投资管理有限公司",
    //       csort: 0,
    //       id: 1786,
    //       prov: "广东",
    //       psort: 3,
    //       website: "http://www.gzdashu.com/",
    //     },
    //     {
    //       companyname: "珠海市佳禾资产管理有限公司",
    //       csort: 0,
    //       id: 1786,
    //       prov: "广东",
    //       psort: 3,
    //       website: "http://www.jiahefund.cn/homepage/",
    //     },
    //     {
    //       companyname: "深圳和聚基金管理股份公司",
    //       csort: 0,
    //       id: 1786,
    //       prov: "广东",
    //       psort: 3,
    //       website: "http://www.jiahefund.cn/homepage/",
    //     },
    //     {
    //       companyname: "深圳市鼎达资产管理有限责任公司",
    //       csort: 0,
    //       id: 1786,
    //       prov: "广东",
    //       psort: 3,
    //       website: "",
    //     },
    //     {
    //       companyname: "新余中鼎创富投资管理中心(有限合伙)",
    //       csort: 0,
    //       id: 1786,
    //       prov: "广东",
    //       psort: 3,
    //       website: "",
    //     },
    //     {
    //       companyname: "深圳市复和资产管理有限公司",
    //       csort: 0,
    //       id: 1786,
    //       prov: "广东",
    //       psort: 3,
    //       website: "",
    //     },
    //     {
    //       companyname: "深圳市前海久银投资基金管理有限公司",
    //       csort: 0,
    //       id: 1786,
    //       prov: "广东",
    //       psort: 3,
    //       website: "",
    //     },
    //     {
    //       companyname: "深圳市磐乾投资管理有限公司",
    //       csort: 0,
    //       id: 1786,
    //       prov: "广东",
    //       psort: 3,
    //       website: "",
    //     },
    //     {
    //       companyname: "五洲行基金管理（深圳）有限公司",
    //       csort: 0,
    //       id: 1786,
    //       prov: "广东",
    //       psort: 3,
    //       website: "",
    //     },
    //     {
    //       companyname: "广东耘阳证券投资基金管理有限公司",
    //       csort: 0,
    //       id: 1786,
    //       prov: "广东",
    //       psort: 3,
    //       website: "",
    //     },
    //     {
    //       companyname: "深圳前海尊宏伟业资产管理有限公司",
    //       csort: 0,
    //       id: 1786,
    //       prov: "广东",
    //       psort: 3,
    //       website: "",
    //     },
    //     {
    //       companyname: "深圳市泰润海吉资产管理有限公司",
    //       csort: 0,
    //       id: 1786,
    //       prov: "广东",
    //       psort: 3,
    //       website: "",
    //     },
    //     {
    //       companyname: "广东杉谷证券投资基金管理有限公司",
    //       csort: 0,
    //       id: 1786,
    //       prov: "广东",
    //       psort: 3,
    //       website: "",
    //     },
    //     {
    //       companyname: "广东动量资本管理有限公司",
    //       csort: 0,
    //       id: 1786,
    //       prov: "广东",
    //       psort: 3,
    //       website: "",
    //     },
    //     {
    //       companyname: "广州市德银投资管理有限公司",
    //       csort: 0,
    //       id: 1786,
    //       prov: "广东",
    //       psort: 3,
    //       website: "",
    //     },
    //     {
    //       companyname: "广州宣牛资产管理有限公司",
    //       csort: 0,
    //       id: 1786,
    //       prov: "广东",
    //       psort: 3,
    //       website: "",
    //     },
    //     {
    //       companyname: "广州广金私募证券投资管理有限公司",
    //       csort: 0,
    //       id: 1786,
    //       prov: "广东",
    //       psort: 3,
    //       website: "",
    //     },
    //     {
    //       companyname: "深圳易承资本管理有限公司",
    //       csort: 0,
    //       id: 1786,
    //       prov: "广东",
    //       psort: 3,
    //       website: "",
    //     },
    //     {
    //       companyname: "广州璞一投资管理有限公司",
    //       csort: 0,
    //       id: 1786,
    //       prov: "广东",
    //       psort: 3,
    //       website: "",
    //     },
    //     {
    //       companyname: "深圳市前海尊柏资产管理有限公司",
    //       csort: 0,
    //       id: 1786,
    //       prov: "广东",
    //       psort: 3,
    //       website: "",
    //     },
    //     {
    //       companyname: "珠海市广恒盛基金管理企业（有限合伙）",
    //       csort: 0,
    //       id: 1786,
    //       prov: "广东",
    //       psort: 3,
    //       website: "",
    //     },
    //     {
    //       companyname: "深圳基成资本管理有限公司",
    //       csort: 0,
    //       id: 1786,
    //       prov: "广东",
    //       psort: 3,
    //       website: "",
    //     },
    //     {
    //       companyname: "定鼎（深圳）资本管理有限公司",
    //       csort: 0,
    //       id: 1786,
    //       prov: "广东",
    //       psort: 3,
    //       website: "",
    //     },
    //     {
    //       companyname: "未来（深圳）投资管理有限公司",
    //       csort: 0,
    //       id: 1786,
    //       prov: "广东",
    //       psort: 3,
    //       website: "",
    //     },
    //     {
    //       companyname: "珠海策略狮资产管理有限公司",
    //       csort: 0,
    //       id: 1786,
    //       prov: "广东",
    //       psort: 3,
    //       website: "",
    //     },
    //     {
    //       companyname: "深圳前海壹财基金管理有限公司",
    //       csort: 0,
    //       id: 1786,
    //       prov: "广东",
    //       psort: 3,
    //       website: "",
    //     },
    //     {
    //       companyname: "广州晟博资产管理有限公司",
    //       csort: 0,
    //       id: 1786,
    //       prov: "广东",
    //       psort: 3,
    //       website: "",
    //     },
    //     {
    //       companyname: "广州南沙区老虎资本管理合伙企业（有限合伙）",
    //       csort: 0,
    //       id: 1786,
    //       prov: "广东",
    //       psort: 3,
    //       website: "",
    //     },
    //     {
    //       companyname: "深圳君道量化基金管理有限公司",
    //       csort: 0,
    //       id: 1786,
    //       prov: "广东",
    //       psort: 3,
    //       website: "",
    //     },
    //   ],
    //   others: [
    //     {
    //       companyname: "大成基金管理有限公司",
    //       csort: 0,
    //       id: 1800,
    //       prov: "其他地区",
    //       psort: 5,
    //       website: "http://www.dcfund.com.cn/dcjj/index.jhtml",
    //     },
    //     {
    //       companyname: "山东融升私募基金管理有限公司",
    //       csort: 0,
    //       id: 1800,
    //       prov: "其他地区",
    //       psort: 5,
    //       website: "",
    //     },
    //     {
    //       companyname: "张家界旺金资产管理有限公司",
    //       csort: 0,
    //       id: 1800,
    //       prov: "其他地区",
    //       psort: 5,
    //       website: "",
    //     },
    //     {
    //       companyname: "乐盈资本管理（横琴）有限公司",
    //       csort: 0,
    //       id: 1800,
    //       prov: "其他地区",
    //       psort: 5,
    //       website: "",
    //     },
    //     {
    //       companyname: "梅州市鑫海达资产管理有限公司",
    //       csort: 0,
    //       id: 1800,
    //       prov: "其他地区",
    //       psort: 5,
    //       website: "",
    //     },
    //     {
    //       companyname: "莆田市点石投资管理有限公司",
    //       csort: 0,
    //       id: 1800,
    //       prov: "其他地区",
    //       psort: 5,
    //       website: "",
    //     },
    //     {
    //       companyname: "大连涌顺投资管理有限公司",
    //       csort: 0,
    //       id: 1800,
    //       prov: "其他地区",
    //       psort: 5,
    //       website: "",
    //     },
    //     {
    //       companyname: "济南春晖资产管理有限公司",
    //       csort: 0,
    //       id: 1800,
    //       prov: "其他地区",
    //       psort: 5,
    //       website: "",
    //     },
    //     {
    //       companyname: "青岛易持资产管理有限公司",
    //       csort: 0,
    //       id: 1800,
    //       prov: "其他地区",
    //       psort: 5,
    //       website: "",
    //     },
    //     {
    //       companyname: "成都赚呗投资管理有限公司",
    //       csort: 0,
    //       id: 1800,
    //       prov: "其他地区",
    //       psort: 5,
    //       website: "",
    //     },
    //     {
    //       companyname: "辽宁胜握资产管理有限公司",
    //       csort: 0,
    //       id: 1800,
    //       prov: "其他地区",
    //       psort: 5,
    //       website: "",
    //     },
    //     {
    //       companyname: "安徽金研投资管理合伙企业（有限合伙）",
    //       csort: 0,
    //       id: 1800,
    //       prov: "其他地区",
    //       psort: 5,
    //       website: "",
    //     },
    //     {
    //       companyname: "厦门宁水投资管理有限公司",
    //       csort: 0,
    //       id: 1800,
    //       prov: "其他地区",
    //       psort: 5,
    //       website: "",
    //     },
    //     {
    //       companyname: "泽鑫资产管理（山东）有限公司",
    //       csort: 0,
    //       id: 1800,
    //       prov: "其他地区",
    //       psort: 5,
    //       website: "",
    //     },
    //     {
    //       companyname: "孤岩量化基金管理（青岛）有限公司",
    //       csort: 0,
    //       id: 1800,
    //       prov: "其他地区",
    //       psort: 5,
    //       website: "",
    //     },
    //     {
    //       companyname: "厦门玖巴资本管理有限公司",
    //       csort: 0,
    //       id: 1800,
    //       prov: "其他地区",
    //       psort: 5,
    //       website: "",
    //     },
    //     {
    //       companyname: "成都大浦投资管理有限公司",
    //       csort: 0,
    //       id: 1800,
    //       prov: "其他地区",
    //       psort: 5,
    //       website: "",
    //     },
    //     {
    //       companyname: "南京鑫臣达资产管理有限公司",
    //       csort: 0,
    //       id: 1800,
    //       prov: "其他地区",
    //       psort: 5,
    //       website: "",
    //     },
    //     {
    //       companyname: "天津三奇投资管理有限责任公司",
    //       csort: 0,
    //       id: 1800,
    //       prov: "其他地区",
    //       psort: 5,
    //       website: "",
    //     },
    //     {
    //       companyname: "华夏桥水（青岛）私募基金管理有限公司",
    //       csort: 0,
    //       id: 1800,
    //       prov: "其他地区",
    //       psort: 5,
    //       website: "",
    //     },
    //   ],
    //   sh: [
    //     {
    //       companyname: "上海与取投资管理有限公司",
    //       csort: 0,
    //       id: 1787,
    //       prov: "上海",
    //       psort: 2,
    //       website: "http://www.yuqucapital.com/website/w/h",
    //     },
    //     {
    //       companyname: "上海银箭资产管理有限公司",
    //       csort: 0,
    //       id: 1787,
    //       prov: "上海",
    //       psort: 2,
    //       website: "",
    //     },
    //     {
    //       companyname: "上海裕灏投资管理有限公司",
    //       csort: 0,
    //       id: 1787,
    //       prov: "上海",
    //       psort: 2,
    //       website: "",
    //     },
    //     {
    //       companyname: "固利资产管理（上海）有限公司",
    //       csort: 0,
    //       id: 1787,
    //       prov: "上海",
    //       psort: 2,
    //       website: "",
    //     },
    //     {
    //       companyname: "上海奕旻投资管理有限公司",
    //       csort: 0,
    //       id: 1787,
    //       prov: "上海",
    //       psort: 2,
    //       website: "",
    //     },
    //     {
    //       companyname: "上海熙赢投资管理有限公司",
    //       csort: 0,
    //       id: 1787,
    //       prov: "上海",
    //       psort: 2,
    //       website: "",
    //     },
    //     {
    //       companyname: "上海米答资产管理有限公司",
    //       csort: 0,
    //       id: 1787,
    //       prov: "上海",
    //       psort: 2,
    //       website: "",
    //     },
    //     {
    //       companyname: "上海星楼投资管理有限公司",
    //       csort: 0,
    //       id: 1787,
    //       prov: "上海",
    //       psort: 2,
    //       website: "",
    //     },
    //     {
    //       companyname: "上海由势投资管理有限公司",
    //       csort: 0,
    //       id: 1787,
    //       prov: "上海",
    //       psort: 2,
    //       website: "",
    //     },
    //     {
    //       companyname: "朴见投资管理（上海）有限公司",
    //       csort: 0,
    //       id: 1787,
    //       prov: "上海",
    //       psort: 2,
    //       website: "",
    //     },
    //     {
    //       companyname: "上海聊塑资产管理中心（有限合伙）",
    //       csort: 0,
    //       id: 1787,
    //       prov: "上海",
    //       psort: 2,
    //       website: "",
    //     },
    //     {
    //       companyname: "上海启览资产管理有限公司",
    //       csort: 0,
    //       id: 1787,
    //       prov: "上海",
    //       psort: 2,
    //       website: "",
    //     },
    //   ],
    //   zj: [
    //     {
    //       companyname: "杭州珩景资产管理有限公司",
    //       csort: 0,
    //       id: 1797,
    //       prov: "浙江",
    //       psort: 4,
    //       website: "",
    //     },
    //     {
    //       companyname: "杭州光隐资产管理有限公司",
    //       csort: 0,
    //       id: 1797,
    //       prov: "浙江",
    //       psort: 4,
    //       website: "",
    //     },
    //     {
    //       companyname: "义乌市核航投资管理有限公司",
    //       csort: 0,
    //       id: 1797,
    //       prov: "浙江",
    //       psort: 4,
    //       website: "",
    //     },
    //     {
    //       companyname: "宁波明盛资产管理有限公司",
    //       csort: 0,
    //       id: 1797,
    //       prov: "浙江",
    //       psort: 4,
    //       website: "",
    //     },
    //     {
    //       companyname: "浙江时中资本管理有限公司",
    //       csort: 0,
    //       id: 1797,
    //       prov: "浙江",
    //       psort: 4,
    //       website: "",
    //     },
    //     {
    //       companyname: "杭州德舟投资管理有限公司",
    //       csort: 0,
    //       id: 1797,
    //       prov: "浙江",
    //       psort: 4,
    //       website: "",
    //     },
    //     {
    //       companyname: "杭州国睿投资管理有限公司",
    //       csort: 0,
    //       id: 1797,
    //       prov: "浙江",
    //       psort: 4,
    //       website: "",
    //     },
    //     {
    //       companyname: "杭州锦海投资管理有限公司",
    //       csort: 0,
    //       id: 1797,
    //       prov: "浙江",
    //       psort: 4,
    //       website: "",
    //     },
    //     {
    //       companyname: "浙江期成投资管理有限公司",
    //       csort: 0,
    //       id: 1797,
    //       prov: "浙江",
    //       psort: 4,
    //       website: "",
    //     },
    //     {
    //       companyname: "杭州高恺资产管理有限公司",
    //       csort: 0,
    //       id: 1797,
    //       prov: "浙江",
    //       psort: 4,
    //       website: "",
    //     },
    //     {
    //       companyname: "盈方资产管理（杭州）有限公司",
    //       csort: 0,
    //       id: 1797,
    //       prov: "浙江",
    //       psort: 4,
    //       website: "",
    //     },
    //   ],
    // };
    // this.choosearea("bj");
  },
};
</script>

<style scoped>
.success{
  position: absolute;
    top: 73px;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    flex-direction: column;
}
td.active {
  cursor: pointer;
}
td.active:hover {
  color: blue;
}
.successcomfoot {
  width: 620px;
  margin-left: 74px;
  height: 40px;
  margin-top: 20px;
  text-align: right;
}
.pagebutton {
  display: inline-block;
  height: 25px;
  width: 25px;
  margin-right: 15px;
  border: 1px solid #dfdfdf;
  text-align: center;
  line-height: 25px;
  cursor: pointer;
  color: #adadad;
  user-select: none;
}
.pageclickbutton {
  display: inline-block;
  height: 25px;
  width: 25px;
  margin-right: 15px;
  border: 1px solid #dfdfdf;
  color: white;
  text-align: center;
  line-height: 25px;
  background: #22427d;
  cursor: pointer;
  user-select: none;
}
.martop {
  /* margin-top: 60px; */
  color: #fff;
  font-weight: 700;
  padding-left: 30px;
  height: 60px;
}
.disp {
  display: none;
}
.successhtml {
  /* margin-top: 20px; */
  background: url("../../assets/images/cgal-top.png") no-repeat top;
  background-size: 100% auto;
  /* height: 660px; */
  min-width: 1400px;
  width: 100%;
  padding-top: 110px;
  flex-grow:1;

}
.successhead {
  position: relative;
  height: 140px;
  width: 1000px;
  margin: 0 auto;
}
.successheadtext {
  position: absolute;
  top: 100px;
}
.successbody {
  width: 1200px;
  margin: 0 auto;
  height: 100%;
  /* height: 635px; */
}
.successbodytext {
  width: 1010px;
  margin: 0 auto;
  height: 100%;
  /* height: 635px; */
  position: relative;
  min-height: 650px;
}
.successleft {
  /* float: left; */
  padding-top: 60px;
  width: 250px;
  background: url("../../assets/images/cgal.png") no-repeat top;
  background-size: 100% auto;
  /* height: 635px; */
  height: 345px;
}
.successright {
  /* float: left; */
  position: absolute;
  width: 760px;
  background: #ebf2fc;
  /* background-size: 100% 100%; */
  /* min-height: 5px; */
  margin-left: -45px;
  margin-top: 30px;
  border-radius: 10px 0 0 0;
  box-shadow: -2px 0px rgb(65 97 153 / 10%);
  top: 5px;
  left: 250px;
  bottom: 0;
}
.lefttext {
  font-size: 14px;
  line-height: 40px;
  position: relative;
  z-index: 99;
  /* margin-top: 15px; */
  letter-spacing: 1px;
  color: #1a2747;
  /* text-align: center; */
  text-indent: 2em;
  /* border-bottom: 1px dashed #a4acbf; */
  cursor: pointer;
  font-weight: bold;
  padding-left: 20px;
}
.clicktext {
  position: relative;
  z-index: 99;
  font-size: 14px;
  line-height: 40px;
  background: #ebf2fc;
  letter-spacing: 1px;
  color: #1a2747;
  border-left: 5px solid #d6b65b;
  /* margin-left: -3px; */
  text-indent: 2em;
  /* border-bottom: 1px dashed #a4acbf; */
  cursor: pointer;
  font-weight: bold;
  padding-left: 15px;
}
.clicktext div,
.lefttext div {
  width: 160px;
  border-bottom: 1px dashed #a4acbf;
}
.lefttext:hover {
  color: #e3a200;
}
.showsanjiao {
  width: 250px;
  height: 10px;
  background: url("../../assets/images/cgal.png") no-repeat left;
}
.nosanjiao {
  width: 250px;
  height: 10px;
}
.righttext {
  margin-top: 60px;
}
td {
  height: 35px;
  border-bottom: 1px solid #eeeeee;
  line-height: 35px;
  vertical-align: middle;
  font-size: 14px;
  padding-left: 40px;
  letter-spacing: 1px;
  color: #323232;
}
tbody tr:nth-child(odd) {
  background: #fff;
}
tbody tr:nth-child(even) {
  background: #f5f8ff;
}
tr:last-child td {
  border: none;
}
</style>