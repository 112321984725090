<template>
  <div>
    <div class="productcenterhtml">
      <div class="productcenterbody">
        <!-- <div class="productcenterbodytag">
          <ul style="padding-top: 70px">
            <span class="title">关于量化交易</span>
            <li class="pli">
              <div v-bind:class="[form[0] ? class4 : class3]"></div>
              <div
                v-bind:class="[form[0] ? class1 : class5]"
                style="line-height: 20px"
                @click="clickLi(0)"
              >
                <div style="width: 26px; height: 40px; float: left"></div>
                <div class="tagmid">关于麦语言</div>
                <div style="width: 30px; height: 40px; float: left"></div>
              </div>
              <div
                style="width: 9px; height: 7px; float: left; ine-height: 7px"
              ></div>
            </li>
            <li class="pli">
              <div v-bind:class="[form[1] ? class4 : class3]"></div>
              <div
                v-bind:class="[form[1] ? class1 : class2]"
                @click="clickLi(1)"
              >
                <div style="width: 26px; height: 40px; float: left"></div>
                <div class="tagmid">量化交易概念</div>
                <div style="width: 30px; height: 40px; float: left"></div>
              </div>
            </li>
            <li class="pli">
              <div v-bind:class="[form[2] ? class4 : class3]"></div>
              <div
                v-bind:class="[form[2] ? class1 : class2]"
                @click="clickLi(2)"
              >
                <div style="width: 26px; height: 40px; float: left"></div>
                <div class="tagmid">量化交易起源</div>
                <div style="width: 30px; height: 40px; float: left"></div>
              </div>
            </li>
            <li class="pli">
              <div v-bind:class="[form[3] ? class4 : class3]"></div>
              <div
                v-bind:class="[form[3] ? class1 : class2]"
                @click="clickLi(3)"
              >
                <div style="width: 26px; height: 40px; float: left"></div>
                <div class="tagmid">量化交易在中国</div>
                <div style="width: 30px; height: 40px; float: left"></div>
              </div>
            </li>
            <li class="pli">
              <div v-bind:class="[form[4] ? class4 : class3]"></div>
              <div
                v-bind:class="[form[4] ? class1 : class2]"
                @click="clickLi(4)"
              >
                <div style="width: 26px; height: 40px; float: left"></div>
                <div class="tagmid">经典文章</div>
                <div style="width: 30px; height: 40px; float: left"></div>
              </div>
            </li>
          </ul>
        </div> -->
        <div class="productcenterbodytext" ref="box">
          <div
            style="            
              border-radius: 2%;
              position: relative;
            "
          >
            <div class="midtext">
              <div v-if="id == 1">
                <div class="head4" id="about0">麦语言的历史</div>
                <p class="pproduct" style="margin-top:12px">
                  System trade的思想，在2002年前后自海外传入我国，当时被称作系统交易，台湾地区称之为程式交易，即用一个公式去做交易。这给文华人带来了启示，觉得这就是我们未来的方向。
                </p>
                <div>
                  <div class="leftP">
                    <p class="pproduct">
                     卧薪尝胆再造，终于在2004年文华自主研发推出了国内第一套程序化交易函数库（麦语言前身），文华当时将其命名为“程序化交易系统”，从此网络上有了程序化交易这个词条，中国有了程序化交易软件，有了程序化交易者。用户可以用文华提供的各种函数和指令编写交易模型。模型可以测试收益率、胜率等指标，还可以接入到期货的交易系统，实现自动下单。
                    </p>
                  </div>
                  <div class="rightP" style="position:relative;top:-20px">
                    <img src="../../assets/images/2004.png" alt="" />
                  </div>
                </div>
                <p class="pproduct">
                  麦语言倡导的是积木式的编程理念，把复杂算法封装到一个个的函数里，采用"小语法，大函数"的构建模式，大幅提高编写效率，其他语言100多句的模型，用麦语言一般10几句就可以编出来。配合专门的量化交易数据结构，配合丰富的金融统计函数库，同样可以支持逻辑复杂的金融应用。
                </p>
                <p class="pproduct">
                  2005年开始，文华在全国巡回举办程序化交易培训班，推广麦语言，推广量化交易的理念。到2009年一共举办了24期培训班，帮助1000多名学员走上程序化交易的道路，他们是最早的接受程序化理念、具有远见的交易人，涌现出像朱淋靖、王建斌、李斌、谢磊等知名的程序化大咖。
                </p>
                <p class="pproduct">     
                  麦语言经过二十年的发展，吸收数十万用户的意见反馈，一点一点完善起来的，成长为一套成熟稳定、使用人数最多的模型开发编程语言之一。
                </p>
                <p class="pproduct">     
                  量化交易发展到今天，在理性投资、交易策略研究、模型创建、投资组合、风险管理等很多领域都发挥了重要作用，WT8用户的数千台麦语言量化终端，就是数千个电脑交易机器人，在全国各地的托管机房，自动发出指令，想象一下，这绝对是数字时代的一条靓丽的风景线。
                </p>
                <p class="pproduct">     
                  量化交易已经得到广泛的应用，机构在用，期货高手也在用。我们同一个楼的人，我们同一个办公室的人，我们身边的人都开始量化交易了。有的在用量化交易软件提供的历史数据和模型量化评估软件，对自己多年的交易思想进行完善再优化，提高盈利水平；有的在利用量化交易软件提供的编程语言和金融函数，建立了新的交易策略，重新起航；有的把模型装在10台电脑上，让电脑变成自己的工人，把交易室变成了利润车间......
                </p>
                <p class="pproduct">     
                  量化就是未来，未来一定会更好！
                </p>
                <!-- <p>
                  <a
                    class="ahref"
                    href="https://cxh.wenhua.com.cn/training.asp?pid=1"
                    target="_blank"
                    >&gt;&gt;&gt;了解“麦语言”模型编写培训班</a
                  >
                </p> -->
              </div>
              <div v-if="id == 2">
                <div class="head4" id="about1">有关的量化交易概念</div>
                <p id="about1" class="tit_p1">1、量化交易概念</p>
                <p class="pproduct" style="margin-top:12px">
                  量化交易是借助现代统计学和数学建模的方法，使用海量的历史数据进行回测研究，评估策略的优劣，找到盈利与风险的平衡点，并依靠计算机配置多元化的投资组合，以实现降低系统风险并克服人性弱点的作用，使投资决策更科学，更理性。
                </p>
                <div>
                  <div class="leftP">
                    <p class="pproduct">
                      量化交易是一个交易的概念，用户可以把平时的交易思想，写成交易策略模型，让电脑去执行这些交易思想，自动下单。利用电脑的计算能力和铁面无私，提高下单的速度和效率，避免交易受到情绪的影响，理性交易。
                    </p>
                  </div>
                  <div class="rightP">
                    <img src="../../assets/images/lianghua.png" alt="" />
                  </div>
                </div>
                <p class="pproduct">
                  量化交易也是一个研究的概念，量化交易软件都提供丰富的历史数据和收益/风险的多角度的模型评估算法，用户可以在电脑的仿真交易环境下，去测试、改进策略模型，来促进交易思想快速成熟，节省动辄几个月甚至几年的实盘验证期，节省实盘需要的真金白银成本。利用电脑的历史数据存储能力，能节省时间，节省金钱。
                </p>
                <div class="head3 yid" style="width: 430px">
                  <span style="font-size: 15px">一段高手</span
                  >&nbsp;指标量化，用电脑成功突破了情绪对交易的影响
                </div>
                <p class="pproduct">代表性观点：量化不就是按指标操作嘛！</p>
                <p class="pproduct">
                  一段高手具有编写公式的能力，能把技术指标改写为自动下单模型，让电脑来下单，让交易更有纪律，更加一致，成功避免了情绪化的影响。
                </p>
                <p class="pproduct">
                  对于一段高手来说，电脑的作用是执行，执行的是多年的实盘总结出的策略。
                </p>
                <div class="head3 erd" style="width: 320px">
                  <span style="font-size: 15px">二段高手</span
                  >&nbsp;策略生产，用电脑生产出新策略
                </div>
                <p class="pproduct">代表性观点：量化不就是回测嘛！</p>
                <p class="pproduct">
                  二段高手把自己的的灵感和新想法写成模型，利用大量的历史数据样本，对模型进行回测，验证想法的有效性，测出模型的盈利性。然后把模型在模拟交易上运行，进一步验证模型的盈利性，对照模拟交易的结果，对模型进行进一步完善，最后模型拿到实盘去运行。
                </p>
                <p class="pproduct">
                  对于二段高手来说，电脑的作用不仅是执行，更是生产策略的机器。
                </p>
                <div class="head3 sand" style="width: 430px">
                  <span style="font-size: 15px">三段高手</span
                  >&nbsp;资金管理，通过模型组合实现更稳定的盈利水平
                </div>
                <p class="pproduct">代表性观点：量化不就是资金管理嘛！</p>
                <p class="pproduct">
                  三段高手拥有自己的模型库，在模组里对一个合约加载不同特性的模型，有针对震荡行情的，有针对趋势行情的，加载的k线周期也不一样。每一个模型分配一定量的资金，每一个模型在自己的资金池里运行，资金回撤到一个阀值定会自动进行平仓或清盘等风控操作。
                </p>
                <p class="pproduct">对于三段高手来说，电脑更是风控的助手。</p>
                <div class="head3 sid" style="width: 400px">
                  <span style="font-size: 15px">四段高手</span
                  >&nbsp;修炼成精，能够用量化扑捉市场的各种机会
                </div>
                <p class="pproduct">代表性观点：量化就是捡钱包的机器。</p>
                <p class="pproduct">
                  四段高手具有盘口模型的编写能力，编写的模型能扑捉到盘口偶然出现的不合理性，利用不合理性来做套利、抢帽子等类型的交易。
                </p>
                <p class="pproduct">
                  对于四段高手来说，电脑更是扫描钱包的机器。
                </p>
                <!-- <div style="height:30px;"></div> -->
                <p id="about2" class="tit_p1">2、量化交易起源</p>
                <p class="pproduct" style="margin-top:12px">
                  量化交易最早是由组合交易发展而来。根据纽约证券交易所的定义，只要交易的股票数量超过15只，总资金超过1百万美元，就可以被当作是量化交易。当时，量化交易的主要目的是跟踪指数进行套利交易。由于指数权重股往往包括众多的股票，投资者很难利用人工方法对指数进行跟踪，因此利用计算机对一篮子股票进行操作的方式，成为了专业投资者的首选。这就是量化交易的雏形。
                </p>
                <p class="pproduct">
                  量化交易的发展，主要得益于计算机及网络技术的发展、报价方式的改变以及对冲基金的兴起三方面。
                </p>
                <div>
                  <div class="leftP">
                    <p class="pproduct">
                      计算机及网络技术的发展，为量化交易的发展提供了技术上的保证。由于计算机的运算速度越来越快，投资者可以对复杂的股票组合进行分析研究。而网络技术的发展，使得投资者可以在第一时间获得最新的行情信息进行分析，并执行买入卖出的操作。
                    </p>
                  </div>
                  <div class="rightP">
                    <img src="../../assets/images/yuany.png" alt="" />
                  </div>
                </div>
                <p class="pproduct">
                  报价方式的改变，则在客观上刺激了量化交易技术的发展。2000之前，美国的股票交易所采用的都是分数的报价方式，股票的最小变动单位是1/8或者1/16。即一只股票的价格上涨或者下跌的幅度，必须是1/8或者1/16的整数倍。2001年之后，股票报价方式全面改为小数报价，也就是说，股票的变动单位变为了1美分，或者0.01美元。报价方式的改变，大大地增加了股票价格的“琐碎”程度，股票组合的复杂性大大增加，只有计算机才能完成如此繁琐的分析。因此量化交易逐渐受到了投资者的青睐。
                </p>
                <p class="pproduct">
                  而最重要的因素，就是大量对冲基金的兴起。由于对冲基金往往使用高杠杆的金融工具进行操作，投资风险极大。市场上的投资机会稍纵即逝，采用传统的手工下单，可能会错失大量的操作良机。计算机由于其优异的运算性能，可以有效摒弃投资者的侥幸以及恐惧心理，成为代替人工操作的首选。
                </p>
                <p class="pproduct">
                  经过多年的发展，量化交易逐渐脱离了最初的组合投资的范畴，变成了依靠计算机根据事先设计好的交易模型，分析即时行情信息，下达买入卖出交易指令的操作模式。
                </p>
                <p class="pproduct">
                  和传统的定义相比，现在的量化交易不仅突破了当初的股票数量的限制，突破了资金量的研制，也突破了交易品种和交易模式的限制。如今，股票、期货、期权、债券，几乎所有的交易品种上都可以找到量化交易的影子，交易目的也从最早的指数套利，发展出了非指数套利、趋势交易、高频交易等多种模式。
                </p>
                <!-- <div style="height:30px;"></div> -->
              </div>
              <div v-if="id == 5">
                <div class="head4" id="about4" style="margin-bottom:15px">经典文章</div>
                <p class="tit_p">如何建立和使用自己的量化系统</p>
                <p class="pproduct">
                  量化投资始于1987年美国华尔街传奇人物——詹姆斯·西蒙斯。互联网的发展让国内投资者从2006年开始了量化自动交易的研究，2007年至2011年国内陆续出现可以量化交易的TB开拓者、金字塔、文华财经等平台。2011年6月以后，国内兴起量化投资，量化研究者增至数百万人，实盘使用者多以机构和大户为主。
                </p>
                <p class="p_right">&gt;&gt;
                  <a
                    class="a_right"
                    href="https://www.wenhua.com.cn/myy/wenzhang/22.htm"
                    target="_blank"
                    >更多内容</a
                  >
                </p>

                <p class="tit_p">浅谈量化交易系统搭建工程</p>
                <p class="pproduct">
                  在国际市场上，量化交易已经比较完善，而且是一个十分重要的交易方式。虽然量化交易在我国还只是处在萌芽阶段，但是随着国内资本市场的日趋成熟，随着投资者素质的不断提高，量化交易将逐步成为国内市场的主流交易方式之一。因此，建立起一套成熟稳定的量化交易系统，不仅是可行的，更是顺应行业发展、推动期货市场发挥自身应有的作用和功能所必需的。
                </p>
                <p class="p_right">&gt;&gt;
                  <a
                    class="a_right"
                    href="https://www.wenhua.com.cn/myy/wenzhang/21.htm"
                    target="_blank"
                    >更多内容</a
                  >
                </p>

                <p class="tit_p">选择适合自己的交易平台</p>
                <p class="pproduct">
                  目前，国内期货市场已有的量化交易平台超过20家，投资者应该选择怎样的平台开始自己的量化交易之路？记者带着这一问题，采访了多家量化交易商和投资者。
                </p>
                <p class="p_right">&gt;&gt;
                  <a
                    class="a_right"
                    href="https://www.wenhua.com.cn/myy/wenzhang/20.htm"
                    target="_blank"
                    >更多内容</a
                  >
                </p>

                <p class="tit_p">
                  聚焦国内量化交易：和者日众量化交易引领风潮——期货日报
                </p>
                <p class="pproduct">
                  编者按：在过去的两年多时间中，国内期货市场的参与者们不断对交易技术进行探索，量化交易的理念得到进一步推广，在实践上也取得了不错的战绩。期货日报记者近日通过走访国内量化交易的开发者、使用者和推广者，对当前国内量化交易的开发、应用和发展现状进行了一次较为全面的了解。
                </p>
                <p class="p_right">&gt;&gt;
                  <a
                    class="a_right"
                    href="https://www.wenhua.com.cn/myy/wenzhang/19.htm"
                    target="_blank"
                    >更多内容</a
                  >
                </p>

                <p class="tit_p">量化交易对市场的动态影响研究——上海证券报</p>
                <p class="pproduct">
                  多年来的探索和实践表明量化交易可有助于提高股票、期货等市场的运作效率，增加市场流动性，从而使期货的价格发现功能得到更加充分的发挥。
                </p>
                <p class="p_right">&gt;&gt;
                  <a
                    class="a_right"
                    href="https://www.wenhua.com.cn/myy/wenzhang/18.htm"
                    target="_blank"
                    >更多内容</a
                  >
                </p>

                <p class="tit_p">量化交易在国外期市的发展与现状——上海证券报</p>
                <p class="pproduct">
                  自电子交易系统出现以来，量化交易作为证券交易方式的一项重大改革与创新，已经被越来越多的交易者所接受和使用，发展势头相当迅猛。而对于我国期货市场来说，随着近年来市场结构和产品体系的快速成长，目前我国期货市场已经涵盖了股票指数、基本金属、贵金属、能源、化工和农产品等大部分大宗商品品种。
                </p>
                <p class="p_right">&gt;&gt;
                  <a
                    class="a_right"
                    href="https://www.wenhua.com.cn/myy/wenzhang/17.htm"
                    target="_blank"
                    >更多内容</a
                  >
                </p>

                <p class="tit_p">
                  三步骤实现期指量化交易应用——中国国际期货研究院 韩蕴喆
                </p>
                <p class="pproduct">
                  量化交易的概念最早产生于上世纪70年代的美国，在国际市场上的应用已经十分成熟。目前我国的量化交易主要应用在商品期货上。而随着股指期货的上市，期货市场和证券市场实现了真正意义上的互动，投资者不仅可以在期货市场上进行投机交易，同时可以在期货与股票之间进行套利交易。构建量化交易策略的方法多种多样，但至少应当包括模型设计、历史测算和实盘检验三个步骤。
                </p>
                <p class="p_right">&gt;&gt;
                  <a
                    class="a_right"
                    href="https://www.wenhua.com.cn/myy/wenzhang/1.htm"
                    target="_blank"
                    >更多内容</a
                  >
                </p>

                <!-- <p class="tit_p">
                  运用量化交易要先解决的问题——新湖期货有限公司 方志
                </p>
                <p class="pproduct">
                  最近期市火爆，波动幅度也加大，越来越多的客户注意到我们的量化交易系统。在本次上涨中的信号非常准确，能够回避掉中途的杂波与振荡。很多人认为，购买了一个好的交易系统，就可以高枕无忧，稳定赚钱了。
                </p>
                <p>
                  <a
                    class="a_right"
                    href="https://www.wenhua.com.cn/myy/wenzhang/2.htm"
                    target="_blank"
                    >&gt;&gt;更多内容</a
                  >
                </p> -->

                <p class="tit_p">
                  量化交易是期货交易者走出失败的“金钥匙”——上海中期公司量化交易研究小组组长
                  朱淋靖专访
                </p>
                <p class="pproduct">
                  在竞争残酷的期货市场，许多投资者渐渐的都会有一种交易越来越难做的感觉。包括不少期货市场久经沙场的老兵，都觉得自己不学习不提高就很难在这个市场立足。随着系统交易在中国的推广及发展，越来越多的人认识了系统交易，也对系统交易产生了浓厚的兴趣，但许多人对系统交易还有很多疑问，也存在不少认识上的误区。
                </p>
                <p class="p_right">&gt;&gt;
                  <a
                    class="a_right"
                    href="https://www.wenhua.com.cn/myy/wenzhang/3.htm"
                    target="_blank"
                    >更多内容</a
                  >
                </p>

                <p class="tit_p">
                  股指期货量化交易 ——光大期货研究所量化首席研究员 黄玉
                </p>
                <p class="pproduct">
                  黄玉：首先我们来看看量化是怎么定义的，因为平时大家都说量化，早期的定义是这样的，凡是任何含有500指数15只以上，股票或者100万美元，这样就是量化。按照模型的定义由计算机投资的行为这样一个模型就是计算机的系统，这也是我们现在要考虑的一个方面...
                </p>
                <p class="p_right">&gt;&gt;
                  <a
                    class="a_right"
                    href="https://www.wenhua.com.cn/myy/wenzhang/4.htm"
                    target="_blank"
                    >更多内容</a
                  >
                </p>

                <p class="tit_p">
                  量化交易在期指中的应用 ——平安期货有限公司 李建春 钱盛林
                </p>
                <p class="pproduct">
                  量化交易是指将交易策略的逻辑与参数通过计算机技术进行系统化。量化交易的理论根基也在于对价格行为的研究，它遵从技术分析的基本原理：价格行为反映一切，即认为价格已经和正在反映市场中的信息。因此，投资者可以通过大量的数据统计去寻找一些价格波动规律。通过计算机系统的运行来判断交易的买点与卖点，则可以完全根据交易信号进行交易，而不用关心所谓的基本面、消息面信息。
                </p>
                <p class="p_right">&gt;&gt;
                  <a
                    class="a_right"
                    href="https://www.wenhua.com.cn/myy/wenzhang/5.htm"
                    target="_blank"
                    >更多内容</a
                  >
                </p>

                <p class="tit_p">
                  利用量化交易系统软件来优化期货投资——北京中期量化研究小组成员
                  董元及
                </p>
                <p class="pproduct">
                  现在的金融交易大部分都是以电子化的形式产生。随着这么一个信息技术的发展，大时代已经进入了尾声，现在金融衍生品的交易进入新的交易时代。现在更多是在电脑上完成，越来越无声。我们看一下最近刚刚上映的《枪王之王》古天乐下单的情况。买入日元，他只要轻点一下鼠标，就可以使所有账户同时买入日元的合约。这就是我今天要讲的主题—量化交易。
                </p>
                <p class="p_right">&gt;&gt;
                  <a
                    class="a_right"
                    href="https://www.wenhua.com.cn/myy/wenzhang/6.htm"
                    target="_blank"
                    >更多内容</a
                  >
                </p>
                <!-- <div style="height:50px;"></div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <wh8footer></wh8footer>
  </div>
</template>

<script>
import wh8footer from "./components/wh8/wh8footer.vue";
import axios from "axios";
export default {
  data() {
    return {
      // form: [
      //   true,
      //   false,
      //   false,
      //   false,
      //   false,
      //   false,
      //   false,
      //   false,
      //   false,
      //   false,
      //   false,
      //   false,
      //   false,
      //   false,
      //   false,
      // ],
      id:1,
      class1: "onclick",
      class2: "noclick",
      class3: "touming",
      class4: "yellow",
      class5: "noclickone",
      class6: "noclickele",
      scroll: "",
      url:'',
    };
  },
  components: {
    wh8footer,
  },
  methods: {
    custormAnchor(anchorName) {
      // // 找到锚点
      // let anchorElement = document.getElementById(anchorName);
      // // 如果对应id的锚点存在，就跳转到锚点
      // if(anchorElement) { anchorElement.scrollIntoView(); }
      // var target = document.getElementById(anchorName);
      // target.parentNode.scrollTop = target.offsetTop;
      // this.$refs.box.scrollTop = target.offsetTop;
      // console.log(anchorName);
      this.id = anchorName || 1;
    },
    clickLi(num) {
      this.form.forEach((item, index) => {
        this.form[index] = false;
      });
      // console.log(this.form);
      this.form[num] = true;
      this.$forceUpdate();
      // var id = "about" + num;
      // this.$router.push({ path: `/about/${id}` });
      // this.custormAnchor(id);
    },

    zero() {
      this.form.forEach((item, index) => {
        this.form[index] = false;
      });
    },
  },
  watch:{
    $route(){
      this.id = this.$route.params.id || 1;
    }
  },

  mounted() {
    // this.$refs.box.addEventListener("scroll", this.menu);
    this.custormAnchor(this.$route.params.id);
    axios.get('https://wh-api.wenhua.com.cn/api/officemanage/smallclassurllist').then(res=>{
                var dat = res.data
                var date=new Date()
                var day=date.getDay()
                console.log(dat)
                if(day==5){
                this.url=dat[5].url
                }
                else if(day==2){
                this.url=dat[2].url
                }
                else if(day==3){
                this.url=dat[3].url
                }
                else if(day==4){
                this.url=dat[4].url
                }
                else if(day==1){
                this.url=dat[1].url
                }
                else if(day==6){
                this.url=dat[6].url
                }
                else{
                this.url=dat[0].url
                }
      })
  },
};
</script>

<style scoped>
p {
  font-size: 14px;
  padding-top: 3px;
  padding-bottom: 3px;
  color: #727983;
  line-height: 25px;
  /* width: 640px; */
  letter-spacing: 1px;
  text-align: justify;
}
.productcenterhtml {
  background: url("../../assets/images/about-top.png") no-repeat top;
  background-size: 100%;
  min-width: 1400px;
  width: 100%;
  /* min-height: 839px; */
  padding-top: 110px;
  /* background-position-y: 23px; */
}
.productcenterhead {
  position: relative;
  height: 140px;
  width: 1000px;
  margin: 0 auto;
}
.productcenterheadtext {
  position: absolute;
  top: 105px;
  left: 3px;
}
.productcenterbody {
  width: 1000px;
  margin: 0 auto;
  /* height: 695px; */
  position: relative;
}
.productcenterbodytag {
  width: 280px;
  height: 450px;
  border-radius: 2%;
  background: url("../../assets/images/cxhjy.png") no-repeat center;
  background-size: 100% 100%;
   display: inline-block;
}
.productcenterbodytext {
  /* position: absolute;
  top: 30px;
  left: 210px; */
  width: 100%;
  min-height:620px;
  border-radius: 1em 1em 0 0;
  background: url("../../assets/images/ts-1_02.jpg") no-repeat center;
  background-size: 100% 100%;
  /* overflow-y: auto;
  overflow-x: hidden; */
  /* filter: drop-shadow(1px 1px 2px #c2c5d4); */
  /* box-shadow: -13px 0px rgb(65 97 153 / 10%); */
  padding-bottom: 30px;
  display: inline-block;
  /* margin-left: -80px; */
  /* margin-top: 30px; */
  vertical-align: top;
}
.noclick {
  height: 41px;
  line-height: 41px;

  width: 220px;
  /* border-top: 1px solid #2f5476; */
  letter-spacing: 1px;
  color: #323f62;
  text-align: left;
  float: left;
  font-size: 14px;
  font-weight: normal;
  cursor: pointer;
}
.onclick {
  height: 42px;
  line-height: 42px;
  background: #fdfdfd;

  width: 220px;

  color: #323f62;
  text-align: left;
  letter-spacing: 1px;

  float: left;
  font-size: 14px;
  font-weight: normal;
  cursor: pointer;
}
.touming {
  width: 5px;
  height: 42px;
  float: left;
}
.yellow {
  width: 5px;
  height: 42px;
  background: #c49c71;
  float: left;
  border-top-left-radius: 0.1em;
  border-bottom-left-radius: 0.1em;
}
.head4 {
  
  margin-top:35px;
 
  color: #1f2d54;
    border-bottom: 1px solid #ccc;
    /* text-indent: 1em; */
    font-size: 18px;
    font-weight: 800;
    line-height: 36px;
}

.midtext {
  /* height: 680px; */
  width: 880px;
  margin: 0 auto;
  /* padding-bottom: 100px; */
}
.pproduct {
  font-size: 15px;
  padding-top: 3px;
  padding-bottom: 3px;
  text-indent: 2em;
  color: #727983;
  line-height: 25px;
  /* width: 640px; */
  letter-spacing: 1px;
}
.pimg {
  padding-top: 10px;
  padding-bottom: 10px;
  min-height: 50px;
  width: 100%;
  text-align: center;
}
.noclick:hover {
  color: #e3a200;
}
.pli {
  width: 235px;
  height: 42px;
  /* margin-left: -5px; */
  position: relative;
  z-index: 88;
}
.noclickone {
  height: 41px;
  line-height: 41px;

  letter-spacing: 1px;
  width: 220px;

  /* border-top: 1px solid #2f5476; */
  color: #323f62;
  text-align: left;
  float: left;
  font-size: 14px;
  font-weight: normal;
  cursor: pointer;
}
.noclickele {
  height: 40px;
  line-height: 40px;

  letter-spacing: 1px;
  width: 220px;
  /* border-top: 1px solid #2f5476;
  border-bottom: 1px solid #2f5476; */
  color: #85c0ea;
  text-align: left;
  float: left;
  font-size: 14px;
  font-weight: normal;
  cursor: pointer;
}
.noclickone:hover {
  color: #323f62;
}
.noclickele:hover {
  color: #323f62;
}
.tagmid {
  width: 170px;
  height: 42px;
  float: left;
  font-weight: bold;
  line-height: 42px;
  border-bottom: 1px dashed #ccc;
}
.productcenterbodytag .title {
  display: block;
  height: 60px;

  color: #fff;
  font-weight: bold;
  text-indent: 2em;
  font-size: 16px;
}
.leftP,
.rightP {
  display: inline-block;
  width: 50%;
  vertical-align: middle;
}
.leftP p {
  width: auto;
}
.rightP {
      text-align: center;
}
.ahref {
  color: #34458a;
}
.head3 {
  font-size: 15px;
  /* padding-top: 20px; */
  /* padding-bottom: 10px; */
  /* font-weight: bold; */
  letter-spacing: 1px;
  height: 26px;
  line-height: 26px;
  background-color: #e4e3e1;
  color: #1d3676;
  margin-top: 20px;
  margin-bottom: 10px;
}

.yid span {
  background: url("../../assets/images/yid.png") no-repeat;
}
.erd span {
  background: url("../../assets/images/erd.png") no-repeat;
}
.sand span {
  background: url("../../assets/images/sand.png") no-repeat;
}
.sid span {
  background: url("../../assets/images/sid.png") no-repeat;
}
.head3 span {
  color: #fff;
  /* padding-left: 5px; */
  display: inline-block;
  height: 26px;
  line-height: 26px;
  width: auto;
  padding-left: 5px;
  padding-right: 8px;
  /* background: url("../../assets/images/cp.png") no-repeat; */
  background-size: 100% 100%;
  /* text-indent: 1em; */
}
.tit_p {
  /* height: 40px; */
  /* line-height: 60px; */
  color: #4c4b4b;
  font-weight: 700;
  /* padding-top:15px; */
  padding-bottom:0;
  /* margin-bottom: 10px; */
}
.tit_p1{
  font-weight: normal;
  font-weight: bold;
  font-size: 15px;
  color: #4c4b4b;
  padding-top:15px;
  padding-bottom:0;
}
.a_right {
  color: #34458a;
  /* float: right; */
  margin-right: 6px;
  margin-top: -10px;
}
.p_right{
  text-align: right;
  line-height: 14px;
}
.pproduct{
  display: flex;
}
</style>