<template>
  <div class="tradetable">
    <table style="margin:0 auto;">
      <tr>
        <td
          style="
            width: 70px;
            background: #e1e8f2;
            font-weight: bold;
            color: #084772;
            height: 52px;
            line-height: 52px;
          "
        >
          字母
        </td>
        <td
          style="
            width: 195px;
            background: #e1e8f2;
            font-weight: bold;
            color: #084772;
          "
        >
          期货公司
        </td>
        <td
          style="
            width: 140px;
            background: #e1e8f2;
            font-weight: bold;
            color: #084772;
          "
        >
          上期平台
        </td>
        <td
          style="
            width: 140px;
            background: #e1e8f2;
            font-weight: bold;
            color: #2c638d;
          "
        >
          金仕达交易
        </td>
        <td
          style="
            width: 140px;
            background: #e1e8f2;
            font-weight: bold;
            color: #2c638d;
          "
        >
          恒生接口
        </td>
      </tr>
      <tr>
        <td style="background: white">A</td>
        <td>安粮期货</td>
        <td>支持</td>
        <td>支持</td>
        <td>-</td>
      </tr>
      <tr>
        <td rowspan="3" style="background: white">B</td>
        <td>宝城期货</td>
        <td>支持</td>
        <td>-</td>
        <td>-</td>
      </tr>
      <tr>
        <!-- <td rowspan="2" style="background: white">B</td> -->
        <td>倍特期货</td>
        <td>支持</td>
        <td>支持</td>
        <td>-</td>
      </tr>
      <tr>
        <td>渤海期货</td>
        <td>支持</td>
        <td>-</td>
        <td>-</td>
      </tr>
      <tr>
        <td rowspan="6" style="background: white">C</td>
        <td>财信期货</td>
        <td>支持</td>
        <td>-</td>
        <td>-</td>
      </tr>
      <tr>
        <td>财达期货</td>
        <td>支持</td>
        <td>-</td>
        <td>支持</td>
      </tr>
      <tr>
        <td>长江期货</td>
        <td>支持</td>
        <td>-</td>
        <td>-</td>
      </tr>
      <tr>
        <td>长安期货</td>
        <td>支持</td>
        <td>-</td>
        <td>-</td>
      </tr>
      <tr>
        <td>长城期货</td>
        <td>支持</td>
        <td>-</td>
        <td>-</td>
      </tr>
      <tr>
        <td>创元期货</td>
        <td>支持</td>
        <td>-</td>
        <td>支持</td>
      </tr>
      <tr>
        <td rowspan="14" style="background: white">D</td>
        <td>大地期货</td>
        <td>支持</td>
        <td>-</td>
        <td>支持</td>
      </tr>
      <tr>
        <td>大陆期货</td>
        <td>支持</td>
        <td>-</td>
        <td>-</td>
      </tr>
      <tr>
        <td>大有期货</td>
        <td>支持</td>
        <td>-</td>
        <td>支持</td>
      </tr>
      <tr>
        <td>大越期货</td>
        <td>支持</td>
        <td>-</td>
        <td>支持</td>
      </tr>
      <tr>
        <td>道通期货</td>
        <td>支持</td>
        <td>-</td>
        <td>支持</td>
      </tr>
      <tr>
        <td>东方汇金</td>
        <td>支持</td>
        <td>-</td>
        <td>支持</td>
      </tr>
      <tr>
        <td>东方期货</td>
        <td>支持</td>
        <td>-</td>
        <td>-</td>
      </tr>
      <tr>
        <td>东海期货</td>
        <td>支持</td>
        <td>-</td>
        <td>-</td>
      </tr>
      <tr>
        <td>东航期货</td>
        <td>支持</td>
        <td>-</td>
        <td>-</td>
      </tr>
      <tr>
        <td>东华期货</td>
        <td>支持</td>
        <td>-</td>
        <td>-</td>
      </tr>
      <tr>
        <td>东吴期货</td>
        <td>支持</td>
        <td>-</td>
        <td>支持</td>
      </tr>
      <tr>
        <td>东兴期货</td>
        <td>支持</td>
        <td>-</td>
        <td>-</td>
      </tr>
      <tr>
        <td>东亚期货</td>
        <td>支持</td>
        <td>-</td>
        <td>支持</td>
      </tr>
      <tr>
        <td>东证期货</td>
        <td>支持</td>
        <td>-</td>
        <td>-</td>
      </tr>
      <tr>
        <td rowspan="3" style="background: white">F</td>
        <td>方正中期</td>
        <td>支持</td>
        <td>-</td>
        <td>支持</td>
      </tr>
       <tr>
        <td>佛山金控</td>
        <td>支持</td>
        <td>支持</td>
        <td>-</td>
      </tr>
      <tr>
        <td>福能期货</td>
        <td>支持</td>
        <td>-</td>
        <td>-</td>
      </tr>
      <tr>
        <td rowspan="17" style="background: white">G</td>
        <td>格林大华</td>
        <td>支持</td>
        <td>-</td>
        <td>支持</td>
      </tr>
      <tr>
        <td>冠通期货</td>
        <td>支持</td>
        <td>-</td>
        <td>-</td>
      </tr>
      <tr>
        <td>光大期货</td>
        <td>支持</td>
        <td>-</td>
        <td>-</td>
      </tr>
      <tr>
        <td>广发期货</td>
        <td>支持</td>
        <td>-</td>
        <td>-</td>
      </tr>
      <tr>
        <td>广金期货</td>
        <td>支持</td>
        <td>-</td>
        <td>-</td>
      </tr>
      <tr>
        <td>广州期货</td>
        <td>支持</td>
        <td>-</td>
        <td>支持</td>
      </tr>
      <tr>
        <td>国都期货</td>
        <td>支持</td>
        <td>支持</td>
        <td>-</td>
      </tr>
      <tr>
        <td>国富期货</td>
        <td>支持</td>
        <td>-</td>
        <td>-</td>
      </tr>
      <tr>
        <td>国海良时</td>
        <td>支持</td>
        <td>-</td>
        <td>支持</td>
      </tr>
      <tr>
        <td>国金期货</td>
        <td>支持</td>
        <td>-</td>
        <td>-</td>
      </tr>
      <tr>
        <td>国联期货</td>
        <td>支持</td>
        <td>-</td>
        <td>-</td>
      </tr>
      <tr>
        <td>国贸期货</td>
        <td>支持</td>
        <td>-</td>
        <td>-</td>
      </tr>
      <tr>
        <td>国盛期货</td>
        <td>支持</td>
        <td>-</td>
        <td>-</td>
      </tr>
      <tr>
        <td>国泰君安</td>
        <td>支持</td>
        <td>-</td>
        <td>支持</td>
      </tr>
      <tr>
        <td>国投安信</td>
        <td>支持</td>
        <td>-</td>
        <td>支持</td>
      </tr>
      <tr>
        <td>国信期货</td>
        <td>支持</td>
        <td>-</td>
        <td>-</td>
      </tr>
      <tr>
        <td>国元期货</td>
        <td>支持</td>
        <td>-</td>
        <td>支持</td>
      </tr>
      <tr>
        <td rowspan="23" style="background: white">H</td>
        <td>海航期货</td>
        <td>-</td>
        <td>支持</td>
        <td>-</td>
      </tr>
      <tr>
        <td>海通期货</td>
        <td>支持</td>
        <td>-</td>
        <td>-</td>
      </tr>
      <tr>
        <td>海证期货</td>
        <td>支持</td>
        <td>-</td>
        <td>-</td>
      </tr>
      <tr>
        <td>和合期货</td>
        <td>支持</td>
        <td>-</td>
        <td>-</td>
      </tr>
      <tr>
        <td>恒泰期货</td>
        <td>支持</td>
        <td>-</td>
        <td>-</td>
      </tr>
      <tr>
        <td>恒银期货</td>
        <td>支持</td>
        <td>-</td>
        <td>支持</td>
      </tr>
       <tr>
         <td>恒力期货</td>
        <td>支持</td>
        <td>-</td>
        <td>-</td>
      </tr>
     
      <tr>
        <td>弘业期货</td>
        <td>支持</td>
        <td>-</td>
        <td>-</td>
      </tr>
      <tr>
        <td>红塔期货</td>
        <td>支持</td>
        <td>支持</td>
        <td>-</td>
      </tr>
      <tr>
        <td>宏源期货</td>
        <td>支持</td>
        <td>-</td>
        <td>-</td>
      </tr>
      <tr>
        <td>华安期货</td>
        <td>支持</td>
        <td>-</td>
        <td>支持</td>
      </tr>
      <tr>
        <td>华创期货</td>
        <td>支持</td>
        <td>-</td>
        <td>支持</td>
      </tr>
      <tr>
        <td>华金期货</td>
        <td>支持</td>
        <td>-</td>
        <td>支持</td>
      </tr>
      <tr>
        <td>华联期货</td>
        <td>支持</td>
        <td>-</td>
        <td>支持</td>
      </tr>
      <tr>
        <td>华龙期货</td>
        <td>支持</td>
        <td>-</td>
        <td>支持</td>
      </tr>
      <tr>
        <td>华融期货</td>
        <td>支持</td>
        <td>-</td>
        <td>-</td>
      </tr>
      <tr>
        <td>华泰期货</td>
        <td>支持</td>
        <td>-</td>
        <td>-</td>
      </tr>
      <tr>
        <td>华闻期货</td>
        <td>支持</td>
        <td>-</td>
        <td>-</td>
      </tr>
      <tr>
        <td>华西期货</td>
        <td>支持</td>
        <td>-</td>
        <td>-</td>
      </tr>
      <tr>
        <td>华鑫期货</td>
        <td>支持</td>
        <td>-</td>
        <td>-</td>
      </tr>
      <tr>
        <td>华融融达</td>
        <td>支持</td>
        <td>支持</td>
        <td>-</td>
      </tr>
      <tr>
        <td>徽商期货</td>
        <td>支持</td>
        <td>支持</td>
        <td>-</td>
      </tr>
      <tr>
        <td>混沌天成</td>
        <td>支持</td>
        <td>-</td>
        <td>-</td>
      </tr>
      <tr>
        <td rowspan="10" style="background: white">J</td>
        <td>建信期货</td>
        <td>支持</td>
        <td>-</td>
        <td>-</td>
      </tr>
      <tr>
        <td>江海汇鑫</td>
        <td>支持</td>
        <td>-</td>
        <td>支持</td>
      </tr>
     
      <tr>
        <td>金鹏期货</td>
        <td>支持</td>
        <td>支持</td>
        <td>支持</td>
      </tr>
      <tr>
        <td>金瑞期货</td>
        <td>支持</td>
        <td>-</td>
        <td>支持</td>
      </tr>
      <tr>
        <td>金石期货</td>
        <td>支持</td>
        <td>-</td>
        <td>-</td>
      </tr>
      <tr>
        <td>金信期货</td>
        <td>支持</td>
        <td>支持</td>
        <td>-</td>
      </tr>
      <tr>
        <td>金元期货</td>
        <td>支持</td>
        <td>支持</td>
        <td>-</td>
      </tr>
      <tr>
        <td>锦泰期货</td>
        <td>支持</td>
        <td>-</td>
        <td>支持</td>
      </tr>
      <tr>
        <td>津投期货</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
      </tr>
      <tr>
        <td>九州期货</td>
        <td>支持</td>
        <td>-</td>
        <td>-</td>
      </tr>
      <tr>
        <td rowspan="1" style="background: white">L</td>
        <td>中泰期货</td>
        <td>支持</td>
        <td>-</td>
        <td>支持</td>
      </tr>
      <tr>
        <td rowspan="3" style="background: white">M</td>
        <td>迈科期货</td>
        <td>支持</td>
        <td>-</td>
        <td>-</td>
      </tr>
      <tr>
        <td>美尔雅期货</td>
        <td>支持</td>
        <td>-</td>
        <td>-</td>
      </tr>
      <tr>
        <td>民生期货</td>
        <td>支持</td>
        <td>-</td>
        <td>-</td>
      </tr>
      <tr>
        <td rowspan="2" style="background: white">N</td>
        <td>南华期货</td>
        <td>支持</td>
        <td>支持</td>
        <td>-</td>
      </tr>
      <tr>
        <td>宁证期货</td>
        <td>支持</td>
        <td>-</td>
        <td>支持</td>
      </tr>
      <tr>
        <td style="background: white">P</td>
        <td>平安期货</td>
        <td>支持</td>
        <td>-</td>
        <td>-</td>
      </tr>
      <tr>
        <td rowspan="2"  style="background: white">Q</td>
        <td>前海期货</td>
        <td>支持</td>
        <td>-</td>
        <td>-</td>
      </tr>
       <tr>
        <td>齐盛期货</td>
        <td>支持</td>
        <td>-</td>
        <td></td>
      </tr>
      <tr>
        <td rowspan="2" style="background: white">R</td>
        <td>瑞达期货</td>
        <td>支持</td>
        <td>支持</td>
        <td>-</td>
      </tr>
      <tr>
        <td>瑞奇期货</td>
        <td>支持</td>
        <td>支持</td>
        <td>-</td>
      </tr>
      <tr>
        <td rowspan="8" style="background: white">S</td>
        <td>三立期货</td>
        <td>支持</td>
        <td>-</td>
        <td>支持</td>
      </tr>
      <tr>
        <td>山金期货</td>
        <td>支持</td>
        <td>-</td>
        <td>-</td>
      </tr>
      <tr>
        <td>上海中期</td>
        <td>支持</td>
        <td>-</td>
        <td>-</td>
      </tr>
      <tr>
        <td>申银万国</td>
        <td>支持</td>
        <td>-</td>
        <td>-</td>
      </tr>
      <tr>
        <td>神华期货</td>
        <td>支持</td>
        <td>支持</td>
        <td>-</td>
      </tr>
      <tr>
        <td>首创期货</td>
        <td>支持</td>
        <td>-</td>
        <td>-</td>
      </tr>
       <tr>
        <td>首创京都</td>
        <td>支持</td>
        <td>-</td>
        <td>支持</td>
      </tr>
      <tr>
        <td>盛达期货</td>
        <td>支持</td>
        <td>-</td>
        <td>-</td>
      </tr>
      
      <tr>
        <td rowspan="3" style="background: white">T</td>
        <td>天富期货</td>
        <td>支持</td>
        <td>支持</td>
        <td>-</td>
      </tr>
      <tr>
        <td>铜冠金源</td>
        <td>支持</td>
        <td>-</td>
        <td>-</td>
      </tr>
      <tr>
        <td>通惠期货</td>
        <td>支持</td>
        <td>支持</td>
        <td>-</td>
      </tr>
      <tr>
        <td style="background: white" rowspan="2">W</td>
        <td>五矿经易</td>
        <td>支持</td>
        <td>-</td>
        <td>支持</td>
      </tr>
      <tr>
        <td>物产中大</td>
        <td>支持</td>
        <td>-</td>
        <td>支持</td>
      </tr>
      <tr>
        <td rowspan="11" style="background: white">X</td>
        <td>西南期货</td>
        <td>支持</td>
        <td>-</td>
        <td>支持</td>
      </tr>
       <tr>
        <td>西部期货</td>
        <td>支持</td>
        <td>-</td>
        <td>-</td>
      </tr>
      <tr>
        <td>先融期货</td>
        <td>支持</td>
        <td>-</td>
        <td>-</td>
      </tr>
      <tr>
        <td>新湖期货</td>
        <td>支持</td>
        <td>-</td>
        <td>-</td>
      </tr>
      <tr>
        <td>新纪元期货</td>
        <td>支持</td>
        <td>-</td>
        <td>-</td>
      </tr>
     
      <tr>
        <td>新世纪期货</td>
        <td>支持</td>
        <td>-</td>
        <td>-</td>
      </tr>
      <tr>
        <td>鑫鼎盛期货</td>
        <td>支持</td>
        <td>支持</td>
        <td>-</td>
      </tr>
      <tr>
        <td>信达期货</td>
        <td>支持</td>
        <td>-</td>
        <td>支持</td>
      </tr>
      <tr>
        <td>兴业期货</td>
        <td>支持</td>
        <td>-</td>
        <td>-</td>
      </tr>
      <tr>
        <td>兴证期货</td>
        <td>支持</td>
        <td>-</td>
        <td>-</td>
      </tr>
      <tr>
        <td>先锋期货</td>
        <td>支持</td>
        <td>-</td>
        <td>-</td>
      </tr>
      <tr>
        <td rowspan="6" style="background: white">Y</td>
        <td>一德期货</td>
        <td>支持</td>
        <td>支持</td>
        <td>-</td>
      </tr>
      <tr>
        <td>银河期货</td>
        <td>支持</td>
        <td>-</td>
        <td>-</td>
      </tr>
      <tr>
        <td>英大期货</td>
        <td>支持</td>
        <td>-</td>
        <td>支持</td>
      </tr>
      <tr>
        <td>永安期货</td>
        <td>支持</td>
        <td>-</td>
        <td>支持</td>
      </tr>
      <tr>
        <td>云晨期货</td>
        <td>支持</td>
        <td>支持</td>
        <td>-</td>
      </tr>
      <tr>
        <td>云财富期货</td>
        <td>支持</td>
        <td>-</td>
        <td>-</td>
      </tr>
     
      <tr>
        <td rowspan="20" style="background: white">Z</td>
        <td>招商期货</td>
        <td>支持</td>
        <td>-</td>
        <td>-</td>
      </tr>
      <tr>
        <td>浙商期货</td>
        <td>支持</td>
        <td>-</td>
        <td>支持</td>
      </tr>
      <tr>
        <td>浙石期货</td>
        <td>支持</td>
        <td>-</td>
        <td>支持</td>
      </tr>
      <tr>
        <td>中财期货</td>
        <td>支持</td>
        <td>-</td>
        <td>-</td>
      </tr>
      <tr>
        <td>中钢期货</td>
        <td>支持</td>
        <td>支持</td>
        <td>-</td>
      </tr>
      <tr>
        <td>中国国际</td>
        <td>支持</td>
        <td>-</td>
        <td>-</td>
      </tr>
      <tr>
        <td>中航期货</td>
        <td>支持</td>
        <td>-</td>
        <td>-</td>
      </tr>
      <tr>
        <td>中辉期货</td>
        <td>支持</td>
        <td>-</td>
        <td>-</td>
      </tr>
      <tr>
        <td>中粮期货</td>
        <td>支持</td>
        <td>-</td>
        <td>支持</td>
      </tr>
      <tr>
        <td>紫金天风</td>
        <td>支持</td>
        <td>-</td>
        <td>-</td>
      </tr>
       <tr>
        <td>中金岭南期货</td>
        <td>支持</td>
        <td>-</td>
        <td>-</td>
      </tr>
      <tr>
        <td>中融汇信</td>
        <td>支持</td>
        <td>-</td>
        <td>-</td>
      </tr>
      <tr>
        <td>中天期货</td>
        <td>支持</td>
        <td>支持</td>
        <td>-</td>
      </tr>
      <tr>
        <td>中金财富</td>
        <td>支持</td>
        <td>-</td>
        <td>-</td>
      </tr>
      <tr>
        <td>中信建投</td>
        <td>支持</td>
        <td>-</td>
        <td>-</td>
      </tr>
      <tr>
        <td>中信期货</td>
        <td>支持</td>
        <td>-</td>
        <td>支持</td>
      </tr>
      <tr>
        <td>中衍期货</td>
        <td>支持</td>
        <td>-</td>
        <td>支持</td>
      </tr>
      <tr>
        <td>中银期货</td>
        <td>支持</td>
        <td>-</td>
        <td>-</td>
      </tr>
      <tr>
        <td>中原期货</td>
        <td>支持</td>
        <td>-</td>
        <td>支持</td>
      </tr>
      <tr>
        <td>中州期货</td>
        <td>支持</td>
        <td>-</td>
        <td>-</td>
      </tr>
      
    </table>
    <p class="text_p" style="padding-top:10px;">
      *长安期货、云财富期货、津投期货、中航期货支持飞马后台;国元期货、九州期货支持飞创后台;
    </p>
    <p class="text_p" style="padding-bottom:30px;">
      此列表更新时间：2022年11月8日
    </p>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.tradetable {
  width: 720px;
  margin: 0 auto;
  color: #5a5a5a;
}
tr:nth-child(2n-1) {
  background: #f4f7fc;
}
tr:nth-child(2n) {
  background: white;
}
td {
  vertical-align: middle;
  text-align: center;
  padding-left: 0px;
  border: 1px solid #a9a9a9;
  letter-spacing: 1px;
  color: #5a5a5a;
}
td {
  height: 35px;
  border: 1px solid #cdcdcd;
  line-height: 35px;
  vertical-align: middle;
  font-size: 14px;
  /* padding-left: 40px; */
  letter-spacing: 1px;
  color: #5a5a5a;
}
.text_p{
  line-height: 24px;
  font-size: 14px;
  padding-left:14px;
}
</style>