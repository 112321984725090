<template>
<div>
  <div class="wh8footer" >
    <div
      style="
        display: inline-block
        width: 667px;
        margin: 0 auto;
        letter-spacing: 0.5px;
        padding-top: 10px;
      "
    >
      <a
        style="
          font-size: 12px;
          display: block;
          width: 80px;
          margin: 0 auto;
          color: #416ae1;
          margin-bottom:4px;
        "
        active-class="nosee"
        href="https://www.wenhua.com.cn/"
        target="_blank"
        >文华财经官网</a
      >
      <div>© 上海文华财经资讯股份有限公司<span v-html="'&nbsp;&nbsp;&nbsp;'" style="padding:0"></span>ICP证号：沪B2-20110035</div>
    </div>
    <!-- <div
      style="
        width: 668px;
        margin: 0 auto;
        display: inline-block;
        letter-spacing: 0.5px;
      "
    >
      金融工程师支持：400-811-3366（工作日8:00～22:00，五一、十一、春节初三至初六16:30～20:30）
      <br />

      © 上海文华财经资讯股份有限公司 ICP证号:沪B2-20110035
    </div> -->
    <a
      href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011502008976"
      target="_blank"
      style="display: inline-block;margin-left: 15px;position:relative;top:2px"
      ><img src="../../../../assets/images/wangjing.png"
    /></a>
  </div>
</div>
</template>

<script>
// import ResTxt from '@/ResData/ResTxt'
export default {
  // components: {
  //   ResTxt
  // },
  data(){
    return{
    }
  },
};
</script>

<style>
.wh8footer {
  /* position: absolute;
  bottom: 0; */
  text-align: center;
  min-width: 1400px;
  width: 100%;

  min-height: 64px;

  margin: 0 auto;
  background-color: #010334;
  color: #e8e9ed;
  font-size: 12px;
  line-height: 20px;
}
</style>