<template>
  <div class="buyhtml">
    <div class="buyall">
      <div class="buybody">
        <div class="bodytable">
          <div class="buytips">
            <p style="text-align: center; font-weight: bold">软件服务费</p>
            <div class="buytable">
              <div class="buyleft" >
                
                <div class="buylefttop" @click="clickleft(1)" style="cursor: pointer;margin-top: 50px;">
                  <p
                    
                    style="display: inline-block; font-weight: bold;width:450px;padding: 0 10px;background:#faf6ed;"
                  >
                    8E页面盒子(信号监测、半自动) <span style="float:right">6000元
                    <span
                      style="
                        display: inline-block;
                        font-size: 12px;
                        font-weight: normal;
                      "
                    >
                      /年/账号
                    </span>
                    <div
                    v-bind:class="[lefttext[1] ? isclick : noclick]"
                    class="imgs"
                    id="1"
                  ></div>
                    </span> 
                  </p>
                  
                </div>
                <div v-if="lefttext[1]" class="lefttable">
                  <table>
                    <tr>
                      <td colspan="2" class="buytabletitle">标配数据</td>
                    </tr>
                    <tr>
                      <td>中国金融期货交易所*</td>
                      <td>上海期货交易所(含上期能源)</td>
                    </tr>
                    <tr>
                      <td>大连商品交易所</td>
                      <td>郑州商品交易所</td>
                    </tr>
                    <tr>
                      <td>上海证券交易所*</td>
                      <td>深圳证券交易所*</td>
                    </tr>
                    <tr>
                      <td>文华商品指数和系列板块指数</td>
                      <td></td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <td colspan="2" class="buytabletitle">模型编写</td>
                    </tr>
                    <tr>
                      <td width="240px">公式编写（400+个函数，麦语言）</td>
                      <td>
                        <router-link to="/download/download/2" target="_blank"
                          ><span style="text-decoration: underline #bd9386"
                            >基本面量化函数</span
                          ></router-link
                        ><img src="../../assets/images/huo2.gif" />
                      </td>
                    </tr>
                    <tr>
                      <!-- <td>
                        <router-link to="/download/download/2" target="_blank"
                          ><span style="text-decoration: underline #bd9386"
                            >策略优化函数</span
                          ></router-link
                        ><img src="../../assets/images/huo2.gif" />
                      </td> -->
                      <td>
                        资金头寸函数<img src="../../assets/images/huo2.gif" />
                      </td>
                      <td style="line-height: 17px">止损专用指令</td>
                    </tr>
                    <tr>
                      
                      <td>模型加密函数</td>
                      <td></td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <td colspan="2" class="buytabletitle">模型回测</td>
                    </tr>
                    <tr>
                      <td width="240px">
                        逐笔tick数据回测<img
                          src="../../assets/images/huo2.gif"
                        />
                      </td>
                      <td>专业测试报告</td>
                    </tr>
                    <tr>
                      <td>回测资金曲线（信号复盘）</td>
                      <td>枚举法参数优化</td>
                    </tr>
                    <tr>
                      <td style="line-height: 17px">遗传算法参数优化</td>
                      <td>多模型组合回测</td>
                    </tr>
                    <tr>
                      <td style="line-height: 17px">期货多因子分析</td>
                    </tr>
                  </table>

                  <table>
                    <tr>
                      <td colspan="2" class="buytabletitle">运行</td>
                    </tr>
                    <tr>
                      <td width="240px">后台量化</td>
                      <td>信号监测</td>
                    </tr>
                    <tr>
                      <td>
                        半自动下单
                        
                      </td>
                      <td>批量加载 </td>
                    </tr>
                    <tr>
                      <td>多窗口平铺监控</td>
                      <td style="line-height: 17px">日志邮件发送</td>
                      <!-- <td></td> -->
                    </tr>
                  </table>

                  <table>
                    <tr>
                      <td colspan="2" class="buytabletitle">其它功能</td>
                    </tr>
                    <tr>
                      <td width="240px">云端止损单</td>
                      <td>账户风控</td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <td colspan="2" class="buytabletitle">售后服务</td>
                    </tr>
                    <tr>
                      <td width="240px">专属的金融工程师</td>
                      <td>辅助编写模型</td>
                    </tr>
                    <tr>
                      <td width="240px">文文通即时服务</td>
                    </tr>
                  </table>

                  <!-- <div
                    style="
                      border: 1px #e9dbb8 solid;
                      height: 0;
                      width: 480px;
                      margin-top: 15px;
                      margin-bottom: 20px;
                    "
                  ></div> -->
                </div>
                <div class="buylefttop" @click="clickleft(0)" style="cursor: pointer;margin-top:20px">
                  <p
                    
                    style="display: inline-block; font-weight: bold;width:450px;padding: 0 10px;background:#faf6ed;"
                  >
                    8C页面盒子 + 运行模组(全自动) <span style="float:right">8700元
                    <span
                      style="
                        display: inline-block;
                        font-size: 12px;
                        font-weight: normal;
                      "
                    >
                      /年/账号
                    </span>
                    <div
                    v-bind:class="[lefttext[0] ? isclick : noclick]"
                    class="imgs"
                    id="1"
                  ></div>
                    </span> 
                  </p>
                  
                </div>
                 <div v-if="lefttext[0]" class="lefttable">
                  <table>
                    <tr>
                      <td colspan="2" class="buytabletitle">标配数据</td>
                    </tr>
                    <tr>
                      <td>中国金融期货交易所*</td>
                      <td>上海期货交易所(含上期能源)</td>
                    </tr>
                    <tr>
                      <td>大连商品交易所</td>
                      <td>郑州商品交易所</td>
                    </tr>
                    <tr>
                      <td>上海证券交易所*</td>
                      <td>深圳证券交易所*</td>
                    </tr>
                    <tr>
                      <td>文华商品指数和系列板块指数</td>
                      <td></td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <td colspan="2" class="buytabletitle">模型编写</td>
                    </tr>
                    <tr>
                      <td width="240px">公式编写（400+个函数，麦语言）</td>
                      <td>
                        <router-link to="/download/download/2" target="_blank"
                          ><span style="text-decoration: underline #bd9386"
                            >基本面量化函数</span
                          ></router-link
                        ><img src="../../assets/images/huo2.gif" />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <router-link to="/download/download/2" target="_blank"
                          ><span style="text-decoration: underline #bd9386"
                            >策略优化函数</span
                          ></router-link
                        ><img src="../../assets/images/huo2.gif" />
                      </td>
                      <td>
                        资金头寸函数<img src="../../assets/images/huo2.gif" />
                      </td>
                    </tr>
                    <tr>
                      <td style="line-height: 17px">止损专用指令</td>
                      <td>模型加密函数</td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <td colspan="2" class="buytabletitle">模型回测</td>
                    </tr>
                    <tr>
                      <td width="240px">
                        逐笔tick数据回测<img
                          src="../../assets/images/huo2.gif"
                        />
                      </td>
                      <td>专业测试报告</td>
                    </tr>
                    <tr>
                      <td>回测资金曲线（信号复盘）</td>
                      <td>枚举法参数优化</td>
                    </tr>
                    <tr>
                      <td style="line-height: 17px">遗传算法参数优化</td>
                      <td>多模型组合回测</td>
                    </tr>
                    <tr>
                      <td style="line-height: 17px">期货多因子分析</td>
                    </tr>
                  </table>

                  <table>
                    <tr>
                      <td colspan="2" class="buytabletitle">运行</td>
                    </tr>
                    <tr>
                      <td width="240px">后台量化</td>
                      <td>页面盒子</td>
                    </tr>
                    <tr>
                      <td>
                        期货合约运行模组
                        <img src="../../assets/images/huo2.gif" />
                      </td>
                      <td>产品申购/赎回管理</td>
                    </tr>
                    <tr>
                      <td>远程服务器运行监控</td>
                      <td style="line-height: 17px">日志邮件发送</td>
                      <!-- <td></td> -->
                    </tr>
                  </table>

                  <table>
                    <tr>
                      <td colspan="2" class="buytabletitle">其它功能</td>
                    </tr>
                    <tr>
                      <td width="240px">云端止损单</td>
                      <td>账户风控</td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <td colspan="2" class="buytabletitle">售后服务</td>
                    </tr>
                    <tr>
                      <td width="240px">专属的金融工程师</td>
                      <td>辅助编写模型</td>
                    </tr>
                    <tr>
                      <td width="240px">文文通即时服务</td>
                    </tr>
                  </table>

                  <!-- <div
                    style="
                      border: 1px #e9dbb8 solid;
                      height: 0;
                      width: 480px;
                      margin-top: 15px;
                      margin-bottom: 20px;
                    "
                  ></div> -->
                </div>
                <!-- <div class="buyleftdown">
                 
                </div> -->
              </div>
              <div class="buymid">
                <img src="../../assets/images/jia.png" class="midjiahao" />
              </div>
              <div class="buyright">
                <div
                  style="
                    font-size: 14px;
                    color: #616673;
                    line-height: 16px;
                    margin-top: 20px;
                    font-weight: bold;
                  "
                >
                  根据需要选配模块费用(不是必选)
                </div>
                <div
                  class="buyrighttext"
                  @click="rightclickfirst()"
                  style="margin-top: 15px; cursor: pointer"
                >
                  <p style="display: inline-block" class="buyrightleft">
                    选配的多账号下单模块
                  </p>
                  <div>
                    <div
                      v-bind:class="[righttext.righttext1 ? isclick : noclick]"
                      class="imgs buyleftleft imgri"
                    ></div>
                    <div style="display: inline-block" class="buyleftleft">
                      +<span style="letter-spacing: 0">4800</span>元/年/账号
                    </div>
                  </div>
                </div>
                <div v-if="righttext.righttext1" style="position: relative">
                  <div class="sanjiaoright"></div>
                  <div class="sanjiaotext">
                    <table style="margin-right: 0">
                      <tr>
                        <td width="230px" style="padding-top: 1px">
                          <router-link
                            class="atiaozhuan"
                            to="/proc/productcenter1/productcenter2"
                            target="_blank"
                            >一带多下单</router-link
                          ><img
                            class="huohuo"
                            src="../../assets/images/huo2.gif"
                          />
                        </td>
                        <td width="140px">
                          账号分组<img
                            class="huohuo"
                            src="../../assets/images/huo2.gif"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <router-link
                            class="atiaozhuan"
                            to="/proc/productcenter1/productcenter5"
                            target="_blank"
                            >组中账号设置不同下单倍数</router-link
                          ><img
                            class="huohuo"
                            src="../../assets/images/huo2.gif"
                          />
                        </td>
                        <td>
                          <router-link
                            class="atiaozhuan"
                            target="_blank"
                            to="/proc/productcenter1/productcenter9"
                            >云端分组止损止盈</router-link
                          >
                        </td>
                      </tr>
                      <tr>
                        <td style="padding-bottom: 2px; height: 29px">
                          <router-link
                            class="atiaozhuan"
                            target="_blank"
                            to="/proc/productcenter1/productcenter8"
                            >云端分组条件单</router-link
                          >
                        </td>
                        <td>账户风控</td>
                      </tr>
                    </table>
                  </div>
                </div>
                <div
                  class="buyrighttext"
                  style="cursor: pointer"
                  @click="rightclicksecond()"
                >
                  <p style="display: inline-block" class="buyrightleft">
                    选配的云主机
                  </p>
                  <div>
                    <div
                      v-bind:class="[righttext.righttext2 ? isclick : noclick]"
                      class="imgs buyleftleft imgri"
                    ></div>
                    <div style="display: inline-block" class="buyleftleft">
                      按实际选配机型定价
                    </div>
                  </div>
                </div>
                <div v-if="righttext.righttext2" style="position: relative">
                  <div class="sanjiaoright"></div>
                  <div class="sanjiaotext" style="padding-top: 5px">
                    <table style="margin-right: 0">
                      <tr>
                        <td width="240px" style="text-indent: 0">
                          <router-link
                            target="_blank"
                            to="/proc/productcenter2/one"
                            >云主机Ⅲ型<span style="font-size: 12px"
                              >（四核cpu、8G内存）</span
                            ></router-link
                          >
                        </td>
                        <td width="135px" style="padding-left: 0">
                          +13800元/年/账号
                        </td>
                      </tr>
                      <tr>
                        <td style="text-indent: 0">
                          <router-link
                            target="_blank"
                            to="/proc/productcenter2/one"
                            >云主机Ⅳ型<span style="font-size: 12px"
                              >（八核cpu、16G内存）</span
                            ></router-link
                          >
                        </td>
                        <td style="padding-left: 0">+18000元/年/账号</td>
                      </tr>
                    </table>
                  </div>
                </div>
                <div
                  class="buyrighttext"
                  style="cursor: pointer"
                  @click="rightclickthird()"
                >
                  <p
                    style="display: inline-block; margin-left: -8px"
                    class="buyrightleft"
                  >
                    *选配的数据模块
                  </p>
                  <div>
                    <div
                      v-bind:class="[righttext.righttext3 ? isclick : noclick]"
                      class="imgs buyleftleft imgri"
                    ></div>
                    <div style="display: inline-block" class="buyleftleft">
                      按实际选配数据定价
                    </div>
                  </div>
                </div>
                <div
                  v-if="righttext.righttext3"
                  style="
                    background: #fffdf8;
                    width: 415px;
                    min-height: 0;
                    position: relative;
                    margin-top: 10px;
                    margin-bottom: 20px;
                  "
                >
                  <div class="sanjiaoright"></div>
                  <div class="santextright">价格单位:元/年/账号</div>
                  <div
                    class="sanjiaotext"
                    style="padding-top: 21px; width: 415px; padding-bottom: 1px"
                  >
                    <div class="titlexp">
                      <span style="width: 220px; padding-right: 30px"
                        >授权名称</span
                      ><span style="width: 80px">购买人身份</span
                      ><span style="width: 70px">价格</span>
                    </div>
                    <table
                      class="xuanpeitb"
                      style="margin-bottom: 0; margin-top: 0"
                    >
                      <!-- <tr ><td width="255px" style="height:0px;"></td><td width="75px" style="text-align:center"></td><td ></td></tr> -->

                      <tr>
                        <td rowspan="2" class="konghang xxxx" width="235px">
                          <a
                            href="https://www.wenhua.com.cn/Home/ChargeDataCME"
                            target="_blank"
                            >纽约商业交易所(NYMEX)</a
                          >
                        </td>
                        <td class="shangtxt classzihao" width="75px">
                          非专业用户
                        </td>
                        <td class="shangtxt" width="60px">+360</td>
                      </tr>
                      <tr class="xxxx">
                        <td
                          class="xxxx xiatxt classzihao"
                          style="
                            text-align: center;
                            border-top: 1px solid #ffe5aa;
                            border-right: 0;
                          "
                        >
                          专业用户
                        </td>
                        <td
                          class="xxxx xiatxt xuqiu"
                          style="border-top: 1px solid #ffe5aa"
                        >
                          +12000
                        </td>
                      </tr>
                      <tr>
                        <td rowspan="2" class="konghang">
                          <a
                            href="https://www.wenhua.com.cn/Home/ChargeDataCME"
                            target="_blank"
                            >纽约商品交易所(COMEX)</a
                          >
                        </td>
                        <td class="shangtxt classzihao">非专业用户</td>
                        <td class="shangtxt">+360</td>
                      </tr>
                      <tr class="xxxx">
                        <td
                          class="xxxx xiatxt classzihao"
                          style="
                            text-align: center;
                            border-top: 1px solid #ffe5aa;
                            border-right: 0;
                          "
                        >
                          专业用户
                        </td>
                        <td
                          class="xxxx xiatxt xuqiu"
                          style="border-top: 1px solid #ffe5aa"
                        >
                          +12000
                        </td>
                      </tr>
                      <tr>
                        <td rowspan="2" class="konghang">
                          <a
                            href="https://www.wenhua.com.cn/Home/ChargeDataCME"
                            target="_blank"
                            >芝加哥期货交易所(CBOT)</a
                          >
                        </td>
                        <td class="shangtxt classzihao">非专业用户</td>
                        <td class="shangtxt">+360</td>
                      </tr>
                      <tr class="xxxx">
                        <td
                          class="xxxx xiatxt classzihao"
                          style="
                            text-align: center;
                            border-top: 1px solid #ffe5aa;
                            border-right: 0;
                          "
                        >
                          专业用户
                        </td>
                        <td
                          class="xxxx xiatxt xuqiu"
                          style="border-top: 1px solid #ffe5aa"
                        >
                          +12000
                        </td>
                      </tr>
                      <tr>
                        <td rowspan="2" class="konghang">
                          <a
                            href="https://www.wenhua.com.cn/Home/ChargeDataCME"
                            target="_blank"
                            >芝加哥商品交易所(CME)</a
                          >
                        </td>
                        <td class="shangtxt classzihao">非专业用户</td>
                        <td class="shangtxt">+360</td>
                      </tr>
                      <tr class="xxxx">
                        <td
                          class="xxxx xiatxt classzihao"
                          style="
                            text-align: center;
                            border-top: 1px solid #ffe5aa;
                            border-right: 0;
                          "
                        >
                          专业用户
                        </td>
                        <td
                          class="xxxx xiatxt xuqiu"
                          style="border-top: 1px solid #ffe5aa"
                        >
                          +12000
                        </td>
                      </tr>
                      <tr>
                        <td rowspan="2" class="konghang">
                          欧洲期货交易所(EUREX)
                        </td>
                        <td class="shangtxt classzihao">个人</td>
                        <td class="shangtxt" style="padding-left: 1px">
                          *+360
                        </td>
                      </tr>
                      <tr class="xxxx">
                        <td
                          class="xxxx xiatxt classzihao"
                          style="
                            text-align: center;
                            border-top: 1px solid #ffe5aa;
                            border-right: 0;
                          "
                        >
                          公司
                        </td>
                        <td
                          class="xxxx xiatxt"
                          style="
                            text-align: left;
                            padding-left: 8px;
                            border-top: 1px solid #ffe5aa;
                          "
                        >
                          +7200
                        </td>
                      </tr>
                      <tr class="xxxx">
                        <td class="konghang">伦敦金属交易所(LME)</td>
                        <td class="zhong">/</td>
                        <td class="xxxx">+12000</td>
                      </tr>
                      <tr class="xxxx">
                        <td class="konghang">洲际交易所(ICE)- 美国</td>
                        <td class="zhong">/</td>
                        <td class="xxxx">+15000</td>
                      </tr>
                      <tr class="xxxx">
                        <td class="konghang">洲际交易所(ICE)- 欧洲商品</td>
                        <td class="zhong">/</td>
                        <td class="xxxx">+15000</td>
                      </tr>
                      <tr class="xxxx">
                        <td class="konghang">香港股票(需境外查看)</td>
                        <td class="zhong">/</td>
                        <td class="xxxx">+3000</td>
                      </tr>
                      <tr>
                        <td rowspan="2" class="konghang">
                          香港股票(仅境内查看)
                        </td>
                        <td class="shangtxt classzihao">个人</td>
                        <td class="shangtxt">+1200</td>
                      </tr>
                      <tr class="xxxx">
                        <td
                          class="xxxx xiatxt classzihao"
                          style="
                            text-align: center;
                            border-top: 1px solid #ffe5aa;
                            border-right: 0;
                          "
                        >
                          公司
                        </td>
                        <td
                          class="xxxx xiatxt"
                          style="
                            text-align: left;
                            padding-left: 8px;
                            border-top: 1px solid #ffe5aa;
                          "
                        >
                          +1500
                        </td>
                      </tr>
                      <tr class="xxxx">
                        <td class="konghang">美国股票</td>
                        <td class="zhong">/</td>
                        <td class="xxxx">+360</td>
                      </tr>
                      <tr class="xxxx">
                        <td class="konghang">伦敦金数据</td>
                        <td class="zhong">/</td>
                        <td class="xxxx">+360</td>
                      </tr>

                      <tr class="xxxx cccc">
                        <td class="konghang" style="width: 255px">
                          上海黄金交易所数据(黄金T+D等)
                        </td>
                        <td class="zhong" style="width: 85px">/</td>
                        <td class="xxxx">+360</td>
                      </tr>
                      <tr class="xxxx">
                        <td class="konghang">*金融期货交易所(CFFEX)境外查看</td>
                        <td class="zhong">/</td>
                        <td class="xxxx">+3600</td>
                      </tr>
                      <tr class="xxxx">
                        <td class="konghang">
                          <a
                            href="https://www.wenhua.com.cn/Home/ChargeDataDLEVEL2"
                            target="_blank"
                            >Level2-大连商品交易所(DCE)</a
                          >
                        </td>
                        <td class="zhong">/</td>
                        <td class="xxxx">+1200</td>
                      </tr>
                      <tr class="xxxx">
                        <td class="konghang">
                          <a
                            href="https://www.wenhua.com.cn/Home/ChargeDataDCE"
                            target="_blank"
                            >五档-大连商品交易所(DCE)</a
                          >
                        </td>
                        <td class="zhong">/</td>
                        <td class="xxxx">+600</td>
                      </tr>
                      <tr class="xxxx">
                        <td class="konghang">
                          <a
                            href="https://www.wenhua.com.cn/Home/ChargeDataCFFEX"
                            target="_blank"
                            >五档-金融期货交易所(CFFEX)</a
                          >
                        </td>
                        <td class="zhong">/</td>
                        <td class="xxxx">+1200</td>
                      </tr>
                      <tr class="xxxx">
                        <td class="konghang">
                          <a
                            href="https://www.wenhua.com.cn/Home/ChargeDataCZCE"
                            target="_blank"
                            >五档-郑州商品交易所(ZCE)</a
                          >
                        </td>
                        <td class="zhong">/</td>
                        <td class="xxxx">+600</td>
                      </tr>
                      <tr class="xxxx">
                        <td class="konghang">
                          <a style="font-size: 12px"
                            >五档-上海黄金交易所数据(黄金T+D等)</a
                          >
                        </td>
                        <td class="zhong">/</td>
                        <td class="xxxx">+600</td>
                      </tr>
                      <tr class="xxxx">
                        <td rowspan="2" class="konghang">
                          <a
                            href="https://www.wenhua.com.cn/Home/ChargeDataCME"
                            target="_blank"
                            >五档-纽约商业交易所(NYMEX)</a
                          >
                        </td>
                        <td class="shangtxt classzihao">非专业用户</td>
                        <td class="shangtxt">+1800</td>
                      </tr>
                      <tr class="xxxx">
                        <td
                          class="xxxx xiatxt classzihao"
                          style="
                            text-align: center;
                            border-top: 1px solid #ffe5aa;
                            border-right: 0;
                          "
                        >
                          专业用户
                        </td>
                        <td
                          class="xxxx xiatxt xuqiu"
                          style="border-top: 1px solid #ffe5aa"
                        >
                          +12000
                        </td>
                      </tr>
                      <tr>
                        <td rowspan="2" class="konghang">
                          <a
                            href="https://www.wenhua.com.cn/Home/ChargeDataCME"
                            target="_blank"
                            >五档-纽约商品交易所(COMEX)</a
                          >
                        </td>
                        <td class="shangtxt classzihao">非专业用户</td>
                        <td class="shangtxt">+1800</td>
                      </tr>
                      <tr class="xxxx">
                        <td
                          class="xxxx xiatxt classzihao"
                          style="
                            text-align: center;
                            border-top: 1px solid #ffe5aa;
                            border-right: 0;
                          "
                        >
                          专业用户
                        </td>
                        <td
                          class="xxxx xiatxt xuqiu"
                          style="border-top: 1px solid #ffe5aa"
                        >
                          +12000
                        </td>
                      </tr>
                      <tr>
                        <td rowspan="2" class="konghang">
                          <a
                            href="https://www.wenhua.com.cn/Home/ChargeDataCME"
                            target="_blank"
                            >五档-芝加哥期货交易所(CBOT)</a
                          >
                        </td>
                        <td class="shangtxt classzihao">非专业用户</td>
                        <td class="shangtxt">+1800</td>
                      </tr>
                      <tr class="xxxx">
                        <td
                          class="xxxx xiatxt classzihao"
                          style="
                            text-align: center;
                            border-top: 1px solid #ffe5aa;
                            border-right: 0;
                          "
                        >
                          专业用户
                        </td>
                        <td
                          class="xxxx xiatxt xuqiu"
                          style="border-top: 1px solid #ffe5aa"
                        >
                          +12000
                        </td>
                      </tr>
                      <tr>
                        <td rowspan="2">
                          <a
                            href="https://www.wenhua.com.cn/Home/ChargeDataCME"
                            target="_blank"
                            >五档-芝加哥商品交易所(CME)</a
                          >
                        </td>
                        <td class="shangtxt classzihao">非专业用户</td>
                        <td class="shangtxt">+1800</td>
                      </tr>
                      <tr>
                        <td
                          class="xiatxt classzihao"
                          style="
                            text-align: center;
                            border-top: 1px solid #ffe5aa;
                            border-right: 0;
                          "
                        >
                          专业用户
                        </td>
                        <td
                          class="xiatxt xuqiu"
                          style="border-top: 1px solid #ffe5aa"
                        >
                          +12000
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="tipsss">
        <p class="zhu">*注&nbsp;:</p>
        <p class="zhushi">
          1.中国金融期货数据及国内股票数据仅限在中国(不含港、澳、台)范围内查看使用;
        </p>
        <p class="zhushi">
          2.选配数据是指在标配数据以外可选择增加的数据授权，选配数据需另外支付相应费用(费用单位：元/年/账号);
        </p>
        <p class="zhushi">3.欧洲期货交易所个人价格只针对中国境内用户有效;</p>
        <p class="zhushi" style="margin-bottom: 25px">
          4.如需在境外查看中金一档数据，需付数据使用费。
        </p>
        <p style="border-bottom: 1px solid rgb(207, 211, 230)"></p>
        <p style="margin-top: 25px">
          <img src="../../assets/images/zhuyi.png" /><span
            class="zhushi"
            style="padding-left: 3px"
            >声明:</span
          >
        </p>
        <p class="zhushi">
          1.本服务必须提供的数据、信息、功能仅限用户另外付费选配的范围,服务期内我司有权对其他数据、信息、功能进行增减等调整;
        </p>
        <p class="zhushi">
          2.你可能遇到的下列情形(包括但不限于)恕我司不负责解决，并不退还剩余费用：
        </p>
        <p class="zhushi">
          (1)因电脑硬件配置低或操作系统版本过低，无法满足新版要求；
        </p>
        <p class="zhushi">(2)开户的期货公司，不再支持文华软件。</p>
        <div class="lianjie">
          <div class="buylianjie">
            <a
              style="
                color: white;
                font-size: 18px;
                font-weight: bold;
                letter-spacing: 1px;
              "
              href="https://pay.wenhua.com.cn/?whid=webmaster&clientid=data&mt=fA&pid=0&Futurestype=999#/"
              target="_blank"
              >去购买</a
            >
          </div>
          <a
            style="
              text-decoration: none;
              color: #304560;
              position: relative;
              bottom: -8px;
              font-size: 12px;
              border-bottom: 2px solid black;
              letter-spacing: 1px;
            "
            href="https://pay.wenhua.com.cn/#/question"
            target="_blank"
          >
            购买相关常见问题</a
          >
        </div>
        <div style="padding-bottom: 20px">
          <!-- <p class="zhushi" style="text-indent: 0;">
          wh8购买说明
        </p>
        <p class="zhushi" style="text-indent: 0;">
          1、使用算法交易，需要购买<a style="color:rgb(65,106,225);text-decoration: underline;" href="https://wh9.wenhua.com.cn/Order/Index" target="_blank">wh9</a>;
        </p>
        <p class="zhushi" style="text-indent: 0;">
          2、付款后，如无功能问题，恕不退款。
        </p> -->
        </div>
      </div>
    </div>
    <!-- <wh8footer></wh8footer> -->
  </div>
</template>

<script>
// import wh8footer from "./components/wh8/wh8footer.vue";
export default {
  data() {
    return {
      lefttext: [false,false],
      isclick: "imgbuy",
      noclick: "imgbuy1",
      righttext: {
        righttext1: false,
        righttext2: false,
        righttext3: false,
      },
    };
  },
  components: {
    // wh8footer,
  },
  methods: {
    custormAnchor(anchorName) {
      // 找到锚点
      let anchorElement = document.getElementById(anchorName);
      // 如果对应id的锚点存在，就跳转到锚点
      if (anchorElement) {
        anchorElement.scrollIntoView();
      }
    },
    clickleft(num) {
      this.lefttext[num] = !this.lefttext[num];
     if(num == 0){
       this.lefttext[1] = false
     }else{
       this.lefttext[0] = false
     }
        
      
      

      this.$forceUpdate();
      // if (this.lefttext == false) {
      //   document.getElementsByClassName("buyleftdown")[0].style.textAlign =
      //     "center";
      // } else {
      //   document.getElementsByClassName("buyleftdown")[0].style.textAlign =
      //     "right";
      // }
    },
    bianli() {
      Object.keys(this.righttext).forEach((item) => {
        this.righttext[item] = false;
      });
    },
    rightclickfirst() {
      //       console.log(Object.getOwnPropertyNames(this.righttext).length)
      // for(var count=0;count<this.righttext.length;count++){
      //   if(count==0){
      //     this.righttext.righttext1=!this.righttext.righttext1

      //   }
      //   else{
      //     console.log(1)
      //     this.righttext[count]=false
      //   }
      // }
      Object.keys(this.righttext).forEach((item, index) => {
        if (index == 0) {
          this.righttext.righttext1 = !this.righttext.righttext1;
        } else {
          this.righttext[item] = false;
        }
      });
    },
    rightclicksecond() {
      Object.keys(this.righttext).forEach((item, index) => {
        if (index == 1) {
          this.righttext.righttext2 = !this.righttext.righttext2;
        } else {
          this.righttext[item] = false;
        }
      });
    },
    rightclickthird() {
      Object.keys(this.righttext).forEach((item, index) => {
        if (index == 2) {
          this.righttext.righttext3 = !this.righttext.righttext3;
        } else {
          this.righttext[item] = false;
        }
      });
    },
  },
  mounted() {
    this.custormAnchor(this.$route.params.id);
  },
};
</script>

<style scoped>
.buyhtml {
  /* background: #f8fbff; */
  background: url("../../assets/images/buy-top.png") no-repeat top;
  padding-top: 100px;
  min-width: 1400px;
  width: 100%;
  /* background-size:  1000px auto; */
  min-height: 700px;
  z-index: 3;
  margin: 0 auto;
  font-size: 14px;
}
.buyall {
  min-width: 1400px;
  width: 100%;

  min-height: 700px;
  z-index: 10;
}
.buyhead {
  position: relative;
  height: 140px;
  width: 1000px;
  margin: 0 auto;
}
.buyheadtext {
  position: absolute;
  top: 100px;
}
.buybody {
  width: 1000px;
  margin: 0 auto;
  min-height: 230px;
  padding-bottom: 30px;
  /* background: white; */
  margin-top: 9px;
  background: url("../../assets/images/jsbg.jpg") no-repeat top;
  /* background-size:  1000px auto; */
}
td {
  border: 0;
  text-align: left;
  padding-left: 2px;
  color: #7d4c3d;
  padding-right: 7px;
  padding-left: 2px;
  height: 28px;
  line-height: 28px;
}
.bodytable {
  width: 1000px;
  margin: 0 auto;
}
.midtxt {
  padding-left: 15px;
  letter-spacing: 0.5px;
}
.rigtxt {
  background: #faf5ef;
}
br {
  height: 0px;
}
.zhu {
  background: url("../../assets/images/zhu.png") no-repeat;
  margin-top: 20px;
  color: white;
  padding-left: 7px;
  height: 25px;
  line-height: 25px;
  font-size: 14px;
}
.zhushi {
  line-height: 24px;
  font-size: 14px;
  color: #5a5a5a;
  letter-spacing: 1px;
  text-indent: 1em;
}
.lianjie {
  margin-top: 60px;
  padding-bottom: 30px;
  text-align: right;
  width: 972px;
}
.buylianjie {
  background: url("../../assets/images/buy.png") no-repeat center;
  display: inline-block;
  width: 150px;
  height: 40px;
  margin-left: 10px;
  line-height: 40px;
  text-align: center;
}
a {
  color: #7d4c3d;
  text-decoration: none;
  width: 180px;
}
.price {
  padding-left: 19px;
  letter-spacing: 0.1px;
}
.xuanpei {
  background: #e8d3b4;
  text-align: center;
  font-weight: bold;
  font-size: 15px;
}
.buytips {
  min-height: 40px;
  height: 100%;
  font-size: 18px;
  line-height: 40px;
  /* background: #cbd6fc; */
  color: #fff;
  letter-spacing: 2px;
  font-weight: 540;
}
.buytable {
  position: relative;
  height: 100%;
  min-height: 180px;
  width: 1000px;
  /* background: #faf8f3; */
}
.buyleft {
  vertical-align: top;
  margin-top: 0px;
  width: 520px;
  color: #93694a;
  display: inline-block;
  /* padding-left: 30px; */
  /* text-align: center; */
}
.buyleftdown {
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 45px;
}
.buylefttop {
  padding-left: 30px;
  font-size: 18px;
}
.imgbuy {
  background: url("../../assets/images/jiantouxia.png") no-repeat center;
}
.lefttable {
  width: 470px;
  margin-left: 30px;
  background: rgb(250, 246, 237);
  border-bottom: 2px #e9dbb8 solid;
  /* margin-top: 40px; */
}
.lefttable table{
  width: 440px;
      margin-left: 10px;
}
.buytabletitle {
  width: 480px;
  background: #f3e6c9;
  color: #93694a;
  text-align: left;
  padding-left: 5px;
}
.imgbuy1 {
  background: url("../../assets/images/jiantoushang.png") no-repeat center;
}
.imgs {
  display: inline-block;
  margin-left: 8px;
  margin-bottom: 7px;
  cursor: pointer;
  width: 13px;
  height: 10px;
}
.buymid {
  position: absolute;
  top: 10px;
  bottom: 10px;
  left: 520px;
  margin: 15px 15px 15px 15px;
  display: inline-block;
  width: 10px;
  border-left: 1px solid #e4d7b9;
}
.buyright {
  display: inline-block;
  width: 395px;
  margin-left: 55px;
  min-height: 180px;
}
.buyrighttext {
  color: #93694a;
  font-size: 14px;
  font-weight: bold;
  line-height: 24px;
  margin-top: 5px;

  height: 24px;
}
.buyrightleft {
  float: left;
}
.buyleftleft {
  float: right;
}
.imgri {
  padding-bottom: 10px;
}
.tipsss {
  width: 1000px;
  margin: 0 auto;
}
.midjiahao {
  position: absolute;
  left: -20px;
  top: 45px;
}
.csssanjiao {
  margin-top: 2px;
  height: 0;
  width: 0;
  border-bottom: 10px solid #fff5e0;
  border-right: 8px solid #faf8f3;
  border-left: 8px solid #faf8f3;
  margin-left: 70px;
  box-shadow: 2px -1px 16px #f2e0bc inset;
}
.sanjiaotext {
  box-shadow: 2px -1px 2px #f2e0bc inset, -2px 0px 5px #f2e0bc inset;
  background: #fff5e0;
  width: 395px;
  margin-top: 15px;
}
.buythree {
  width: 400px;
  background: #ffecc2;
  color: #93694a;
  position: relative;

  height: 30px;
}
.buythree p {
  line-height: 30px;
  height: 30px;
  font-size: 14px;
}
.threeleft {
  position: absolute;
  left: 10px;
}
.threeright {
  position: absolute;
  right: 0;
}
.threehiright {
  position: absolute;
  right: 0;
  height: 65px;
  width: 200px;
  border-left: 1px solid #fffdf8;
  font-size: 14px;
}
.threehiritop {
  border-bottom: 1px solid #fffdf8;
  height: 24px;
  text-align: center;
  line-height: 24px;
}
.threehiright p {
  text-align: center;
  line-height: 24px;
}

table tr td:first-child {
  padding-left: 10px;
  padding-right: 0;
}
.sanjiao {
  margin-left: 70px;
  margin-bottom: -15px;
}
.sanjiaoleft {
  width: 20px;
  height: 20px;
  transform: rotate(45deg);
  -ms-transform: rotate(45deg); /* IE 9 */
  -moz-transform: rotate(45deg); /* Firefox */
  -webkit-transform: rotate(45deg); /* Safari 和 Chrome */
  -o-transform: rotate(45deg); /* Opera */
  background: #fff5e0;
  position: absolute;
  box-shadow: 2px -1px 2px #f2e0bc inset;
}
.sanjiaoright {
  width: 13px;
  height: 13px;
  transform: rotate(135deg);
  -ms-transform: rotate(135deg); /* IE 9 */
  -moz-transform: rotate(135deg); /* Firefox */
  -webkit-transform: rotate(135deg); /* Safari 和 Chrome */
  -o-transform: rotate(135deg); /* Opera */
  background: #fff5e0;
  position: absolute;
  top: -6px;
  left: 70px;
  box-shadow: 2px -1px 2px #f2e0bc inset;
}
.buyright td {
  height: 28px;
  line-height: 20px;
}
.huohuo {
  margin-bottom: -5px;
}
.xuanpeitb {
  margin: 11px;
  margin-top: 7px;
  margin-bottom: 15px;
  border-collapse: separate;

  background: #fff5e1;
}
.xuanpeitb td {
  /* background: #faf8f3; */
  padding: 0;
  padding-left: 0;
}
.xuanpeitb tr td:first-child {
  padding: 0;
  text-align: left;
  border-right: 1px solid #ffe5aa;
}
.xuanpei a {
  color: #9b755a;
}
.xuanpeitb tr {
  background: #fff5e1;
  height: 22px;
}
.xuanpeitb td {
  height: 17px;
  line-height: 17px;
}
/* .xuanpeitb tr:first-child{
    background: #ffe5aa;
    
  } */
.xuanpeitb tr:first-child td {
  padding-left: 0;
}
.santextright {
  height: 13px;
  position: absolute;
  top: -3px;
  right: 6px;
  font-size: 13px;
  color: #9e906b;
}
.zhong {
  text-align: left;
  border-bottom: 1px solid #ffe5aa;
  font-size: 13px;
  height: 28px !important;
  line-height: 23px !important;
}
.konghang {
  padding-left: 0px !important;
  border-bottom: 1px solid #ffe5aa;
}
.xxxx {
  border-bottom: 1px solid #ffe5aa;
}
.xxx {
  width: 380px;
  margin: 0 auto;
  border-bottom: 1px solid #ffe5aa;
  height: 0;
  line-height: 0;
}
.ssss {
  border-bottom: 1px solid #ffe5aa;

  border-spacing: 0;
}
.ssss td {
  border-spacing: 0;
}
.hh tr:first-child td {
  background: #fff5e1 !important;
  color: #7d4c3d !important;
  text-align: left;
}
.shangtxt {
  /* padding-top: 5px !important; */
}
.xiatxt {
  /* padding-bottom: 5px !important; */
  text-align: right;
}
.xuanpeitb tr td:nth-child(2) {
  text-align: center;
}
.xuanpeitb tr td:nth-child(3) {
  padding-left: 8px;
}
.classzihao {
  font-size: 13px;
}
.xuanpeitb tr td:last-child {
  letter-spacing: 0;
}
.titlexp {
  background: #ffe5aa;
  font-size: 14px;
  color: #9b755a;
  margin: 5px 5px 0 5px;
  line-height: 34px;
  height: 34px;
}
.titlexp span {
  display: inline-block;
  text-align: center;
}
.atiaozhuan {
  text-decoration: underline #bd9386;
}
.buyright a {
  text-decoration: underline #bd9386;
}
.xuqiu {
  text-align: left !important;
  padding-left: 8px !important;
}
td {
  height: 28px;
  /* border: 1px solid #cdcdcd; */
  line-height: 28px;
  vertical-align: middle;
  font-size: 14px;
  padding-left: 30px;
  letter-spacing: 1px;
}
td img {
  vertical-align: middle;
}
</style>