<template>
    <transition name="fade">
        <div v-if="show" class="tip" @click="click">{{ text }}</div>
    </transition>
</template>

<script>
export default {
    name: 'Tip',
    props: {
        text: String,
        show: Boolean
    },
    methods: {
        click() {
            this.$emit('update:show', false)
        }
    }
}
</script>

<style scoped>
.tip {
    color: #e25656;
    background: #fcf9d9;

    padding: 9px 15px;
    min-width: 10px;
    font-size: 12px;
    line-height: 100%;

    display: inline-block;
    position: absolute;
    white-space: nowrap;

    filter: drop-shadow(0 4px 10px rgba(254, 76, 14, .18));
    box-shadow: 0 0 12px rgba(249, 188, 107, .25) inset;

    border: 1px solid #fdc998;
    border-radius: 27px;

    z-index: 1;
    user-select: none;

    cursor: default;
}

.tip::after {
    content: '';

    border: 5px solid transparent;
    border-top-color: #fcf1cb;
    filter: drop-shadow(0 1.48px 0 #fdc998);

    left: 14px;
    bottom: -9px;
    position: absolute;
}

.fade-enter-active, .fade-leave-active {
    transition: all .14s;
}

.fade-enter, .fade-leave-to {
    transform: translateY(10px);
    opacity: 0;
}

</style>