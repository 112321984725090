<template>
  <div class="productcenterhtml">
    <div class="productcentercontent">
      <div class="productcenterbody">
        <div class="productcenterbodytag">
          <ul style="padding-top: 70px">
            <span class="title">文华云主机</span>
            <li class="pli">
              <div v-bind:class="[form[0] ? class4 : class3]"></div>
              <div
                v-bind:class="[form[0] ? class1 : class5]"
                style="line-height: 20px"
                @click="clickLi(0)"
              >
                <div style="width: 26px; height: 40px; float: left"></div>
                <div class="tagmid" style="width: 160px">
                  专业云主机的技术优势
                </div>
                <div style="width: 30px; height: 40px; float: left"></div>
              </div>
              <div
                style="width: 9px; height: 7px; float: left; ine-height: 7px"
              ></div>
            </li>
            <li class="pli">
              <div v-bind:class="[form[1] ? class4 : class3]"></div>
              <div
                v-bind:class="[form[1] ? class1 : class2]"
                @click="clickLi(1)"
              >
                <div style="width: 26px; height: 40px; float: left"></div>
                <div class="tagmid">云配置</div>
                <div style="width: 30px; height: 40px; float: left"></div>
              </div>
            </li>
            <li class="pli">
              <div v-bind:class="[form[2] ? class4 : class3]"></div>
              <div
                v-bind:class="[form[2] ? class1 : class2]"
                @click="clickLi(2)"
              >
                <div style="width: 26px; height: 40px; float: left"></div>
                <div class="tagmid">购买</div>
                <div style="width: 30px; height: 40px; float: left"></div>
              </div>
            </li>
            <li class="pli">
              <div v-bind:class="[form[3] ? class4 : class3]"></div>
              <div
                v-bind:class="[form[3] ? class1 : class2]"
                @click="clickLi(3)"
              >
                <div style="width: 26px; height: 40px; float: left"></div>
                <div class="tagmid">上云步骤</div>
                <div style="width: 30px; height: 40px; float: left"></div>
              </div>
            </li>
          </ul>
        </div>
        <div class="productcenterbodytext" ref="box">
          <div
            style="
              width: 780px;

              border-radius: 2%;
              position: relative;
            "
          >
            <div class="midtext">
              
              <div v-if="form[0]">
              <p class="pproduct" id="productcenter0">
                期货交易专用云主机服务：文华利用云机房为量化交易者精心打造的极速、可靠、无阻网络环境，让网络对量化交易的影响趋近于“0”。
              </p>
              <div class="head4" style="width: 215px">
                <span>一</span>&nbsp;专业云主机的技术优势
              </div>

              <p style="font-weight: 700">· 文华云机房</p>
              <p style="padding-left: 12px; margin-bottom: 10px" class="smallp">
                依托于文华的行情通道优势、运维服务优势、为客户提供高质量的云主机服务。
              </p>
              <p style="font-weight: 700">· 极速行情</p>
              <p style="padding-left: 12px; margin-bottom: 10px" class="smallp">
                千兆网络连接局域网内的文华行情服务器，有效降低行情延时、降低数据丢包率，提供高速的数据传输。
              </p>
              <p style="font-weight: 700">· 云端24小时运行量化模型</p>
              <p style="padding-left: 12px; margin-bottom: 10px" class="smallp">
                量化模型在文华云主机运行，不仅避免了电脑死机、断电等风险，而且降低滑点成本。
              </p>

              <p class="pimg">
                <img  style="margin-bottom: 25px" src="../../assets/images/yunzhuji_12.png" />
              </p>
               </div>
              <div v-if="form[1]">
              <div class="head4" id="productcenter1" style="width: 103px">
                <span>二</span>&nbsp;云配置
              </div>
              <!-- <br> -->
              <table
                width="600"
                border="0"
                cellpadding="5"
                cellspacing="1"
                bgcolor="#a6a6a6"
              >
                <tbody>
                  <tr>
                    <td width="80" height="30" rowspan="2" bgcolor="#0054c6">
                      型号
                    </td>
                    <td width="42" height="30" rowspan="2" bgcolor="#0054c6">
                      CPU
                    </td>
                    <td width="33" height="30" rowspan="2" bgcolor="#0054c6">
                      内存
                    </td>
                    <td width="52" height="30" rowspan="2" bgcolor="#0054c6">
                      数据盘
                    </td>
                    <td width="56" height="30" rowspan="2" bgcolor="#0054c6">
                      公网IP
                    </td>
                    <td height="30" colspan="2" bgcolor="#0054c6">带宽</td>
                    <td width="137" height="30" rowspan="2" bgcolor="#0054c6">
                      价格
                    </td>
                  </tr>
                  <tr>
                    <td width="47" height="30" bgcolor="#0054c6">
                      数据<br />
                      带宽
                    </td>
                    <td width="47" height="30" bgcolor="#0054c6">
                      维护<br />
                      带宽
                    </td>
                  </tr>
                  <tr>
                    <td height="30" bgcolor="#FFFFFF">云主机Ⅲ型</td>
                    <td height="30" bgcolor="#FFFFFF">四核</td>
                    <td height="30" bgcolor="#FFFFFF">8G</td>
                    <td height="30" bgcolor="#FFFFFF">150G</td>
                    <td height="30" bgcolor="#FFFFFF">1个</td>
                    <td height="30" bgcolor="#FFFFFF">1000M</td>
                    <td height="30" bgcolor="#FFFFFF">5M</td>
                    <td height="30" bgcolor="#FFFFFF">13800元/年/服务器</td>
                  </tr>
                  <tr>
                    <td height="30" bgcolor="#FFFFFF">云主机Ⅳ型</td>
                    <td height="30" bgcolor="#FFFFFF">八核</td>
                    <td height="30" bgcolor="#FFFFFF">16G</td>
                    <td height="30" bgcolor="#FFFFFF">150G</td>
                    <td height="30" bgcolor="#FFFFFF">1个</td>
                    <td height="30" bgcolor="#FFFFFF">1000M</td>
                    <td height="30" bgcolor="#FFFFFF">5M</td>
                    <td height="30" bgcolor="#FFFFFF">18000元/年/服务器</td>
                  </tr>
                </tbody>
              </table>
               </div>
              <div v-if="form[2]">
              <div class="head4" id="productcenter2" style="width: 85px">
                <span>三</span>&nbsp;购买
              </div>
              <p class="pproduct smallp">
                适用于
                <router-link to="/download">赢智（T8）</router-link>、<a
                  href="https://mq.wenhua.com.cn/Download/index"
                  target="_blank"
                  >库安（T9）</a
                >软件。
              </p>
              <p
                class="pproduct"
                style="color: #c00; font-size: 12px; padding-left: 5px"
              >
                购买文华云主机前请仔细阅读该<a
                  href="https://ftpc.wenhua.com.cn/files/guide/wh8-v8.2/download/入网责任书.docx"
                  >责任书</a
                >，文华不承担任何风险及责任。
              </p>
              <p class="pproduct">
                <a
                  href="https://pay.wenhua.com.cn/#/Detail/78"
                  target="_blank"
                  style="text-decoration: none"
                  >>>>详情查询网上支付</a
                >
              </p>
               </div>
              <div v-if="form[3]">
              <div class="head4" id="productcenter3" style="width: 120px">
                <span>四</span>&nbsp;上云步骤
              </div>
              <p class="pproduct" style="padding: 0">
                用户需在本地远程操控云主机，享受云服务，远程上云操作步骤说明<a
                  href="https://ftpc.wenhua.com.cn/files/guide/wh8-v8.2/download/云主机使用说明.docx"
                  >点击下载</a
                >
                ，下载安装好软件后首次上云，需按如下方式登录行情和交易:
              </p>
              <p class="pproduct">登录云行情</p>
              <p class="pproduct1">（1）配置服务器；</p>
              <p class="pproduct1">
                （2）选择极速服务器，按照所购买的云主机型号，选择对应的极速节点；
              </p>
              <p class="pproduct1">（3）输入行情账号密码，登录行情。</p>
              <p class="pimg">
                <img src="../../assets/images/yunzhuji_23.gif" />
              </p>
              <p class="pproduct">登录云交易</p>
              <p class="pproduct1">（1）点击【期货户】弹出交易登录界面；</p>
              <p class="pproduct1">（2）选择开户的期货公司；</p>
              <p class="pproduct1">
                （3）选择交易网关，按照所购买的云主机型号，选择对应的交易网关；
              </p>
              <p class="pproduct1">（4）登录交易。</p>
              <p class="pimg">
                <img
                  style="margin-bottom: 25px"
                  src="../../assets/images/yunzhuji_22.gif"
                />
              </p>
               </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: [
        true,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
      ],
      class1: "onclick",
      class2: "noclick",
      class3: "touming",
      class4: "yellow",
      class5: "noclickone",
      class6: "noclickele",
      scroll: "",
    };
  },
  methods: {
    custormAnchor(anchorName) {
      // // 找到锚点
      // let anchorElement = document.getElementById(anchorName);
      // if(anchorElement) {
      // this.$nextTick(()=>{
      //   window.scrollTo(0,anchorElement.offsetTop+50);

      // })
      // }
      var target = document.getElementById(anchorName);
      // target.parentNode.scrollTop = target.offsetTop;
      if (target !== null) {
        this.$refs.box.scrollTop = target.offsetTop;
      }
    },
    clickLi(num) {
      this.form.forEach((item, index) => {
        this.form[index] = false;
      });
      // console.log(this.form);
      this.form[num] = true;
      this.$forceUpdate();
      var id = "productcenter" + num;
      this.$router.push({ path: `/proc/productcenter2/${id}` });
      this.custormAnchor(id);
    },

    zero() {
      this.form.forEach((item, index) => {
        this.form[index] = false;
      });
    },

    menu(e) {
      if (
        e.target.scrollTop >=
          document.querySelector("#productcenter0").offsetTop &&
        document.querySelector("#productcenter1").offsetTop > e.target.scrollTop
      ) {
        this.zero();
        this.form[0] = true;
        this.$forceUpdate();
        var id1 = "productcenter0";
        this.$router.push({ path: `/proc/productcenter2/${id1}` });
      } else if (
        e.target.scrollTop >=
          document.querySelector("#productcenter1").offsetTop &&
        document.querySelector("#productcenter2").offsetTop > e.target.scrollTop
      ) {
        this.zero();
        this.form[1] = true;
        this.$forceUpdate();
        var id2 = "productcenter1";
        this.$router.push({ path: `/proc/productcenter2/${id2}` });
      } else if (
        e.target.scrollTop >=
          document.querySelector("#productcenter2").offsetTop &&
        document.querySelector("#productcenter3").offsetTop > e.target.scrollTop
      ) {
        this.zero();
        this.form[2] = true;
        this.$forceUpdate();
        var id3 = "productcenter2";
        this.$router.push({ path: `/proc/productcenter1/${id3}` });
      } else if (
        e.target.scrollTop >=
          document.querySelector("#productcenter3").offsetTop &&
        document.querySelector("#productcenter4").offsetTop > e.target.scrollTop
      ) {
        this.zero();
        this.form[3] = true;
        this.$forceUpdate();
        var id4 = "productcenter3";
        this.$router.push({ path: `/proc/productcenter2/${id4}` });
      } else {
        this.zero();
        this.form[4] = true;
        this.$forceUpdate();
        var id5 = "productcenter4";
        this.$router.push({ path: `/proc/productcenter2/${id5}` });
      }
    },
  },

  mounted() {
    // this.$refs.box.addEventListener("scroll", this.menu);
    this.custormAnchor(this.$route.params.id);
  },
};
</script>

<style scoped>
p {
  font-size: 15px;
  padding-top: 3px;
  padding-bottom: 3px;
  color: #727983;
  line-height: 25px;
  /* width: 640px; */
  letter-spacing: 1px;
}
.productcenterhtml {
  background: url("../../assets/images/yunzhuj.jpg") no-repeat;

  background-size: 100% 100%;
  min-width: 1400px;
  width: 100%;
  min-height:760px;
  /* padding-top: 120px; */
  /* background-position-y: 23px */
}
.productcenterhead {
  position: relative;
  height: 140px;
  width: 1000px;
  margin: 0 auto;
}
.productcenterheadtext {
  position: absolute;
  top: 105px;
  left: 3px;
}
.productcentercontent {
  background: url("../../assets/images/cpzx-top.png") no-repeat top;
  background-size: 100%;
  min-width: 1400px;
  width: 100%;
  /* min-height: 900px; */
  /* padding-top: 120px; */
}
.productcenterbody {
  width: 1000px;
  margin: 0 auto;
  /* height: 695px; */
  position: relative;
  padding-top: 110px;
}
.productcenterbodytag {
  width: 280px;
  height: 400px;
  border-radius: 2%;
  background: url("../../assets/images/yunzj.png") no-repeat center;
  background-size: 100% 100%;
  display: inline-block;
}
.productcenterbodytext {
  /* position: absolute;
  top: 150px;
  left: 210px; */
  width: 780px;
  min-height: 600px;
  border-top-left-radius: 1em;
  /* background: url("../../assets/images/ts-1_02.jpg") no-repeat center;
  background-size: 100% 100%; */
  background: #ebf2fc;
  /* overflow-y: auto;
  overflow-x: hidden; */
  filter: drop-shadow(1px 1px 2px #c2c5d4);
  box-shadow: -13px 0px rgb(65 97 153 / 10%);
  padding-top: 28px;
  display: inline-block;
  margin-left: -80px;
  margin-top: 30px;
  vertical-align: top;
}
.noclick {
  height: 41px;
  line-height: 41px;

  width: 220px;
  /* border-top: 1px solid #2f5476; */
  letter-spacing: 1px;
  color: #323f62;
  text-align: left;
  float: left;
  font-size: 14px;
  font-weight: normal;
  cursor: pointer;
}
.onclick {
  height: 42px;
  line-height: 42px;
  background: #ebf2fc;

  width: 220px;

  color: #323f62;
  text-align: left;
  letter-spacing: 1px;

  float: left;
  font-size: 14px;
  font-weight: normal;
  cursor: pointer;
}
.touming {
  width: 5px;
  height: 42px;
  float: left;
}
.yellow {
  width: 5px;
  height: 42px;
  background: #c49c71;
  float: left;
  border-top-left-radius: 0.1em;
  border-bottom-left-radius: 0.1em;
}
.head4 {
  font-size: 15px;
  /* padding-top: 20px; */
  /* padding-bottom: 10px; */
  /* font-weight: bold; */
  letter-spacing: 1px;
  height: 26px;
  line-height: 26px;
  background-color: #d6e5fc;
  color: #303d67;
  margin-top: 20px;
  margin-bottom: 20px;
}
.head4 span {
  color: #fff;
  /* padding-left: 5px; */
  display: inline-block;
  height: 26px;
  line-height: 26px;
  width: 45px;
  background: url("../../assets/images/yun.png") no-repeat left;
  background-size: 100%;
  /* text-indent: 1em; */
  text-align: center;
}
.midtext {
  /* height: 680px; */
  width: 680px;
  margin: 0 auto;
  /* padding-bottom: 100px; */
}
.pproduct {
  font-size: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  text-indent: 2em;
  color: #727983;
  line-height: 25px;
  /* width: 640px; */
  letter-spacing: 1px;
}
.pproduct1 {
  font-size: 15px;
  /* padding-top: 10px; */
  /* padding-bottom: 10px; */
  /* text-indent: 2em; */
  padding-left: 22px;
  color: #727983;
  line-height: 25px;
  /* width: 640px; */
  letter-spacing: 1px;
}
.pimg {
  padding-top: 10px;
  padding-bottom: 10px;
  min-height: 50px;
  width: 100%;
  text-align: center;
}
.noclick:hover {
  color: #e3a200;
}
.pli {
  width: 235px;
  height: 42px;
  /* margin-left: -5px; */
  position: relative;
  z-index: 88;
}
.noclickone {
  height: 41px;
  line-height: 41px;

  letter-spacing: 1px;
  width: 220px;

  /* border-top: 1px solid #2f5476; */
  color: #323f62;
  text-align: left;
  float: left;
  font-size: 14px;
  font-weight: normal;
  cursor: pointer;
}
.noclickele {
  height: 40px;
  line-height: 40px;

  letter-spacing: 1px;
  width: 220px;
  /* border-top: 1px solid #2f5476;
  border-bottom: 1px solid #2f5476; */
  color: #85c0ea;
  text-align: left;
  float: left;
  font-size: 14px;
  font-weight: normal;
  cursor: pointer;
}
.noclickone:hover {
  color: #323f62;
}
.noclickele:hover {
  color: #323f62;
}
.tagmid {
  width: 150px;
  height: 42px;
  float: left;
  font-weight: bold;
  line-height: 42px;
  border-bottom: 1px dashed #ccc;
}
.productcenterbodytag .title {
  display: block;
  height: 70px;
  color: #fff;
  font-weight: bold;
  text-indent: 2em;
  font-size: 16px;
}

td {
  height: 35px;
  border: 1px solid #cdcdcd;
  /* line-height: 35px; */
  vertical-align: middle;
  font-size: 14px;
  letter-spacing: 1px;
  padding-left: 0;
  text-align: center;
  color: #fff;
}
tr:nth-child(3) td,
tr:nth-child(4) td {
  color: #666;
}
.smallp {
  padding: 0;
  line-height: 20px;
}
</style>