import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

let token = sessionStorage.getItem('assets-token')

// 初次设置 jwt token header
axios.defaults.headers.common['Authorization'] = 'Bearer ' + token

const store = new Vuex.Store({
    state: {
        is_admin: !!token,
        token
    },
    mutations: {
        SetAuth(state, value) {
            state.is_admin = value
        },
        SetToken(state, value) {
            state.token = value

            // 后续设置 jwt token header
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + value
        }
    }
})

export default store