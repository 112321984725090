<template>
    <div class="res-login-container">
        <div class="res-login-area-wrap" v-if="ipAllow">
            <div class="res-login-area" :class="{ active: show, hide }" @click="click">{{ button_txt }}</div>
        </div>
        <transition name="res-login-fade">
            <div class="res-login-wrap" v-if="show">
                <Login />
            </div>
        </transition>
    </div>
</template>

<script>
import Login from "./Login";
import store from "../store";
import axios from 'axios'

export default {
    name: 'ResLogin',
    store,
    components: {
        Login,
    },
    data: () => ({
        show: false,
        hide: false,
        ipAllow: false
    }),
    computed: {
        button_txt() {
            if (this.$store.state.is_admin) return '点击退出'
            else return '点击登录'
        }
    },
    methods: {
        click() {
            if (!this.$store.state.is_admin) {
                this.show = true
            } else {
                this.hide = true
                setTimeout(() => this.hide = false, 300)
                this.$store.commit('SetAuth', false)
                sessionStorage.removeItem("assets-token")
            }
        },
        set_hide() {
            this.show = false;
        }
    },
    created(){
        // var xhr = new XMLHttpRequest();
        // xhr.open('POST', 'http://erp2.wenhua.com.cn/Erp/IpAllow');
        // xhr.send(null);
        // xhr.onload = function(e) {
        //     var xhr = e.target;
        //     console.log(xhr.responseText);
        // }
        axios.get('https://wh-api.wenhua.com.cn/api/IpAllow/IpAllow').then( res => {
            this.ipAllow = res.data ? true : false;
        }).catch( err => {
            this.ipAllow = false
            console.log('err: '+ err)
        })
    }
}
</script>

<style scoped src="./style.css"></style>