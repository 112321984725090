<template>
  <div class="beijing">
    <div class="beijtable"  v-if="page.page1">
      <table>
        <tr>
          <td
            style="
              width: 620px;
              background: #e2e8f4;
              text-align: center;
              color: #454d60;
              padding-left: 0px;
            "
          >
            公司名称
          </td>
        </tr>
        <tr>
          <td>北京木叶山资产管理有限公司</td>
        </tr>
        <tr>
          <td>天玑创投（北京）投资基金管理有限公司</td>
        </tr>
        <tr>
          <td>北京鸣谦资产管理有限公司</td>
        </tr>
        <tr>
          <td>北京新湖巨源投资管理有限公司</td>
        </tr>
        <tr>
          <td>北京铼金资产管理有限公司</td>
        </tr>
        <tr>
          <td>北京易达天下资产管理有限公司</td>
        </tr>
        <tr>
          <td>北京中诚融信投资管理有限公司</td>
        </tr>
        <tr>
          <td>北京禾晟源投资管理有限公司</td>
        </tr>
        <tr>
          <td>北京上国象投资管理有限公司</td>
        </tr>
        <tr>
          <td>北京鸥鹏融汇投资管理有限公司</td>
        </tr>
        <tr>
          <td>启辰金航资产管理（北京）有限公司</td>
        </tr>
      </table>
    </div>
    <div class="beijtable2" v-if="page.page2">
      <table>
        <tr>
          <td
            style="
              width: 620px;
              background: #e2e8f4;
              text-align: center;
              color: #454d60;
              padding-left: 0px;
            "
          >
            公司名称
          </td>
        </tr>
        <tr>
          <td>兴禾嘉业（北京）投资基金管理有限公司</td>
        </tr>
        <tr>
          <td>启辰金航资产管理（北京）有限公司</td>
        </tr>
        <tr>
          <td>北京中成信泰资产管理有限责任公司</td>
        </tr>
      </table>
    </div>
    <div class="successcomfoot">
      <div v-bind:class="[page.page1 ? class1 : class2]" @click="showp1">1</div>
      <div v-bind:class="[page.page2 ? class1 : class2]" @click="showp2">2</div>
      共2页，13条</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      page: {
        page1: true,
        page2: false,
      },
      class2: "pagebutton",
      class1: "pageclickbutton",
    };
  },
  methods: {
    showp1() {
      Object.keys(this.page).forEach((item) => {
        this.page[item] = false;
      });
      this.page.page1 = true;
    },
    showp2() {
      Object.keys(this.page).forEach((item) => {
        this.page[item] = false;
      });
      this.page.page2 = true;
    },
  },
};
</script>

<style >
td {
  height: 35px;
  border: 1px solid #cdcdcd;
  line-height: 35px;
  vertical-align: middle;
  font-size: 14px;
  padding-left: 40px;
  letter-spacing: 1px;
}
.successcomfoot {
  width: 620px;
  margin-left: 0px;
  height: 40px;
  margin-top: 20px;
  text-align: right;
}
</style>