import { render, staticRenderFns } from "./pingjia.vue?vue&type=template&id=3cb79eaa&"
import script from "./pingjia.vue?vue&type=script&lang=js&"
export * from "./pingjia.vue?vue&type=script&lang=js&"
import style0 from "./pingjia.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports