import VueRouter from 'vue-router'
import Vue from 'vue'

import buy from '../components/components/buy_new'
import buytest from '../components/components/buytest'
import videoClass from '../components/components/videoClass'
import buyM from '../components/components/buyM'

import about from '../components/components/about'
import download from '../components/components/download'
// import learningMaterials from '../components/components/learningMaterials'

// import downloadM from '../components/components/downloadM'
import productcenter from '../components/components/productcenter'
import productcenter1 from '../components/components/productcenter1'
import productcenter2 from '../components/components/productcenter2'
import serive from '../components/components/serive'
import wwt from '../components/components/wwtService'
import seriveM from '../components/components/serviceM'
import myFinEng from '../components/components/myFinEng'
// import success from '../components/components/success'
import trade from '../components/components/trade'
import aboveall from '../components/components/aboveall'
import proc from '../components/components/proc'
// import softAdvantage from '../components/components/softAdvantage'
import shengjishuoming from '../components/components/shengjishuoming'
import succ from '../components/components/succ'
Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const routes = [

  {
    path: "/aboveall",
    component: aboveall,
    name: "aboveall",

  },
  {
    path: "/buytest",
    component: buytest,
    name: "buytest",

  },
  {
    path: "/buy",
    component: buyM,
    name: "buy",
    children: [
      {
        path: "buy",
        component: buy,
        name: "buy",
      },
      // {
      //   path: "videoClass",
      //   component: videoClass,
      //   name: "videoClass",
      // },
      {
        path: "",
        component: buy,
        name: "buy",
      },
    ]
  },
  {
    path: "/download",
    component: download,
    name: "download",
    children: [{
      path: "download/:id",
      component: download,
      name: "download",
      }
    ]
    // children: [
    //   {
    //     path: "download/:id",
    //     component: download,
    //     name: "download",
    //   },
    //   {
    //     path: "softAdvantage",
    //     component: softAdvantage,
    //     name: "softAdvantage",
    //   },
    //   {
    //     path: "learningMaterials",
    //     component: learningMaterials,
    //     name: "learningMaterials",
    //   },
    //   {
    //     path: "",
    //     component: download,
    //     name: "download",
    //   },
    // ]
  },
  {
    path: "/proc",
    component: proc,
    name: "proc",
    children: [
      {
        path: "productcenter/:id",
        component: productcenter,
        name: "productcenter",
      },
      {
        path: "productcenter1/:id",
        component: productcenter1,
        name: "productcenter1",
      },
      {
        path: "productcenter2/:id",
        component: productcenter2,
        name: "productcenter2",
      },
      {
        path: "shengjishuoming",
        component: shengjishuoming,
        name: "shengjishuoming",
      },
      {
        path: "",
        component: productcenter,
        name: "proc",
      },
    ]
  },

  {
    path: "/serive",
    component: seriveM,
    name: "serive",
    children: [
      {
        path: "serive",
        component: serive,
        name: "serive",
      },
      // {
      //   path: "myFinEng",
      //   component: myFinEng,
      //   name: "myFinEng",
      // },
      {
        path: "wwt",
        component: wwt,
        name: "wwt",
      },
      // {
      //   path: "",
      //   component: serive,
      //   name: "serive",
      // },
    ]
  },
  {
    path: "/success",
    component: succ,
    name: "success"
  },
  {
    path: "/trade",
    component: trade,
    name: "trade"
  },
  {
    path: "/about",
    component: about,
    name: "about",
    children: [
      {
      path: "/about/:id",
      component: about,
      name: "about",
    }
    ]
  },
  { path: '*', redirect: '/aboveall' }


]

const router = new VueRouter({
  mode: 'hash',

  routes
})
export default router