<template>
  <div class="videoClass">
    <div class="videoClasshtml">
      <div class="content">
        <p class="title">模型编写视频教程</p>
        <div class="jieshao" style="margin-top: 20px">
          <p class="module_tit">
            <span>介绍</span>
          </p>
          <p class="p_text" style="margin-top: 20px">
            本教程主要针对初级量化交易自学者定制，自学时对于不理解的知识点可通过视频反复回放学习；或到在线客服（按下图所示方法进入在线客服）中提问。通过视频、教程（纸质PPT）、在线客服相结合的学习方式会让初学者很快掌握初级量化交易的应用。
          </p>
          <div class="pic">
            <img src="../../assets/images/lunttw.png" alt="" />
          </div>
          <p class="p_text" style="margin-top: 20px">
            本教程以视频为主，并配有PPT知识点学习指导和函数说明书。视频从认识量化交易、建立量化模型、运用软件实施量化交易等多个方面讲解，且每个方面都结合了大量的模型案例进行由浅入深的分析。每个章节按了解概念->讲述方法->指导编写->分析案例的过程分步展开。
          </p>
          <p class="p_text" style="margin-top: 20px">
            课程内容由模型的基本结构、跨指标、跨周期、跨合约、止损模型的编写到分组指令的编写，穿插模型实例使讲解更容易理解，另外还会对量化策略模型设计、回测等进行全面详细解析。函数说明书包含了所有麦语言现有的函数，并从函数意义、语法规则、用法举例三个方面对函数详解。
          </p>

          <div class="maiyuy">
            <div class="videoContent">
              <p>模型编写视频教程包含以下内容：</p>
              <ul>
                <li>麦语言量化应用培训基础班讲义（150页）</li>
                <li>麦语言函数手册（172页）</li>
                <li>U盘（教学视频时长：9:52'，Mp4格式）</li>
              </ul>
            </div>
          </div>
        </div>

        <div class="kechen" style="margin-top: 20px">
          <p class="module_tit">
            <span>课程目录</span>
          </p>
          <table
            width="720 "
            align="center "
            cellpadding="0 "
            cellspacing="0 "
            style="margin: 30px auto;background: rgb(249,248,247) ;"
          >
            <tbody>
              <tr>
                <td class="tabt r1" colspan="2 ">课程内容</td>
                <td class="tabt" style="text-indent:0;padding-left:9px">时长（分钟）</td>
                <td class="tabt" width="100 " style="text-indent:0;text-align:center;">课程难度</td>
              </tr>
              <tr>
                <td class="t1" width="70 ">第一讲</td>
                <td class="t1" width="350 ">平台、语法、操作符、函数（一）</td>
                <td class="t1" width="90 " style="text-align: center">35</td>
                <td class="t1 r" width="90 ">★</td>
              </tr>
              <tr>
                <td>第二讲</td>
                <td>平台、语法、操作符、函数（二）</td>
                <td style="text-align: center">30</td>
                <td class="r">★</td>
              </tr>
              <tr>
                <td>第三讲</td>
                <td>指标的编写（一）</td>
                <td style="text-align: center">38</td>
                <td class="r">★</td>
              </tr>
              <tr>
                <td>第四讲</td>
                <td>指标的编写（二）</td>
                <td style="text-align: center">43</td>
                <td class="r">★</td>
              </tr>
              <tr>
                <td>第五讲</td>
                <td>指令与模型基本结构</td>
                <td style="text-align: center">36</td>
                <td class="r">★</td>
              </tr>
              <tr>
                <td>第六讲</td>
                <td>模型类型解析</td>
                <td style="text-align: center">30</td>
                <td class="r">★</td>
              </tr>
              <tr>
                <td>第七讲</td>
                <td>从技术分析到量化交易：跨指标模型的编写</td>
                <td style="text-align: center">45</td>
                <td class="r">★★</td>
              </tr>
              <tr>
                <td>第八讲</td>
                <td >从技术分析到量化交易：跨周期（合约）模型的编写</td>
                <td style="text-align: center">48</td>
                <td class="r">★★</td>
              </tr>
              <tr>
                <td>第九讲</td>
                <td>分组指令的编写</td>
                <td style="text-align: center">35</td>
                <td class="r">★★</td>
              </tr>
              <!--<tr>
						<td class="bo ">第十讲</td>
						<td class="bo ">日内交易模型和tick模型的编写</td>
						<td class="r bo ">编写难度★★★</td>
					</tr>-->
              <tr>
                <td class="bo">第十讲</td>
                <td class="bo">日内交易模型编写</td>
                <td style="text-align: center">50</td>
                <td class="r bo">★★★</td>
              </tr>
              <tr>
                <td>第十一讲</td>
                <td>止损模型原理及编写</td>
                <td style="text-align: center">52</td>
                <td class="r">★★★</td>
              </tr>
              <tr>
                <td>第十二讲</td>
                <td>资金管理</td>
                <td style="text-align: center">40</td>
                <td class="r">★★★</td>
              </tr>
              <tr>
                <td>第十三讲</td>
                <td>运行优化函数</td>
                <td style="text-align: center">44</td>
                <td class="r">★★★★</td>
              </tr>
              <!-- <tr>
                <td>第十四讲</td>
                <td>策略优化函数（二）</td>
                <td style="text-align: center">44</td>
                <td class="r">★★★★</td>
              </tr> -->
              <tr>
                <td>第十四讲</td>
                <td>IF THEN 语句与全局变量</td>
                <td style="text-align: center">66</td>
                <td class="r">★★★★★</td>
              </tr>
            </tbody>
          </table>
          <div style="width: 720px; margin: 0 auto">
            <p class="zhu">
              <span> * 注 </span>
            </p>
            <p class="p_text" style="color: #171b36; text-indent: 0;width: 760px;">
              U盘是消耗品仅作为视频载体邮寄，在收到U盘后请尽快以其它方式保存视频，我公司不负责U盘售后服务。
            </p>
            <div style="height: 50px; margin-top: 10px">
              <button class="buy"><a href="https://pay.wenhua.com.cn/#/detail/44" target="_blank">去购买</a></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "videoClass",
};
</script>
<style  scoped>
.videoClass {
  background: url("../../assets/images/buy-top.png") no-repeat top;
  background-size: 100% auto;
  min-width: 1400px;
  width: 100%;
  min-height: 1212px;
  height: 100%;
  padding-top: 110px;
}
.videoClasshtml {
  width: 1000px;
  margin: 0 auto;
  background: #f5f1ee;
  /* min-height: 900px; */
}
.videoClass .content {
  min-height: 537px;
  background: url("../../assets/images/jsbg.jpg") no-repeat top;
  padding: 0 50px 20px 50px;
}
.title {
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  line-height: 40px;
  color: #fff;
}
.kechen .module_tit span {
  display: block;
  background: url("../../assets/images/bq1.png") no-repeat;
  background-size: 100% 30px;
  width: 80px;
  height: 30px;
  line-height: 30px;
  color: #fff;
  text-align: center;
}
.jieshao .module_tit span {
  display: block;
  background: url("../../assets/images/bq1.png") no-repeat;
  width: 63px;
  height: 30px;
  line-height: 30px;
  color: #fff;
  text-align: center;
}
.zhu span {
  display: block;
  background: #405189;
  border-radius: 0 15px 15px 0;
  width: 63px;
  height: 30px;
  line-height: 30px;
  color: #fff;
  text-align: center;
}
.p_text {
  text-indent: 2em;
  font-size: 15px;
  color: #555;
  line-height: 28px;
  letter-spacing: 1px;
}
.pic {
  text-align: center;
  padding: 20px 0;
}
td {
  height: 35px;
  border: 1px solid #cdcdcd;
  line-height: 35px;
  vertical-align: middle;
  font-size: 15px;
  letter-spacing: 1px;
  color: #3e6493;
  padding-left: 0;
}
tr:nth-child(1){
  background: #3e6493;
  
}
tr:nth-child(1) td{
  color: #fff;
}
tr td:nth-child(1) {
  text-align: center;
}
tr td:nth-child(2) {
  text-indent: 2em;
}
tr td:nth-child(4) {
  text-indent: 1em;
}
.buy {
  text-align: center;
  color: #fff;
  font-weight: 700;
  line-height: 40px;
  border: none;
  border-radius: 5px;
  letter-spacing: 1px;
  background: url("../../assets/images/buy.png") no-repeat center;
  /* display: inline-block; */
  width: 150px;
  height: 40px;
  font-size: 18px;
  float: right;
}
.buy a{
  color: #fff;
  text-decoration: none;
}
.maiyuy {
  background: url(../../assets/images/maiyuy.png) no-repeat;
  width: 100%;
  height: 250px;
  background-position-x: 100px;
  background-position-y: 10px;
  padding-top: 50px;
}
.videoContent{
  margin-left: 530px;
  /* margin-top: 100px; */
}
.videoContent p {
  font-size: 16px;
  font-weight: 700;
  color: #666;
  line-height: 32px;
  letter-spacing: 1px;
}
.videoContent ul li {
       font-size: 12px;
    line-height: 18px;
    color: #474c52;
    list-style-type: none;
    list-style-image: none;
    /* background-image: url(../images/listyle.jpg); */
    background-repeat: no-repeat;
    background-position: left 6px;
    /* padding-left: 10px; */
    padding-bottom: 10px;
 }
 .videoContent ul li::before{
   content: " ";
   width: 7px;
   height: 7px;
   background: #b68e6a;
   display: inline-block;
   vertical-align: middle;
   margin-right: 3px;
 }
</style>