<template>
  <div class="serivehtml">
    <div class="content">
      <div class="wwtPic">
        <p class="title">
          文文通<span>VIP客服软件</span>
        </p>
        <p class="cont">与文华wt9、WT8紧密结合的一款辅助交流工具，除具有一般IM软件的基本功能外，还提供页面、指标、模型的传送功能。</p>
      </div>
      <div>
        <div class="tips">功能特色</div>
        <div style="padding: 0 60px 40px 60px">
          <div class="leftP">
            <div>期货人的网上社区</div>
            <p>
              期货人交流、分享的首选的IM软件，可与期友方便的传输软件页面、指标、交易模型等文件，建群共享学习资料。
            </p>
          </div>
          <div class="rightP">
            <img src="../../assets/images/wwt1.png" alt="" />
          </div>
          <div style="width:100%;height:1px;border-bottom:1px dashed #cacdd4;margin-top: 30px;"></div>
          <div class="rightP" style="padding: 20px; margin-top: 50px">
            <img src="../../assets/images/wwt2.png" alt="" />
          </div>
          <div class="leftP" style="padding-left: 0; margin-top: 20px">
            <div>高级学堂、提供专业服务</div>
            <p>
              与高级金融工程师对话，对模型的编写、测算、优化、运行过程中遇到的问题提供实时指导与解析。
            </p>
          </div>
        </div>
      </div>
      <div style="background:#f6f6f6;position:relative;height:180px;">
        <div class="tips" style="position: absolute;top: -20px;">下载文文通</div>
        <div >
        <div class="leftP" style="width: 50%;padding-top:40px;">
          <div>如何获取文文通登录账号</div>
          <p style="text-indent: 0;width:520px;">
            购买wt9、WT8授权同时获得文文通服务，文文通软件登录账号同wt9/WT8授权账号，详询400-811-3366。
          </p>
        </div>
        <div class="rightP loadBtn" style="width: 36%;margin-left:40px;padding-top:40px;">
          <div style="overflow: hidden; width: 100%">
            <a
              href="https://ftpc.wenhua.com.cn/files/WWT/wwt_setup_3.5.5.exe"
              class="dianx"
              >电信下载</a
            >
            <a
              href="https://ftpc1.wenhua.com.cn/files/WWT/wwt_setup_3.5.5.exe"
              class="liant"
              >联通下载</a
            >
          </div>
          <div class="lTxt">版本：3.5.5&nbsp;大小：3.53MB</div>
        </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "wwt",
};
</script>
<style scoped>
.serivehtml {
  background: url("../../assets/images/vip-top.png") no-repeat top;
  background-size: 100% auto;
  min-height: 839px;
  min-width: 1400px;
  width: 100%;
  padding-top: 110px;
}
.serivehtml .content {
  width: 1000px;
  margin: 0 auto;
  /* margin-top: 100px; */
  min-height: 600px;
  background: #fff;
  /* border: 1px solid #92afeb; */
  border-radius: 10px 10px 0 0;
  /* padding: 50px; */
  padding-top: 50px;
}
.wwtPic {
  width: 100%;
  background: url("../../assets/images/wwt-top.png") no-repeat top center;
  height: 90px;
  padding-top: 120px;
  margin-bottom: 20px;
}

.tips {
  /* margin-top: 50px; */
  letter-spacing: 1px;
  background: url("../../assets/images/biaoq.png") no-repeat left;
  margin-left: -9px;
  color: white;
  height: 40px;
  width: 110px;
  text-align: center;
  line-height: 50px;
  margin-bottom: 10px;
}
.leftP {
  width: 45%;
  padding: 20px 40px 20px 40px;
  display: inline-block;
  vertical-align: middle;
}
.leftP div {
  color: #1f3668;
  font-weight: 700;
  height: 40px;
  line-height: 40px;
}
.leftP p {
  text-indent: 2em;
  line-height: 28px;
  color: #828282;
}
.rightP {
  width: 45%;
  /* padding: 20px 40px 20px 40px; */
  display: inline-block;
  vertical-align: middle;
}

.loadBtn a {
  display: block;
  width: 130px;
  height: 40px;
  color: #fff;
  background: #fd8d1f;
  border: 0;
  font-size: 15px;
  text-decoration: none;
  text-align: center;
  line-height: 40px;
  float: left;
}

.loadBtn .liant {
  margin-left: 40px;
  background: #3a84ff;
}

.lTxt {
  font-size: 15px;
  margin-top: 10px;
}
.wwtPic .title{
  text-align: center;
  color: #474747;
  font-size: 25px;
  font-weight: 700;
  position: relative;
  letter-spacing: 1px;
}
.wwtPic .title span{
  position: absolute;
  top: -10px;
  font-size: 15px;
  font-weight: normal;
  color: #fff;
  background: #b58f67;
  height: 22px;
  line-height: 22px;
  padding: 0 5px;
  border-radius: 5px;
  letter-spacing: 0;

}
.wwtPic .cont{

  width: 800px;
  margin: 0 auto;
  font-size: 16px;
  line-height: 30px;
  text-indent: 2em;
  letter-spacing: 1px;
  color: #828282;
  margin-top: 5px;
}
</style>