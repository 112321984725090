<template>
  <div class="productcenter1html">
    
    <div class="productcenter1body">
      <div class="productcenter1bodytext">
        <div
          style="
        
            min-height: 680px;
            border-radius: 2%;
            position: relative;
          "
        >
          <div class="midtext">
              <div class="title">
              <p>升级说明</p>
            </div>
            <div class="choose">
              <div class="ptag">项目:</div>
              <ul class="xiangmu" @click.stop="xialaitem" id="xiangmutag">
                <div class="xiasanjiao"></div>
                <li class="xiangmuli">{{ a.itemName }}</li>
                <div v-if="showxm" class="shengjili">
                  <li
                    v-for="(item, index) in itemlist"
                    v-bind:key="index"
                    class="xiangmuli"
                    @click.stop="getitemname(item)"
                    :class="{ yincang: index == 0 || index == 9 }"
                  >
                    {{ item.itemName }}
                  </li>
                </div>
                <div style="border-bottom: 1px solid #c4c7cb"></div>
              </ul>
              <div class="ptag">类别:</div>
              <ul class="leibie" @click.stop="xialatype" id="leibietag">
                <div class="xiasanjiao"></div>
                <li class="xiangmuli">{{ b.iTypeName }}</li>
                <div v-if="showlb" class="shengjili">
                  <li
                    v-for="(item, index) in typelist"
                    v-bind:key="index"
                    class="xiangmuli"
                    @click.stop="gettypename(item)"
                    :class="{ yincang: index == 0 }"
                  >
                    {{ item.iTypeName }}
                  </li>
                </div>
                <div style="border-bottom: 1px solid #c4c7cb"></div>
              </ul>
              <div class="jiansuo" @click="jiansuo">检&nbsp;索</div>
            </div>

            <div class="banbencx">
              <div
                v-for="(item, index) in banbendata"
                v-bind:key="index"
                class="text"
              >
                <p class="verstime">
                  Ver{{ item.version }}&nbsp;&nbsp;&nbsp;({{ item.vtime }})
                </p>
                <p
                  v-for="(txt, idx) in item.versionUpdateDtos"
                  v-bind:key="idx"
                  class="verstext"
                >
                  {{ idx + 1 }}.【{{ txt.uitem }}】&nbsp;&nbsp;&nbsp;【{{
                    txt.utype
                  }}】{{ txt.description }}
                </p>
              </div>
            </div>
            <div style="height: 60px; width: 900px; margin: 0 auto">
              <span class="pagetotal"
                >共{{ pagetotal }}页，{{ commentcount }}条</span
              ><span
                class="pagebt"
                v-bind:class="[lastpoint.f ? class3 : class2]"
                @click="turnrg"
                >&gt;</span
              ><span
                v-if="forthbt"
                v-bind:class="[changeforthbt.f ? class1 : class2]"
                @click="changep4"
                >{{ four }} </span
              ><span
                v-if="thirdbt"
                v-bind:class="[changethirdbt.f ? class1 : class2]"
                @click="changep3"
                >{{ three }}</span
              ><span
                v-if="secondbt"
                v-bind:class="[changesecondbt.f ? class1 : class2]"
                @click="changep2"
                >{{ two }}</span
              ><span
                v-if="firstbt"
                v-bind:class="[changefirstbt.f ? class1 : class2]"
                @click="changep1"
                >{{ one }}</span
              ><span
                class="pagebt"
                v-bind:class="[changefirstbt.f ? class3 : class2]"
                @click="turnlf"
                >&lt;</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { jsonp } from 'vue-jsonp'
import axios from "axios";
import { stateform } from "../../js/request.js";
export default {
  data() {
    return {
      pagetotal: "",
      commentcount: "",
      typeid: -1,
      banbendata: [],
      itemid: -1,
      s: {},
      datass: [],
      itemlist: {},
      typelist: {},
      a: {},
      b: {},
      page: 1,
      showdetail: {
        showone: true,
        showtwo: true,
        showthree: true,
        showfour: true,
        showfive: true,
      },
      one: 1,
      two: 2,
      three: 3,
      four: 4,
      firstbt: true,
      secondbt: true,
      thirdbt: true,
      forthbt: true,
      changefirstbt: { f: true },
      changesecondbt: { f: false },
      changethirdbt: { f: false },
      changeforthbt: { f: false },
      class2: "pagebt",
      class1: "clickpagebt",
      lastpoint: "",
      class3: "notclick",
      showxm: false,
      showlb: false,
    };
  },
  methods: {
    async getxiala() {
      this.s = await stateform();

      this.itemlist = this.s.itemListDtos;
      this.typelist = this.s.typeListDtos;
      this.a = this.itemlist[0];
      this.b = this.typelist[0];
      var st = document.getElementById("xiangmutag").style;
      st.height = (this.itemlist.length - 1) * 21 + 1 + "px";
      var st1 = document.getElementById("leibietag").style;
      st1.height = this.typelist.length * 21 + 1 + "px";
    },
    xialaitem() {
      this.showlb = false;
      this.showxm = !this.showxm;
    },
    getitemname(it) {
      this.a = it;
      // this.$set(this.itemlist,0,it)
      this.itemid = it.itemId;

      this.showlb = false;
      this.showxm = false;
    },
    xialatype() {
      this.showxm = false;
      this.showlb = !this.showlb;
    },
    gettypename(it) {
      this.b = it;
      // this.$set(this.itemlist,0,it)
      this.typeid = it.iTypeId;
      this.showlb = false;
      this.showxm = false;
    },
    jiansuo() {
      // var params={
      //   appid:16,
      //   item:this.itemid,
      //   type:this.typeid,
      //   isall:false,
      // }
      // console.log(params)
      // this.datass=await banben(params)
      // console.log(this.datass)
      // console.log(this.itemid)
      // console.log(this.typeid)
      axios
        .get("//wh-api.wenhua.com.cn/api/Version/GetVersion", {
          params: {
            appid: 11,
            item: this.itemid,
            type: this.typeid,
            isall: false,
          },
        })
        .then((res) => {
          this.datass = [];
          this.banbendata = [];

          this.datass = res.data;
          this.pagetotal = Math.ceil(this.datass.length / 6);
          this.commentcount = this.datass.length;
          this.getdata();
          (this.one = 1),
            (this.two = 2),
            (this.three = 3),
            (this.four = 4),
            (this.changesecondbt.f = false);
          this.changethirdbt.f = false;
          this.changeforthbt.f = false;
          this.changefirstbt.f = true;
          this.changep1();
          this.pagefc();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    pagefc() {
      this.firstbt = true;
      this.secondbt = true;
      this.thirdbt = true;
      this.forthbt = true;
      if (Math.ceil(this.commentcount / 6) == 1) {
        this.secondbt = false;
        this.thirdbt = false;
        this.forthbt = false;
        this.lastpoint = this.changefirstbt;
      } else if (Math.ceil(this.commentcount / 6) == 2) {
        this.thirdbt = false;
        this.forthbt = false;
        this.lastpoint = this.changesecondbt;
      } else if (Math.ceil(this.commentcount / 6) == 3) {
        this.forthbt = false;
        this.lastpoint = this.changethirdbt;
      } else if (Math.ceil(this.commentcount / 6) > 3) {
        this.lastpoint = this.changeforthbt;
      } else {
        this.secondbt = false;
        this.thirdbt = false;
        this.forthbt = false;
        this.lastpoint = this.changefirstbt;
      }
    },

    panduanitem(t) {
      for (var a = 0; a < t.versionUpdateDtos.length; a++) {
        if (
          t.versionUpdateDtos[a].uitem == 1 ||
          t.versionUpdateDtos[a].uitem == "行情"
        ) {
          t.versionUpdateDtos[a].uitem = "行情";
        } else if (
          t.versionUpdateDtos[a].uitem == 2 ||
          t.versionUpdateDtos[a].uitem == "图表"
        ) {
          t.versionUpdateDtos[a].uitem = "图表";
        } else if (
          t.versionUpdateDtos[a].uitem == 3 ||
          t.versionUpdateDtos[a].uitem == "新闻"
        ) {
          t.versionUpdateDtos[a].uitem = "新闻";
        } else if (
          t.versionUpdateDtos[a].uitem == 4 ||
          t.versionUpdateDtos[a].uitem == "交易"
        ) {
          t.versionUpdateDtos[a].uitem = "交易";
        } else if (
          t.versionUpdateDtos[a].uitem == 5 ||
          t.versionUpdateDtos[a].uitem == "量化"
        ) {
          t.versionUpdateDtos[a].uitem = "量化";
        } else if (
          t.versionUpdateDtos[a].uitem == 6 ||
          t.versionUpdateDtos[a].uitem == "套利"
        ) {
          t.versionUpdateDtos[a].uitem = "套利";
        } else if (
          t.versionUpdateDtos[a].uitem == 7 ||
          t.versionUpdateDtos[a].uitem == "通讯"
        ) {
          t.versionUpdateDtos[a].uitem = "通讯";
        } else if (
          t.versionUpdateDtos[a].uitem == 8 ||
          t.versionUpdateDtos[a].uitem == "云端"
        ) {
          t.versionUpdateDtos[a].uitem = "云端";
        } else if (
          t.versionUpdateDtos[a].uitem == 9 ||
          t.versionUpdateDtos[a].uitem == "算法交易"
        ) {
          t.versionUpdateDtos[a].uitem = "算法交易";
        } else {
          t.versionUpdateDtos[a].uitem = "其它";
        }
      }
    },

    panduantype(t) {
      for (var a = 0; a < t.versionUpdateDtos.length; a++) {
        if (
          t.versionUpdateDtos[a].utype == 1 ||
          t.versionUpdateDtos[a].utype == "BUG修改"
        ) {
          t.versionUpdateDtos[a].utype = "BUG修改";
        } else if (
          t.versionUpdateDtos[a].utype == 2 ||
          t.versionUpdateDtos[a].utype == "功能完善"
        ) {
          t.versionUpdateDtos[a].utype = "功能完善";
        } else if (
          t.versionUpdateDtos[a].utype == 3 ||
          t.versionUpdateDtos[a].utype == "新增功能"
        ) {
          t.versionUpdateDtos[a].utype = "新增功能";
        } else if (
          t.versionUpdateDtos[a].utype == 4 ||
          t.versionUpdateDtos[a].utype == "操作习惯"
        ) {
          t.versionUpdateDtos[a].utype = "操作习惯";
        } else {
          t.versionUpdateDtos[a].utype = "其它";
        }
      }
    },
    getdata() {
      this.banbendata = [];
      if (this.datass.length > this.page * 6) {
        for (var count = 0; count < 6; count++) {
          this.$set(
            this.banbendata,
            count,
            this.datass[(this.page - 1) * 6 + count]
          );
          this.panduanitem(this.banbendata[count]);
          this.panduantype(this.banbendata[count]);
          // console.log(this.banbendata[count].vtime)
          // console.log(this.datass)
          this.banbendata[count].vtime = this.banbendata[count].vtime.split(
            "T"
          )[0];
          // console.log(this.banbendata)
        }
      } else {
        for (
          var count1 = 0;
          count1 < this.datass.length - (this.page - 1) * 6;
          count1++
        ) {
          // console.log(this.banbendata[count].vtime)
          this.$set(
            this.banbendata,
            count1,
            this.datass[(this.page - 1) * 6 + count1]
          );
          this.panduanitem(this.banbendata[count1]);
          this.panduantype(this.banbendata[count1]);

          this.banbendata[count1].vtime = this.banbendata[count1].vtime.split(
            "T"
          )[0];
        }
      }
    },
    changep1() {
      if (this.one == 1) {
        this.changesecondbt.f = false;
        this.changethirdbt.f = false;
        this.changeforthbt.f = false;
        this.changefirstbt.f = true;
        this.two = 2;
        this.three = 3;
        this.four = 4;
        this.page = this.one;
        this.getdata();
      } else if (this.one == 2) {
        this.changesecondbt.f = true;
        this.changethirdbt.f = false;
        this.changeforthbt.f = false;
        this.changefirstbt.f = false;
        this.one = 1;
        this.two = 2;
        this.three = 3;
        this.four = 4;
        this.page = this.two;
        this.getdata();
      } else {
        this.changesecondbt.f = false;
        this.changethirdbt.f = true;
        this.changeforthbt.f = false;
        this.changefirstbt.f = false;
        this.three = this.three - 2;
        this.two = this.two - 2;
        this.four = this.four - 2;
        this.one = this.one - 2;
        this.page = this.three;
        this.getdata();
      }
    },
    changep2() {
      if (this.two == 2) {
        this.changesecondbt.f = true;
        this.changethirdbt.f = false;
        this.changeforthbt.f = false;
        this.changefirstbt.f = false;
        this.one = 1;
        this.two = 2;
        this.three = 3;
        this.four = 4;
        this.page = this.two;
        this.getdata();
      } else {
        this.changesecondbt.f = false;
        this.changethirdbt.f = true;
        this.changeforthbt.f = false;
        this.changefirstbt.f = false;
        this.three = this.three - 1;
        this.two = this.two - 1;
        this.four = this.four - 1;
        this.one = this.one - 1;
        this.page = this.three;
        this.getdata();
      }
    },

    changep3() {
      this.changesecondbt.f = false;
      this.changethirdbt.f = true;
      this.changeforthbt.f = false;
      this.changefirstbt.f = false;
      this.page = this.three;
      this.getdata();
    },
    changep4() {
      if (this.three == Math.ceil(this.commentcount / 6) - 1) {
        this.changesecondbt.f = false;
        this.changethirdbt.f = false;
        this.changeforthbt.f = true;
        this.changefirstbt.f = false;

        this.page = this.four;
        this.getdata();
      } else {
        this.changesecondbt.f = false;
        this.changethirdbt.f = true;
        this.changeforthbt.f = false;
        this.changefirstbt.f = false;
        this.three = this.three + 1;
        this.two = this.two + 1;
        this.four = this.four + 1;
        this.one = this.one + 1;
        this.page = this.three;
        this.getdata();
      }
    },
    turnlf() {
      if (this.changesecondbt.f == true) {
        this.changesecondbt.f = false;
        this.changethirdbt.f = false;
        this.changeforthbt.f = false;
        this.changefirstbt.f = true;
        this.one = 1;
        this.two = 2;
        this.three = 3;
        this.four = 4;

        this.page = this.one;
        this.getdata();
      } else if (this.changeforthbt.f == true) {
        this.changesecondbt.f = false;
        this.changethirdbt.f = true;
        this.changeforthbt.f = false;
        this.changefirstbt.f = false;
        this.page = this.three;
        this.getdata();
      } else {
        if (this.three == 3) {
          this.changesecondbt.f = true;
          this.changethirdbt.f = false;
          this.changeforthbt.f = false;
          this.changefirstbt.f = false;
          this.two = 2;
          this.three = 3;
          this.four = 4;
          this.page = this.two;
          this.getdata();
        } else {
          this.three = this.three - 1;
          this.two = this.two - 1;
          this.four = this.four - 1;
          this.one = this.one - 1;
          this.page = this.three;
          this.getdata();
        }
      }
    },
    turnrg() {
      if (this.changefirstbt.f == true) {
        this.changesecondbt.f = true;
        this.changethirdbt.f = false;
        this.changeforthbt.f = false;
        this.changefirstbt.f = false;
        this.page = this.two;
        this.getdata();
      } else if (this.changesecondbt.f == true) {
        this.changesecondbt.f = false;
        this.changethirdbt.f = true;
        this.changeforthbt.f = false;
        this.changefirstbt.f = false;
        this.page = this.three;
        this.getdata();
      } else if (this.changethirdbt.f == true) {
        if (this.three == Math.ceil(this.commentcount / 6) - 1) {
          this.changesecondbt.f = false;
          this.changethirdbt.f = false;
          this.changeforthbt.f = true;
          this.changefirstbt.f = false;

          this.page = this.four;
          this.getdata();
        } else {
          this.three = this.three + 1;
          this.two = this.two + 1;
          this.four = this.four + 1;
          this.one = this.one + 1;

          this.page = this.three;
          this.getdata();
        }
      }
    },
  },
  created() {
    this.getxiala();
    this.jiansuo();
  },
  mounted() {
    window.addEventListener("click", () => {
      this.showlb = false;
      this.showxm = false;
    });
  },
};
</script>

<style scoped>
.productcenter1html {
  background: url("../../assets/images/cpzx-top.png") no-repeat top;
  background-size: 100% auto;
  min-width: 1400px;
  width: 100%;
  padding-top: 110px;
  /* min-height: 100%; */
  flex-grow: 1;
}
.productcenter1head {
  position: relative;
  height: 140px;
  width: 1000px;
  margin: 0 auto;
}
.title p {
  color: #1f2d54;
    border-bottom: 1px solid #ccc;
    text-indent: 1em;
    font-size: 18px;
    font-weight: 800;
    line-height: 36px;
  padding-top: 20px;

}
.productcenter1headtext {
  position: absolute;
  top: 90px;
}
.productcenter1body {
  width: 1000px;
  margin: 0 auto;
  /* min-height: 730px; */
 height: 100%;
}

.productcenter1bodytext {
  /* position: absolute;
  overflow-y: auto;
  overflow-x: hidden; */
  width: 1000px;
  /* min-height: 730px; */
  border-top-left-radius: 1em;
  background: white;
  background-size: 100% 100%;
  height: 100%;
  /* position: absolute; */
    /* top: 200px; */
    /* bottom: 84px; */
  /* filter: drop-shadow(1px 1px 2px #dee0f1); */
}
.noclick {
  height: 41px;
  line-height: 41px;

  letter-spacing: 1px;
  width: 220px;

  border-top: 1px solid #ebcb91;
  color: #69532b;
  text-align: left;
  float: left;
  font-size: 13px;
  font-weight: normal;
  cursor: pointer;
}
.onclick {
  height: 42px;
  line-height: 42px;
  background: #fdfdfd;

  width: 220px;
  letter-spacing: 1px;

  text-align: left;

  float: left;
  font-size: 13px;
  font-weight: normal;
  cursor: pointer;

  color: #4e2c2b;
}

.midtext {
  min-height: 680px;
  width: 880px;
  margin: 0 auto;
}
.xiangmu {
  position: relative;
  top: 1px;
  display: inline-block;
  overflow-y: hidden;
  height: 21px;
  width: 120px;
  line-height: 21px;
  vertical-align: top;
}
.xiangmuli {
  height: 21px;
  width: 118px;
  border-right: 1px solid#c4c7cb;
  border-left: 1px solid#c4c7cb;
  line-height: 21px;
  text-align: center;
  vertical-align: top;
  background: white;
  z-index: 999;
  cursor: pointer;
}
.ptag {
  height: 19px;
  line-height: 21px;
  display: inline-block;
  width: 50px;
  margin-left: 30px;
  vertical-align: top;
  cursor: pointer;
  margin-top: 1px;
}
.xiasanjiao {
  height: 0;
  width: 0;

  border-top: 8px solid #696060;

  border-left: 4px solid white;
  border-right: 4px solid white;
  position: absolute;
  right: 10px;
  top: 5px;
}
.choose {
  margin-top: 30px;
  position: absolute;
  margin-left: 40px;
  color: #636363;
  font-size: 14px;
}
.jiansuo {
  width: 50px;
  height: 20px;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  background: #6b92c3;
  color: white;
  border: 1px solid#c4c7cb;
  display: inline-block;
  margin-left: 30px;
  margin-top: 1px;
  user-select: none;
  cursor: pointer;
}
.text {
  border-top: 1px dashed #cacaca;
  min-height: 50px;
  line-height: 20px;
  font-size: 14px;
  color: #636363;
  letter-spacing: 1.5px;
  margin-left: 75px;
  margin-bottom: 15px;
}
.text:nth-child(1) {
  border: none;
}
.banbencx {
  padding-top: 70px;
}
.verstime {
  margin-top: 15px;
}
.verstext {
}
.xiazaizuixin {
  color: #173b83;
  font-size: 12px;
  margin-left: 60px;
  letter-spacing: 1px;
}
.xiangmu:nth-child(2) {
  border-top: 1px solid#c4c7cb;
}
.xiangmu:nth-last-child(3) {
}
.shengjili {
  border-bottom: 1px solid#c4c7cb;
}
.leibie:nth-child(2) {
  border-top: 1px solid#c4c7cb;
  border-bottom: 1px solid#c4c7cb;
}
.leibie:nth-last-child(3) {
  border-bottom: 1px solid#c4c7cb;
}
.leibie {
  position: relative;
  top: 1px;
  display: inline-block;
  overflow-y: hidden;
  height: 19px;
  width: 120px;
  line-height: 19px;
  vertical-align: top;
  border-top: 1px solid#c4c7cb;
}
.yincangjiaoyi:nth-last-child(2) {
  display: none;
}
.yincangjiaoyi:nth-child(9n) {
  display: none;
}
.xiasanjiao:hover {
  border-top: 10px solid #52add5;
}
.yincang {
  display: none;
}
.xiangmuli:hover {
  color: #317cff;
}
</style>