<template>
  <div class="pingjia">
    <div class="yongpingjia">用户评价</div>
    <div class="starpj">
      已有<span style="color: #193ea5">{{ ratecount }}</span
      >条用户评价，<span style="color: #193ea5">{{ commentcount }}</span
      >次用户评分，平均评分:<img
        src="../../../../assets/images/star.png"
        class="star"
      /><img src="../../../../assets/images/star.png" class="star" /><img
        src="../../../../assets/images/star.png"
        class="star"
      /><img src="../../../../assets/images/star.png" class="star" /><img
        src="../../../../assets/images/star_02.png"
        class="star"
      />
    </div>
    <div class="pingjiatable">
      <div style="min-height: 70px; margin-top: -15px">
        <p class="pjcss1" v-html="pingjia[0].body"></p>
        <p class="pjcss2">
          评论人：{{ pingjia[0].username }}&nbsp;{{
            pingjia[0].appname
          }}&nbsp;-&nbsp;版本{{ pingjia[0].ver }}&nbsp;-&nbsp;{{
            pingjia[0].posttime
          }}&nbsp;({{ pingjia[0].rate }}分)
        </p>
      </div>
      <div class="pingj" v-if="showdetail.showtwo">
        <p class="pjcss1" v-html="pingjia[1].body"></p>
        <p class="pjcss2">
          评论人：{{ pingjia[1].username }}&nbsp;{{
            pingjia[1].appname
          }}&nbsp;-&nbsp;版本{{ pingjia[1].ver }}&nbsp;-&nbsp;{{
            pingjia[1].posttime
          }}&nbsp;({{ pingjia[1].rate }}分)
        </p>
      </div>
      <div class="pingj" v-if="showdetail.showthree">
        <p class="pjcss1" v-html="pingjia[2].body"></p>
        <p class="pjcss2">
          评论人：{{ pingjia[2].username }}&nbsp;{{
            pingjia[2].appname
          }}&nbsp;-&nbsp;版本{{ pingjia[2].ver }}&nbsp;-&nbsp;{{
            pingjia[2].posttime
          }}&nbsp;({{ pingjia[2].rate }}分)
        </p>
      </div>
      <div class="pingj" v-if="showdetail.showfour">
        <p class="pjcss1" v-html="pingjia[3].body"></p>
        <p class="pjcss2">
          评论人：{{ pingjia[3].username }}&nbsp;{{
            pingjia[3].appname
          }}&nbsp;-&nbsp;版本{{ pingjia[3].ver }}&nbsp;-&nbsp;{{
            pingjia[3].posttime
          }}&nbsp;({{ pingjia[3].rate }}分)
        </p>
      </div>
      <div class="pingj" v-if="showdetail.showfive">
        <p class="pjcss1" v-html="pingjia[4].body"></p>
        <p class="pjcss2">
          评论人：{{ pingjia[4].username }}&nbsp;{{
            pingjia[4].appname
          }}&nbsp;-&nbsp;版本{{ pingjia[4].ver }}&nbsp;-&nbsp;{{
            pingjia[4].posttime
          }}&nbsp;({{ pingjia[4].rate }}分)
        </p>
      </div>
      <div style="height: 60px; width: 900px; margin: 0 auto">
        <span class="pagetotal">共{{ pagetotal }}页，{{ commentcount }}条</span
        ><span
          class="pagebt"
          v-bind:class="[lastpoint.f ? class3 : class2]"
          @click="turnrg"
          >&gt;</span
        ><span
          v-if="forthbt"
          v-bind:class="[changeforthbt.f ? class1 : class2]"
          @click="changep4"
          >{{ four }}</span
        ><span
          v-if="thirdbt"
          v-bind:class="[changethirdbt.f ? class1 : class2]"
          @click="changep3"
          >{{ three }}</span
        ><span
          v-if="secondbt"
          v-bind:class="[changesecondbt.f ? class1 : class2]"
          @click="changep2"
          >{{ two }}</span
        ><span
          v-if="firstbt"
          v-bind:class="[changefirstbt.f ? class1 : class2]"
          @click="changep1"
          >{{ one }}</span
        ><span
          class="pagebt"
          v-bind:class="[changefirstbt.f ? class3 : class2]"
          @click="turnlf"
          >&lt;</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import { jsonp } from "vue-jsonp";
export default {
  data() {
    return {
      showdetail: {
        showone: true,
        showtwo: true,
        showthree: true,
        showfour: true,
        showfive: true,
      },
      pagetotal: "",
      ratecount: "",
      commentcount: "",
      avgrate: 0,
      tatal: "",
      page: 1,
      pingjia: [
        {
          appname: "",
          body: "",
          posttime: "",
          rate: "",
          username: "",
          ver: "",
        },
        {
          appname: "",
          body: "",
          posttime: "",
          rate: "",
          username: "",
          ver: "",
        },
        {
          appname: "",
          body: "",
          posttime: "",
          rate: "",
          username: "",
          ver: "",
        },
        {
          appname: "",
          body: "",
          posttime: "",
          rate: "",
          username: "",
          ver: "",
        },
        {
          appname: "",
          body: "",
          posttime: "",
          rate: "",
          username: "",
          ver: "",
        },
      ],
      one: 1,
      two: 2,
      three: 3,
      four: 4,
      firstbt: true,
      secondbt: true,
      thirdbt: true,
      forthbt: true,
      changefirstbt: { f: true },
      changesecondbt: { f: false },
      changethirdbt: { f: false },
      changeforthbt: { f: false },
      class2: "pagebt",
      class1: "clickpagebt",
      lastpoint: "",
      class3: "notclick",

      pingj: [],
    };
  },
  methods: {
    getpj() {
      Object.keys(this.showdetail).forEach((item) => {
        this.showdetail[item] = true;
      });
    },
    pagefc() {
      if (Math.ceil(this.total / 5) == 1) {
        this.secondbt = false;
        this.thirdbt = false;
        this.forthbt = false;
        this.lastpoint = this.changefirstbt;
      } else if (Math.ceil(this.total / 5) == 2) {
        this.thirdbt = false;
        this.forthbt = false;
        this.lastpoint = this.changesecondbt;
      } else if (Math.ceil(this.total / 5) == 3) {
        this.forthbt = false;
        this.lastpoint = this.changethirdbt;
      } else {
        this.lastpoint = this.changeforthbt;
      }
    },
    changep1() {
      this.getpj();
      if (this.one == 1) {
        this.changesecondbt.f = false;
        this.changethirdbt.f = false;
        this.changeforthbt.f = false;
        this.changefirstbt.f = true;
        this.two = 2;
        this.three = 3;
        this.four = 4;
        this.page = this.one;
        this.getpjtext();
      } else if (this.one == 2) {
        this.changesecondbt.f = true;
        this.changethirdbt.f = false;
        this.changeforthbt.f = false;
        this.changefirstbt.f = false;
        this.one = 1;
        this.two = 2;
        this.three = 3;
        this.four = 4;
        this.page = this.two;
        this.getpjtext();
      } else {
        this.changesecondbt.f = false;
        this.changethirdbt.f = true;
        this.changeforthbt.f = false;
        this.changefirstbt.f = false;
        this.three = this.three - 2;
        this.two = this.two - 2;
        this.four = this.four - 2;
        this.one = this.one - 2;
        this.page = this.three;
        this.getpjtext();
      }
    },
    changep2() {
      this.getpj();
      if (this.two == 2) {
        this.changesecondbt.f = true;
        this.changethirdbt.f = false;
        this.changeforthbt.f = false;
        this.changefirstbt.f = false;
        this.one = 1;
        this.two = 2;
        this.three = 3;
        this.four = 4;
        this.page = this.two;
        this.getpjtext();
      } else {
        this.changesecondbt.f = false;
        this.changethirdbt.f = true;
        this.changeforthbt.f = false;
        this.changefirstbt.f = false;
        this.three = this.three - 1;
        this.two = this.two - 1;
        this.four = this.four - 1;
        this.one = this.one - 1;
        this.page = this.three;
        this.getpjtext();
      }
    },

    changep3() {
      this.getpj();
      this.changesecondbt.f = false;
      this.changethirdbt.f = true;
      this.changeforthbt.f = false;
      this.changefirstbt.f = false;
      this.page = this.three;
      this.getpjtext();
    },
    changep4() {
      this.getpj();
      if (this.three == Math.ceil(this.total / 5) - 1) {
        this.changesecondbt.f = false;
        this.changethirdbt.f = false;
        this.changeforthbt.f = true;
        this.changefirstbt.f = false;
        if ((this.three + 1) * 5 <= this.total) {
          this.page = this.four;
          this.getpjtext();
        } else {
          this.page = this.four;
          this.getpjtext();
          for (var s = this.four * 5 - 1; this.total <= s; s--) {
            this.showdetail[
              Object.keys(this.showdetail)[s - this.three * 5]
            ] = false;
          }
        }
      } else {
        this.changesecondbt.f = false;
        this.changethirdbt.f = true;
        this.changeforthbt.f = false;
        this.changefirstbt.f = false;
        this.three = this.three + 1;
        this.two = this.two + 1;
        this.four = this.four + 1;
        this.one = this.one + 1;
        this.page = this.three;
        this.getpjtext();
      }
    },
    turnlf() {
      this.getpj();
      if (this.changesecondbt.f == true) {
        this.changesecondbt.f = false;
        this.changethirdbt.f = false;
        this.changeforthbt.f = false;
        this.changefirstbt.f = true;
        this.one = 1;
        this.two = 2;
        this.three = 3;
        this.four = 4;

        this.page = this.one;
        this.getpjtext();
      } else if (this.changeforthbt.f == true) {
        this.changesecondbt.f = false;
        this.changethirdbt.f = true;
        this.changeforthbt.f = false;
        this.changefirstbt.f = false;
        this.page = this.three;
        this.getpjtext();
      } else {
        if (this.three == 3) {
          this.changesecondbt.f = true;
          this.changethirdbt.f = false;
          this.changeforthbt.f = false;
          this.changefirstbt.f = false;
          this.two = 2;
          this.three = 3;
          this.four = 4;
          this.page = this.two;
          this.getpjtext();
        } else {
          this.three = this.three - 1;
          this.two = this.two - 1;
          this.four = this.four - 1;
          this.one = this.one - 1;
          this.page = this.three;
          this.getpjtext();
        }
      }
    },
    turnrg() {
      this.getpj();
      if (this.changefirstbt.f == true) {
        this.changesecondbt.f = true;
        this.changethirdbt.f = false;
        this.changeforthbt.f = false;
        this.changefirstbt.f = false;
        this.page = this.two;
        this.getpjtext();
      } else if (this.changesecondbt.f == true) {
        this.changesecondbt.f = false;
        this.changethirdbt.f = true;
        this.changeforthbt.f = false;
        this.changefirstbt.f = false;
        this.page = this.three;
        this.getpjtext();
      } else if (this.changethirdbt.f == true) {
        if (this.three == Math.ceil(this.total / 5) - 1) {
          this.changesecondbt.f = false;
          this.changethirdbt.f = false;
          this.changeforthbt.f = true;
          this.changefirstbt.f = false;

          if ((this.three + 1) * 5 <= this.total) {
            this.page = this.four;
            this.getpjtext();
          } else {
            this.page = this.four;
            this.getpjtext();
            for (var s = this.four * 5 - 1; this.total <= s; s--) {
              this.showdetail[
                Object.keys(this.showdetail)[s - this.three * 5]
              ] = false;
            }
          }
        } else {
          this.three = this.three + 1;
          this.two = this.two + 1;
          this.four = this.four + 1;
          this.one = this.one + 1;

          this.page = this.three;
          this.getpjtext();
        }
      }
    },
    getpjtext() {
      jsonp("//db.wenhua.com.cn/vote/data.asp", {
        appid: 11,
        page: this.page,
        pagesize: 5,
      })
        .then((res) => {
          var a=/[0-9]{1}/g
          if (res.Data.Page.Total / res.Data.Page.PageSize > res.Data.Page.Page)
            for (var count = 0; count < 5; count++) {
              this.pingjia[count].appname = res.Data.List[count].AppName;
              this.pingjia[count].body = res.Data.List[count].Body;
              this.pingjia[count].posttime = res.Data.List[count].PostTime;
              this.pingjia[count].rate = res.Data.List[count].Rate;
              this.pingjia[count].username = res.Data.List[count].UserName;
              if(this.pingjia[count].username.search("收费")!=-1)
              {
                var b=res.Data.List[count].UserName.search(a)
              this.pingjia[count].username=this.pingjia[count].username.substr(0, Number(b))+"***"+this.pingjia[count].username.substr(Number(b)+ 3)}
                
              this.pingjia[count].ver = res.Data.List[count].Ver;
            }
          else {
            for (
              var count2 = 0;
              count2 < res.Data.Page.Total % res.Data.Page.PageSize;
              count2++
            ) {
              this.pingjia[count2].appname = res.Data.List[count2].AppName;
              this.pingjia[count2].body = res.Data.List[count2].Body;
              this.pingjia[count2].posttime = res.Data.List[count2].PostTime;
              this.pingjia[count2].rate = res.Data.List[count2].Rate;
              this.pingjia[count2].username = res.Data.List[count2].UserName;
              if(res.Data.List[count].UserName.search("收费")!=-1)
              {var b2=res.Data.List[count].UserName.search(a)
              this.pingjia[count2].username=this.pingjia[count].username.substr(0, Number(b2))+"***"+this.pingjia[count2].username.substr(Number(b2)+ 3)}
              this.pingjia[count2].ver = res.Data.List[count2].Ver;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getallpj() {
      jsonp("//db.wenhua.com.cn/vote/data.asp", {
        appid: 11,
        page: this.page,
        pagesize: 10,
      })
        .then((res) => {
          if (res.Data.Page.Total / res.Data.Page.PageSize > this.page) {
            for (var count = 0; count < 10; count++) {
              this.pingj.push({
                appname: res.Data.List[count].AppName,
                body: res.Data.List[count].Body,
                posttime: res.Data.List[count].PostTime,
                rate: res.Data.List[count].Rate,
                username: res.Data.List[count].UserName,
                ver: res.Data.List[count].Ver,
              });
            }
          } else {
            for (
              var count2 = 0;
              count2 < res.Data.Page.Total % res.Data.Page.PageSize;
              count2++
            ) {
              this.pingj.push({
                appname: res.Data.List[count2].AppName,
                body: res.Data.List[count2].Body,
                posttime: res.Data.List[count2].PostTime,
                rate: res.Data.List[count2].Rate,
                username: res.Data.List[count2].UserName,
                ver: res.Data.List[count2].Ver,
              });
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {},
  created() {
    this.pagefc();

    jsonp("https://db.wenhua.com.cn/inc/update.asp", {
      appid: 12,
      t: 1,
    })
      .then((data) => {
        this.tydata = data;

        this.tybanben =
          " 版本:" +
          this.tydata.split("|")[0] +
          "" +
          " (第 " +
          this.tydata.split("|")[3] +
          "次修改)" +
          "" +
          " 大小:" +
          this.tydata.split("|")[2] +
          "" +
          " 日期:" +
          this.tydata.split("|")[1];
      })
      .catch((err) => {
        console.log(err);
      });
    this.getpjtext();
    jsonp("//db.wenhua.com.cn/vote/data.asp", {
      appid: 11,
      page: this.page,
      pagesize: 5,
    })
      .then((res) => {
        this.pagetotal = res.Data.Page.PageTotal;
        this.avgrate = res.Data.RateRecord.AvgRate;
        this.commentcount = res.Data.RateRecord.CommentCount;
        this.ratecount = res.Data.RateRecord.RateCount;
        this.total = res.Data.Page.Total;

        //             for(var count=0;count<(this.commentcount/10);count++){
        //     console.log(this.page)
        //   this.page=this.page+1
        //   this.getallpj()
        // }
      })
      .catch((err) => {
        console.log(err);
      });
  },
};
</script>

<style>
.pagetotal {
  text-align: center;
  line-height: 25px;
  color: #a6a6a6;
  float: right;
  margin-left: 5px;
  margin-right: 5px;
}
</style>