<template>
  <div class="guangzhou">
    <div class="guangztable1" v-if="page.page1">
      <table>
        <tr>
          <td
            style="
              width: 620px;
              background: #e2e8f4;
              text-align: center;
              color: #454d60;
              padding-left: 0px;
            "
          >
            公司名称
          </td>
        </tr>
        <tr>
          <td>深圳前海华杉投资管理有限公司</td>
        </tr>
        <tr>
          <td>深圳市感恩在线投资管理有限公司</td>
        </tr>
        <tr>
          <td>深圳市博信汇通投资管理有限公司</td>
        </tr>
        <tr>
          <td>半人马座资本管理（深圳）有限公司</td>
        </tr>
        <tr>
          <td>广东杉谷证券投资基金管理有限公司</td>
        </tr>
        <tr>
          <td>广州融汇私募证券投资基金管理有限责任公司</td>
        </tr>
        <tr>
          <td>深圳前海诚睿资产管理有限公司</td>
        </tr>
        <tr>
          <td>广州融汇私募证券投资基金管理有限责任公司</td>
        </tr>
        <tr>
          <td>广州广金私募证券投资管理有限公司</td>
        </tr>
        <tr>
          <td>广东金羊基金管理有限公司</td>
        </tr>
         <tr>
          <td>珠海凡旭资产管理有限公司</td>
        </tr>
      </table>
    </div>
    <div class="guangztable2" v-if="page.page2">
      <table>
        <tr>
          <td
            style="
              width: 620px;
              background: #e2e8f4;
              text-align: center;
              color: #454d60;
              padding-left: 0px;
            "
          >
            公司名称
          </td>
        </tr>

        <tr>
          <td>深圳前海固禾资产管理有限公司</td>
        </tr>
        <tr>
          <td>广东厚方投资管理有限公司</td>
        </tr>
        <tr>
          <td>深圳前海夸克资产管理有限公司</td>
        </tr>
        <tr>
          <td>深圳乘浪资本管理有限公司</td>
        </tr>
        <tr>
          <td>深圳昌宏盛瑞投资管理有限公司</td>
        </tr>
        <tr>
          <td>安若本然（珠海）资产管理有限公司</td>
        </tr>
      </table>
    </div>
    <div class="successcomfoot">
      <div v-bind:class="[page.page1 ? class1 : class2]" @click="showp1">1</div>
      <div v-bind:class="[page.page2 ? class1 : class2]" @click="showp2">2</div>
      共2页，17条
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      page: {
        page1: true,
        page2: false,
      },
      class2: "pagebutton",
      class1: "pageclickbutton",
    };
  },
  methods: {
    showp1() {
      Object.keys(this.page).forEach((item) => {
        this.page[item] = false;
      });
      this.page.page1 = true;
    },
    showp2() {
      Object.keys(this.page).forEach((item) => {
        this.page[item] = false;
      });
      this.page.page2 = true;
    },
  },
};
</script>

<style >
</style>