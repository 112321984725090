<template>
  <div class="wh8html">
    <div class="wh8text">
      <div class="wh8header">
        <div>
          <div class="headcon">
            <div style="display: inline-block; width: 0">
              <img
                src="../assets/images/logo.png"
                style="position: relative; top: 14px"
              />
            </div>
            <div class="router">
              <div class="rou">
                <router-link to="/aboveall">首页</router-link>
              </div>
              <ul class="rou listt listt1" style="width: 64px">
                <router-link to="/proc" active-class="seesee"
                  >产品中心</router-link
                >
                <div class="listpro" style="width:120px">
                  <li
                    style="
                      width: 120px;
                      z-index: 108;
                      height: 35px;
                      line-height: 35px;
                      background: white;
                      padding-left: 20px;
                    "
                  >
                    <router-link
                      to="/proc/productcenter/productcenter1"
                      active-class="nosee"
                      >特色功能</router-link
                    >
                  </li>
                  <!-- <li
                    style="
                      width: 140px;
                      z-index: 108;
                      height: 35px;
                      line-height: 35px;
                      background: white;
                      padding-left: 20px;
                    "
                  >
                    <router-link
                      to="/proc/productcenter2/one"
                      active-class="nosee"
                      >文华云主机</router-link
                    >
                  </li> -->
                  <!-- <li
                    style="
                      width: 140px;
                      z-index: 108;
                      height: 35px;
                      line-height: 35px;
                      background: white;
                      padding-left: 20px;
                    "
                  >
                    <router-link
                      to="/proc/productcenter1/productcenter0"
                      active-class="nosee"
                      >多账号下单</router-link
                    >
                  </li> -->
                  <li
                    style="
                      width: 120px;
                      z-index: 108;
                      height: 35px;
                      line-height: 35px;
                      background: white;
                      padding-left: 20px;
                    "
                  >
                    <a href="https://www.wenhua.com.cn/new_guide/wt8_new/view1_1.html" target="_blank"
                      >操作说明</a
                    >
                  </li>
                  
                  <li
                    style="
                      width: 120px;
                      z-index: 108;
                      height: 35px;
                      line-height: 35px;
                      background: white;
                      padding-left: 20px;
                    "
                  >
                    <router-link to="/proc/shengjishuoming" active-class="nosee"
                      >升级说明</router-link
                    >
                  </li>
                  
                </div>
              </ul>
              <ul class="rou listt listt6" style="width: 32px">
                <router-link to="/download" active-class="seesee"
                  >下载</router-link
                >
                <div class="listpro">
                <li
                    style="
                      width: 140px;
                      z-index: 108;
                      height: 35px;
                      line-height: 35px;
                      background: white;
                      padding-left: 20px;
                    "
                  >
                    <router-link
                      to="/download/download/0"
                      active-class="nosee"
                      >实盘通用版</router-link
                    >
                  </li>
                  <li
                    style="
                      width: 140px;
                      z-index: 108;
                      height: 35px;
                      line-height: 35px;
                      background: white;
                      padding-left: 20px;
                    "
                  >
                    <router-link
                      to="/download/download/1"
                      active-class="nosee"
                      >体验版</router-link
                    >
                  </li>
                  <li
                    style="
                      width: 140px;
                      z-index: 108;
                      height: 35px;
                      line-height: 35px;
                      background: white;
                      padding-left: 20px;
                    "
                  >
                    <router-link
                      to="/download/download/2"
                      active-class="nosee"
                      >学习资料</router-link
                    >
                  </li>

                  <!-- <li
                    style="
                      width: 140px;
                      z-index: 108;
                      height: 35px;
                      line-height: 35px;
                      background: white;
                      padding-left: 20px;
                    "
                  >
                    <router-link
                      to="/download"
                      active-class="nosee"
                      >64位量化交易软件</router-link
                    >
                  </li>
                  <li
                    style="
                      width: 140px;
                      z-index: 108;
                      height: 35px;
                      line-height: 35px;
                      background: white;
                      padding-left: 20px;
                    "
                  >
                    <router-link
                      to="/download/softAdvantage"
                      active-class="nosee"
                      >64位软件的优势</router-link
                    >
                  </li> -->
                  <!-- <li
                    style="
                      width: 140px;
                      z-index: 108;
                      height: 35px;
                      line-height: 35px;
                      background: white;
                      padding-left: 20px;
                    "
                  >
                    <router-link
                      to="/download/learningMaterials"
                      active-class="nosee"
                      >学习资料</router-link
                    >
                  </li> -->
                  
                </div>
              </ul>
            
              <div class="rou">
                <router-link to="/trade">交易通道</router-link>
              </div>
              <!-- <div class="rou"><router-link to="/buy/buy">购买</router-link></div> -->
              <ul class="rou listt listt2" style="width: 32px">
                <router-link to="/buy" active-class="seesee"
                  >购买</router-link
                >
                <!-- <div class="listpro">
                  <li
                    style="
                      width: 140px;
                      z-index: 108;
                      height: 35px;
                      line-height: 35px;
                      background: white;
                      padding-left: 20px;
                    "
                  >
                    <router-link
                      to="/buy/buy"
                      active-class="nosee"
                      >软件购买</router-link
                    >
                  </li>
                  <li
                    style="
                      width: 140px;
                      z-index: 108;
                      height: 35px;
                      line-height: 35px;
                      background: white;
                      padding-left: 20px;
                    "
                  >
                    <router-link
                      to="/buy/videoClass"
                      active-class="nosee"
                      >模型编写视频教程</router-link
                    >
                  </li>
                  
                  
                </div> -->
              </ul>
              <ul class="rou listt listt3" style="width: 32px">
                <router-link to="/serive/serive" active-class="seesee"
                  >服务</router-link
                >
                <!-- <div class="listpro">
                  <li
                    style="
                      width: 140px;
                      z-index: 108;
                      height: 35px;
                      line-height: 35px;
                      background: white;
                      padding-left: 20px;
                    "
                  >
                    <router-link
                      to="/serive"
                      active-class="nosee"
                      >VIP服务渠道</router-link
                    >
                  </li>
                  <li
                    style="
                      width: 140px;
                      z-index: 108;
                      height: 35px;
                      line-height: 35px;
                      background: white;
                      padding-left: 20px;
                    "
                  >
                    <router-link
                      to="/serive/wwt"
                      active-class="nosee"
                      >文文通即时服务</router-link
                    >
                  </li>
                  <li
                    style="
                      width: 140px;
                      z-index: 108;
                      height: 35px;
                      line-height: 35px;
                      background: white;
                      padding-left: 20px;
                    "
                  >
                    <router-link
                      to="/serive/myFinEng"
                      active-class="nosee"
                      >我的金融工程师</router-link
                    >
                  </li>
                  
                </div> -->
              </ul>
              <!-- <div class="rou"><router-link to="/serive">服务</router-link></div> -->
              <!-- <div class="rou">
                <router-link to="/success">成功案例</router-link>
              </div> -->
              <ul class="rou listt listt2" style="width: 70px">
                <router-link to="/success" active-class="seesee"
                  >成功案例</router-link
                >
                <div class="listpro">
                  <li
                    style="
                      width: 140px;
                      z-index: 108;
                      height: 35px;
                      line-height: 35px;
                      background: white;
                      padding-left: 20px;
                    "
                  >
                    <router-link
                      to="/success"
                      active-class="nosee"
                      >成功案例</router-link
                    >
                  </li>
                  <li
                    style="
                      width: 140px;
                      z-index: 108;
                      height: 35px;
                      line-height: 35px;
                      background: white;
                      padding-left: 20px;
                    "
                  >
                    <a
                    href="https://lhw.wenhua.com.cn/dist/#/Home"
                    target="_blank"
                      >成功者足迹</a
                    >
                  </li>
                  
                  
                </div>
              </ul>
              <ul class="rou listt listt5" style="width: 100px">
                <router-link to="/about" active-class="seesee"
                  >关于麦语言</router-link
                >
                <div class="listpro" style="width:185px">
                  <li
                    style="
                      width: 140px;
                      z-index: 108;
                      height: 35px;
                      line-height: 35px;
                      background: white;
                      padding-left: 20px;
                    "
                  >
                    <router-link
                      to="/about/1"
                      active-class="nosee"
                      >麦语言的历史</router-link
                    >
                  </li>
                  <li
                    style="
                      width: 185px;
                      z-index: 108;
                      height: 35px;
                      line-height: 35px;
                      background: white;
                      padding-left: 20px;
                    "
                  >
                    <router-link
                      to="/about/2"
                      active-class="nosee"
                      >有关的量化交易概念</router-link
                    >
                  </li>
                  <li
                    style="
                      width: 140px;
                      z-index: 108;
                      height: 35px;
                      line-height: 35px;
                      background: white;
                      padding-left: 20px;
                    "
                  >
                    <router-link
                      to="/about/5"
                      active-class="nosee"
                      >经典文章</router-link
                    >
                  </li>
                  
                  
                </div>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="excepthead">
        <div class="wh8body" ref="body">
          <!-- <transition :name="transitionName" >
        </transition> -->
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ifphone:false,
      showProduct: false,
      transitionName: "transitionLeft",
      ops: {
        vuescroll: {
          mode: "native",
          sizeStrategy: "number",
          detectResize: true,

          locking: false,
        },
        scrollPanel: {
          initialScrollY: false,
          initialScrollX: false,
          scrollingX: false,
          scrollingY: true,
          speed: 300,
          easing: undefined,
          verticalNativeBarPos: "right",
          maxHeight: undefined,
          maxWidth: undefined,
        },
        rail: {
          background: "#01a99a",
          opacity: 1,
          size: "6px",
          specifyBorderRadius: false,
          gutterOfEnds: null,
          gutterOfSide: "2px",
          keepShow: false,
        },
        bar: {
          showDelay: 2000,
          onlyShowBarOnScroll: true,
          keepShow: true,
          background: "#c1c1c1",
          opacity: 0,
          hoverStyle: false,
          specifyBorderRadius: false,
          minSize: false,
          size: "6px",
          disable: false,
        },
      },
    };
  },
  methods: {
    seeProduct() {
      this.showProduct = true;
    },
    hideProduct() {
      this.showProduct = false;
    },
    handleComplete() {
      console.log("scroll complete！");
    },
  },
  watch: {
    $route(to, from) {
      const arr = [
        "/aboveall",
        "/proc/",
        "/proc/productcenter",
        "/proc/productcenter1",
        "/proc/productcenter2",
        "/download",
        "/trade",
        "/buy",
        "/serive",
        "/success",
      ];
      this.transitionName =
        arr.indexOf(to.path) > arr.indexOf(from.path)
          ? "transitionLeft"
          : "transitionRight";
    },//路由轮播
  },
  created(){
    if((/Mobi|Android|iPhone/i.test(navigator.userAgent))){
      this.ifphone=true
    }
    // if(process.env.NODE_ENV=="production"&&(!window.location.host.includes('WT8')||!window.location.host.includes('wt8'))){
    //   window.location.href='https://WT8.wenhua.com.cn/#'+this.$route.path
    // }
  }
};
</script>

<style  scoped>
.wh8header {
  /* position: fixed; */
  top: 0;
  height: 72px;
  line-height: 72px;
  width: 100%;
  background: white;
  filter: drop-shadow(0px 3px 5px #12133b);
  z-index: 999;
  min-width: 1400px;

  -webkit-transform: translateZ(0);
            -moz-transform: translateZ(0);
            -ms-transform: translateZ(0);
            -o-transform: translateZ(0);
            transform: translateZ(0);

            -webkit-backface-visibility: hidden;
            -moz-backface-visibility: hidden;
            -ms-backface-visibility: hidden;
            backface-visibility: hidden;

            -webkit-perspective: 1000;
            -moz-perspective: 1000;
            -ms-perspective: 1000;
            perspective: 1000;
            -webkit-transform-style: preserve-3d;
}

.wh8footer {
  position: fixed;
  bottom: 0;
  text-align: center;
  min-width: 1400px;
  width: 100%;

  height: 80px;

  margin: 0 auto;
  background-color: #14204a;
  color: #e8e9ed;
  font-size: 14px;
  line-height: 20px;
}
.wh8body {
  /* padding-top: 50px; */
  min-width: 1400px;
  /* min-height:70vh; */
  
}
br {
  line-height: 0px;
}

.router {
  float: right;

  height: 70px;
}
.rou {
  margin-top: 5px;
  margin-left: 33px;
  z-index: 108;
  float: left;
  height: 30px;
}
a {
  text-decoration: none;
  color: #404040;
  display: inline-block;
  line-height: 27px;
  height: 27px;
  user-select: none;
  font-size: 16px;
}
.router-link-active {
  border-bottom: 3px solid #fe5e49;
}
.headcon {
  max-width: 1080px;
  min-width: 900px;
  margin: 0 auto;
  /* padding-left: -webkit-calc(100vw - 100%);
  padding-left: calc(100vw - 100%); */
}
a:hover {
  color: #983c3c;
}
.nosee {
}
.seesee {
  border-bottom: 3px solid #fe5e49;
}
.listpro {
  width: 170px;
  height: 0px;
  margin-top: -5px;
  background: white;
  overflow: hidden;
  transition: height 0.5s;
  -moz-transition: height 0.5s; 
  -webkit-transition: height 0.5s; 
  -o-transition: height 0.5s; 
  transform: translateZ(0);
  font-size: 15px;
  
}
.listt1:hover .listpro {
  height: 105px;
}
.listt2:hover .listpro {
  height: 70px;
}
.listt3:hover .listpro {
  height: 105px;
}
.listt5:hover .listpro {
  height: 105px;
}
.listt6:hover .listpro {
  height: 105px;
}
/* .fade-enter-active, .fade-leave-active {
  transition: opacity .3s;
}
.fade-enter, .fade-leave-to  {
  opacity: 0;
}
.transitionBody{
 transition: all 0.3s ;
 position: relative;
-webkit-transform: translate(0, 0);
  transform: translate(0, 0);


}
.transitionLeft-enter,
.transitionRight-leave-active {
  -webkit-transform: translate(100%, 0);
  transform: translate(100%, 0);
}
.transitionLeft-leave-active,
.transitionRight-enter {
  -webkit-transform: translate(-100%, 0);
  transform: translate(-100%, 0);
  
}
.transitionLeft-enter-active,
.transitionRight-enter-active {  

position: absolute;
top: 50px;
left: 0;
right: 0;
bottom: 0;
} */

.wh8scroll {
  position: relative;
  min-height: 100%;
}
.wh8footer1 {
  position: absolute;
  top: 889px;
  text-align: center;
  min-width: 1400px;
  width: 100%;

  height: 80px;

  margin: 0 auto;
  background-color: #14204a;
  color: #e8e9ed;
  font-size: 14px;
  line-height: 20px;
}
.wh8html {
  position: relative;
  min-width:1400px;
  min-height: 100%;
  background: url("../assets/images/ny-bg.jpg") no-repeat top;
  overflow-y: scroll;
  /* overflow-x: hidden; */
  /* background-size:100% 100%; */
  /* padding-bottom: 80px; */

}
.wh8html::-webkit-scrollbar{
  width: 0!important;
}
.wh8text{
  transform: translateZ(0);
}
</style>
