<template>
  <div class="myFinEng">
    <div class="content">
      <div class="header">我的金融工程师</div>
      <div class="article">
        <p>
          购买wt9、WT8授权可获得VIP金融工程师服务（下载<router-link 
            target="_blank"
            to="/serive/wwt" 
            ><a style="color: #5674a8">文文通</a></router-link
          >），金融工程师服务时间 工作日8:00~17:00，期间午休11:30~13:00。
        </p>
        <div class="look">
          <div class="uPic"></div>
          <div class="lookInp">
            <div class="kuang">
              <input
                type="text"
                class="myInp"
                placeholder="输入授权账号，找到您的专属金融工程师"
                v-model="input"
              />
            </div>
            <div class="lookBtn" @click="search"><i></i></div>
          </div>
        </div>
      </div>
      <div
        class="post_info"
      >
        <div
          v-if="flag ==1"
          style="
            font-family: Simsun;
            line-height: 24px;
            color: #505050;
            font-size: 15px;
            margin-left: 166px;
          "
        >
          <p>
            咨询软件使用方法、反馈软件的问题或对软件功能有什么建议，请按照以下方式寻求帮助：
          </p>
          <p
            style="
              margin: 0;
              margin-left: 1.5em;
              text-indent: -1.5em;
              margin-top: 10px;
            "
          >
            1、到在线客服，wh6/随身行相关问题30分钟内回复、wh5/wh7/WT8/wt9/wt10相关问题10分钟内回复，<br> 每天我们处理上千个用户的提问，上一个年度我们解答了154631个用户的问题；<br />
            在线客服入口：软件界面的右上角菜单，【帮助】-&gt;在线客服
          </p>
          <p
            style="
              margin: 0;
              margin-left: 1.5em;
              text-indent: -1.5em;
              margin-top: 5px;
            "
          >
            2、致电金融工程师<span style="font-weight: normal"
              >:400-811-3366（工作日8:00～22:00，周末9:00～20:00）；<br />
              金融工程师支持由金融专业、懂程序化、具有期货从业资格证的专业人员为用户服务。</span
            >
          </p>
        </div>
      </div>
      <div class="out" v-if="flag ==2">
        <div class="someOther">
          <div class="content1" style="padding-left: 180px;">
            <div class="conTitle" style="font-size:22px">专属金融工程师</div>
            <div class="detailOut">
              <div class="conDetail">
                <div class="photo"><img class="pimg" :src="('https://t9.wenhua.com.cn/'+data.avatar)" /></div>
                <div class="nPro">
                  <div class="pname">{{data.showname}}<span style="margin-left:30px;font-size:16px;">{{data.major==''?'':'毕业专业'}} {{data.major}} <span style="font-weight:normal;">{{data.major == ""?'':'|'}}</span> 入职{{data.workingYears}}年</span></div>
                  <div class="talk"><i></i><span>{{data.sign}}</span></div>
                </div>
                
              </div>
              <!-- <div class="compDet">
                <div class="cDetail">
                  <p class="comName">上海文华财经资讯股份有限公司</p>
                  <p>
                    上海市浦东新区源深路419号，陆家嘴国际华城大厦9层（021）50270068-<span
                      name="phone_ext"
                    >{{data.telnumber}}</span>
                  </p>
                  <p>
                    大连市沙河口区软件园东路23号，软件园15号楼4层（0411）84760066-<span
                      name="phone_ext"
                    >{{data.telnumber}}</span>
                  </p>
                </div>
              </div> -->
              <!-- <div class="scanCode">
					<div class="scPic"><img src="" class="scimg" /></div>
					<div class="scTxt">扫一扫加微信</div>
				</div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "myFinEng",
  data() {
    return {
      input: "",
      flag:0,
      data:{}
    };
  },
  methods: {
    search() {
      axios
        .get("https://wt9.wenhua.com.cn/api/ServiceApi/GetService?id="+this.input)
        .then((res) => {
          // console.log(res)
          if(res.data.status){
            this.flag = 2;
            this.data = res.data.data;
          }else{
            this.flag = 1
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style  scoped>
.myFinEng {
  background: url("../../assets/images/vip-top.png") no-repeat top;
  background-size: 100% auto;
  min-width: 1400px;
  width: 100%;
  /* min-height: 539px; */
  padding-top: 110px;
}
.myFinEng .content {
  width: 900px;
  margin: 0 auto;
  /* margin-top: 100px; */
  min-height: 570px;
  background: #fff;
  border: 1px solid #92afeb;
  border-radius: 10px 10px 0 0;
  padding: 50px;
   /* position: absolute; */
            top: 200px;
            bottom: 0;
            left:0;
			right: 0;
}
.myFinEng .content .header {
  color: #1f2d54;
  border-bottom: 1px solid #ccc;
  text-indent: 1em;
  font-size: 18px;
  font-weight: 800;
  line-height: 36px;
}
.myFinEng .content .article {
  padding: 40px 20px 0 40px;
  color: #838383;
  font-size: 14px;
}
.myFinEng .content .article p {
  line-height: 28px;
}
.look {
  width: 550px;
  margin: 60px auto;
  overflow: hidden;
  margin-bottom: 32px;
}

.uPic {
  background: url("../../assets/images/wenhaol.png") no-repeat;
  background-position-y: 30px;
  width: 120px;
  height: 156px;
  float: left;
  /* margin-right: 20px; */
}

.lookInp {
  width: 380px;
  height: 44px;
  float: left;
  margin-top: 55px;
  overflow: hidden;
}

.kuang {
  width: 295px;
  height: 40px;
  border: 2px solid #6189c4;
  border-right: none;
  overflow: hidden;
  float: left;
}

.myInp {
  width: 93%;
  height: 100%;
  border: 0;
  outline: none;
  padding: 0 10px;
  font-size: 15px;
  color: #8d8d8d;
  *line-height: 40px;
  *height: 40px;
}

.lookBtn {
  width: 65px;
  height: 44px;
  float: left;
  background: #6189c4;
  cursor: pointer;
}

.lookBtn i {
  display: block;
  background: url("../../assets/images/lookbtn.jpg") no-repeat;
  width: 28px;
  height: 28px;
  margin: 8px 0 0 18px;
}
.out {
  width: 100%;
  margin-bottom: 30px;
  /* display: none; */
}

.conTitle,
.wcTitle {
  font-size: 24px;
  color: #3a84fd;
  font-weight: bold;
  margin-bottom: 40px;
}

.detailOut {
  width: 100%;
  position: relative;
}

.conDetail {
  padding-left: 10px;
  position: relative;
  overflow: hidden;
  margin-bottom: 40px;
}

.photo,
.nPro,
.pEmail {
  float: left;
  margin-right: 5px;
}

.photo {
  width: 70px;
  height: 70px;
  border-radius: 50px;
  overflow: hidden;
}

.photo img {
  width: 100%;
  height: 100%;
}

.nPro {
  /* width: 170px; */
}
.nPro .talk{
  margin-top: 10px;
}
.talk span{
  background: #f7eadc;
  color: #8d7c7c;
  height: 29px;
  line-height: 29px;
  vertical-align: middle;
    display: inline-block;
    padding-right: 8px;
    border-radius:  0 7px 7px 0;
}
.talk i{
  width: 14px;
    height: 29px;
    vertical-align: middle;
    display: inline-block;
  background: url("../../assets/images/duihua.png") no-repeat;
}
.pname,
.ppro {
  font-size: 20px;
  font-weight: bold;
  margin: 10px;
}

.pEmail i {
  display: block;
  float: left;
  width: 29px;
  height: 28px;
  margin: 5px 12px 5px 0;
}

.phone,
.email {
  overflow: hidden;
  height: 35px;
  line-height: 38px;
}

.phone i {
  background: url("../../assets/images/uphone.jpg") no-repeat;
}

.email i {
  background: url("../../assets/images/email.jpg") no-repeat;
}

.pEmail span {
  font-size: 16px;
  font-weight: bold;
}

.compDet {
  width: 100%;
  background: #d3d3d3;
  padding: 15px 0;
}

.cDetail {
  margin-left: 98px;
}

.cDetail p {
  font-size: 14px;
  margin: 5px 0;
}

.cDetail .comName {
  font-size: 19px;
  font-weight: bold;
  margin-bottom: 10px;
}

.scanCode {
  position: absolute;
  right: 30px;
  top: 0;
  width: 174px;
  height: 210px;
  overflow: hidden;
}

.scPic {
  width: 166px;
  height: 166px;
  border: 4px solid #3a84ff;
  border-radius: 5px;
}

.scPic img {
  width: 100%;
  height: 100%;
}

.scTxt {
  width: 100%;
  text-align: center;
  font-size: 17px;
  margin-top: 8px;
  font-weight: 700;
}
</style>