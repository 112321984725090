<template>
    <form @submit.prevent="submit">
        <div class="form">
            <label class="input-area">
                <Tip text="请输入名称" :style="{ top: '-28px', left: '-1px' }" :show.sync="invalid_name" />
                <div class="input">
                    <i class="icon name"></i>
                    <input type="text" ref="name" v-model="input_name" placeholder="名称" />
                </div>
            </label>
            <label class="input-area">
                <Tip text="请输入 8-15 位不包含特殊字符的密码" :style="{ top: '-28px', left: '-1px' }" :show.sync="invalid_password" />
                <div class="input">
                    <i class="icon passwd"></i>
                    <input type="password" v-model="input_password" placeholder="密码" />
                </div>
            </label>
            <label class="input-area">
                <Tip text="请输入正确的验证码" :style="{ top: '-28px', left: '-1px' }" :show.sync="invalid_vercode" />
                <div class="input">
                    <i class="icon key"></i>
                    <input type="text" v-model="input_vercode" placeholder="验证码" />
                    <div class="vercode" @click="fresh_code">
                        <object class="code_obj" :data="vercode" type="image/jpg">
                            <img class="fresh" src="../assets/fresh.svg" />
                        </object>
                    </div>
                </div>
            </label>
            <div style="position: relative; margin-top: 19px;">
                <Tip :text="request_message" :style="{ top: '-28px', left: '-1px' }" :show.sync="invalid_request" />
                <input type="submit" class="button" style="margin-top: 12px;" :value="message? message : '登 录'" :disabled="sending">
            </div>
        </div>
    </form>
</template>

<script>
import Tip from "./tip";
import axios from 'axios'
import store from '../../../store'

export default {
    name: 'Form',
    components: {
        Tip
    },
    mounted() {
        let target = this.$refs.name
        target && target.focus()
    },
    computed: {
        vercode() {
            let url = 'https://assets.wenhua.com.cn/api/captcha?size=20&t='
            return url + this.random
        }
    },
    data: () => ({
        random: performance.now(),
        invalid_name: false,
        invalid_password: false,
        invalid_vercode: false,
        invalid_request: false,
        request_message: '',
        input_name: '',
        input_password: '',
        input_vercode: '',
        sending: false,
        message: ''
    }),
    watch: {
        input_name() {
            this.invalid_name = !(this.input_name.trim())
            this.invalid_request = false
        },
        input_password() {
            this.invalid_password = !(this.input_password.trim())
            this.invalid_request = false
        },
        input_vercode() {
            this.invalid_vercode = !(this.input_vercode.trim())
            this.invalid_request = false
        }
    },
    methods: {
        fresh_code() {
            this.random = performance.now()
        },
        submit() {
            if (!this.sending) {
                this.invalid_name = false
                this.invalid_password = false
                this.invalid_vercode = false

                if (!(this.input_name.trim())) {
                    this.invalid_name = true
                } else if (!(this.input_password.trim())) {
                    this.invalid_password = true
                } else if (!(this.input_vercode.trim())) {
                    this.invalid_vercode = true
                } else {
                    this.sending = true
                    this.invalid_request = false

                    // 临时
                    // this.$parent.$parent && this.$parent.$parent.auth()

                    axios ({
                        method: 'post',
                        url: 'https://assets.wenhua.com.cn/api/Member/Login',
                        data: {
                            username: this.input_name.trim(),
                            password: this.input_password,
                            validcode: this.input_vercode.trim(),
                        }
                    }).then(({ data }) => {
                        this.sending = false
                        this.fresh_code();

                        this.message = data.message


                        if (data.status) {
                            this.$store.commit('SetAuth', true)
                            this.$parent.$parent && this.$parent.$parent.set_hide()

                            // 保存 token
                            sessionStorage.setItem('assets-token', data.resultData.token);
                            store.commit('SetToken', data.resultData.token);
                        }

                    }).catch(data => {
                        this.invalid_request = true
                        this.request_message = data.message
                        this.sending = false
                        this.fresh_code()
                    })
                    
                }
            }
        }
    }
}
</script>

<style scoped>
.form {
    width: 284px;
    margin-top: 32px;
}

.input {
    border: 1px solid #d1d2d2;
    box-shadow: 0 2px 9px rgba(0, 0, 0, 0.12);
    border-radius: 2px;
    background: #fff;

    height: 34px;
    margin: 15px 0;

    display: flex;
    position: relative;
    align-items: center;

    transition: border .15s;
}

.input:hover {
    border: 1px solid #c1c1c1;
}

.input:focus-within {
    border: 1px solid rgba(67, 85, 206, .5);
}

.input:focus-within .vercode {
    border-left: 1px solid rgba(67, 85, 206, .3);
}

.input:hover > .icon, .input:focus-within > .icon {
    transform-origin: 50% 0%;
    animation: shake 0.35s forwards ease-out;
    animation-delay: .04s;
}

@keyframes shake {
    0% {
        transform: rotate(-15deg);
    }
    33.33% {
        transform: rotate(15deg);
    }
    100% {
        transform: rotate(0);
    }
}

.input::before {
    width: 0;
    left: -1px;
    position: absolute;
    top: -1px;
    bottom: -1px;
    content: "";
    background: #4355ce;
    transition: width .1s;
}

.input:focus-within::before {
    width: 2px;
}

.input > .icon {
    width: 12px;
    height: 12px;
    margin: 0 6px 0 12px;
    transition: transform .15s;
}

.input > .icon.name {
    margin-top: -4px;
    background: url(../assets/icon-name.svg) no-repeat;
}

.input > .icon.passwd {
    margin-top: -2px;
    margin-left: 13px;
    background: url(../assets/icon-passwd.svg) no-repeat;
}

.input > .icon.key {
    margin-top: -3px;
    background: url(../assets/icon-key.svg) no-repeat;
}

.input > input {
    border: 0;
    font-size: 13px;
    margin-top: -2px;
    margin-right: 10px;

    color: #4d5585;
    outline: none;
    background: none;

    flex-grow: 1;
}

/* 解决 chrome 自动填写表单时 input 强制被设置背景色的问题 */
/* 解决自动填写表单字体颜色篡改问题 */
.input > input:-webkit-autofill {
    box-shadow: 0 0 0px 1000px #fff inset !important;
    -webkit-text-fill-color: #4d5585 !important;
}

.input > input::selection {
    color: #212b63;
    background: rgba(68, 86, 206, .18);
}

.input > input::placeholder {
    color: #747a98;
    opacity: 1;
}

.button {
    color: #fff;
    border: 1px solid #3c4dbf;
    background: #4a5cd0;

    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.35);
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);

    width: 100%;
    height: 40px;
    padding: 0 0 1px 0;

    font-size: 15px;
    line-height: 100%;
    font-weight: 900;
    border-radius: 3px;

    display: inline-block;
    outline: none;
    text-align: center;
    user-select: none;

    transition: background .1s, opacity .1s;
    transform: translateY(0px);
}

.button::-moz-focus-inner {
    border: 0;
}

.button:disabled {
    opacity: .7;
}

.button:hover:not(:disabled) {
    background: #5669e0;
}

.button:active:not(:disabled) {
    background: #4153c7;
    transform: translateY(1px);
}

.input-area {
    cursor: text;
    display: block;
    position: relative;
}

.vercode {
    width: 69px;
    height: 34px;
    border: 0;

    background: none;
    border-left: 1px solid #e6e6e6;

    display: block;

    display: flex;
    justify-content: right;
    align-items: center;

    cursor: default;
    user-select: none;

    transition: border .15s;
    position: relative;
    overflow: hidden;
}

.code_obj {
    top: -1px;
    left: -2px;
    width: 104%;
    position: absolute;
    opacity: .8;

    display: flex;
    align-items: center;
    justify-content: center;

    height: 36px;
}

.vercode:hover {
    border-left: 1px solid rgba(193, 193, 193, .7);
}

.vercode:hover > .fresh {
    animation: grow .2s forwards ease-out;
}

.fresh {
    width: 14px;
    height: 14px;
    padding: 5px 14px;
    opacity: .8;
}

@keyframes grow {
    0% { }
    30% {
        opacity: 1;
        transform: scale(1.5);
    }
    100% {
        opacity: 1;
        transform: scale(1.2);
    }
}

</style>