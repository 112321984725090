<template>
  <div class="tradetable">
    <p class="guowaip">
      <a href="https://www.cnfirst.com.hk/" class="acom" target="_blank"
        >◆ CN First（中一期貨有限公司）</a
      >&nbsp;&nbsp;&nbsp; 香港证监会牌照代码：BBH027
    </p>
    <!-- <p class="guowaip" style="margin-bottom: 15px">
      <a href="https://www.henghua.hk/" class="acom" target="_blank"
        >◆ HGNH（橫華國際期貨有限公司）</a
      >&nbsp;&nbsp;&nbsp;香港证监会牌照代码：AOU118
    </p> -->
    <p class="guowaip" style="margin-top: 15px">
      <a href="https://apps.sfc.hk/publicregWeb/searchByName" target="_blank" style="color:#4c508d;"
        >点击这里到中国香港证监会官网查询牌照情况</a
      >
    </p>
    <p class="guowaip">牌照查询标识：CN First</p>
    <p  style="height:60px;line-height:25px;font-size:14px;margin-top: 15px;">
            提示：文华财经只是一家软件服务公司，开户/入金等业务请另外找期货公司办理。
          </p>
  </div>
</template>

<script>
export default {};
</script>

<style scpoed>
.tradetable {
  width: 920px;
  /* height: 600px; */
  margin: 0 auto;
  color: #5a5a5a;
}
.guowaip {
  height: 30px;
  line-height: 30px;
  letter-spacing: 1px;
}
.acom {
  color: #5a5a5a;
  text-decoration: none;
  /* margin-right: 35px; */
}

</style>